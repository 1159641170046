@import "react-toastify/dist/ReactToastify.css";

.NotificationContainer {
  --toastPadding: 8px;
  display: flex;
  flex-direction: column;
  width: 550px;
  max-width: calc(100% - 2 * var(--toastPadding));
  right: var(--toastPadding);
  bottom: var(--toastPadding);
  left: unset;
  padding: 0;

  @media (--tablet) {
    --toastPadding: var(--tabletGutter);
  }

  @media (--desktopHD) {
    --toastPadding: var(--desktopLargeGutter);
  }

  & .NotificationContainer__notification {
    @mixin p20;
    font-family: var(--baseFontFamily);
    box-shadow: var(--boxShadow20);
    padding: 0;
    border-radius: 8px;

    &.NotificationContainer__notification--info {
      color: var(--grayscaleBlackLighten10);
      background-color: var(--support03Lighten60);

      & .NotificationContainer__buttonContainer {
        background-color: var(--support03Base);
      }

      & .NotificationContainer__button {
        &:hover {
          background-color: var(--support03Lighten60);
        }
      }
    }

    &.NotificationContainer__notification--error {
      color: var(--grayscaleBlackLighten10);
      background-color: var(--notificationErrorLighten60);

      & .NotificationContainer__buttonContainer {
        background-color: var(--notificationErrorBase);
      }

      & .NotificationContainer__button {
        fill: var(--grayscaleWhiteBase);

        &:hover {
          fill: var(--grayscaleBlackLighten10);
          background-color: var(--notificationErrorLighten60);
        }
      }
    }
  }

  & .NotificationContainer__content {
    padding: 24px;
  }

  & .NotificationContainer__buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 64px;
  }

  & .NotificationContainer__button {
    fill: currentColor;
    transition: background-color 0.2s ease-out, fill 0.2s ease-out;
    padding: 8px;
    border-radius: 50%;
  }
}
