.ArticleHero {
  position: relative;
  background-color: var(--support01Base);
  padding: var(--mobileHeaderHeight) 0 24px;

  @media (--tablet) {
    padding: var(--headerHeight) 0 48px;
  }

  & .ArticleHero__inner {
    @mixin wrapper;

    @media (--desktop) {
      @mixin defaultGutter;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "backLink image"
        "info image"
        "authors image"
        "authors date";
    }
  }

  & .ArticleHero__backLinkContainer {
    margin-top: 8px;

    @media (--desktop) {
      grid-area: backLink;
    }
  }

  & .ArticleHero__info {
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }

    @media (--desktop) {
      grid-area: info;
      margin-top: 40px;
    }
  }

  & .ArticleHero__category {
    @mixin p40;
    @mixin highlightedText;
    display: block;
  }

  & .ArticleHero__title {
    @mixin h40;
    @mixin breakWithHyphen;
    @mixin lineUnder 16px, currentColor, 128px, 2px;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
    }

    @media (--desktop) {
      @mixin h20;
      @mixin lineUnder 24px, currentColor, 50%, 2px;
      margin-top: 8px;
    }

    @media (--desktopHD) {
      @mixin h10;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  & .ArticleHero__description {
    @mixin p10;
    color: var(--grayscaleBlackLighten10);
    font-weight: var(--mediumFontWeight);
    margin: 32px 0 0;

    @media (--tablet) {
      margin-top: 24px;
    }
  }

  & .ArticleHero__authors {
    margin-top: 32px;

    @media (--desktop) {
      grid-area: authors;
      margin-top: 48px;
    }
  }

  & .ArticleHero__authorItem {
    margin-top: 8px;

    @media (--tablet) {
      margin-top: 16px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  & .ArticleHero__imageContainer {
    z-index: 2;
    width: 100%;

    @media (--belowDesktop) {
      max-width: 400px;
      margin: 32px auto 0;
    }

    @media (--desktop) {
      grid-area: image;
      align-self: start;
      box-sizing: content-box;
      padding-right: var(--desktopSmallWrapperPadding);
    }

    @media (--desktopHD) {
      padding-right: var(--desktopLargeWrapperPadding);
    }
  }

  & .ArticleHero__image {
    @media (--desktop) {
      object-position: right top;
    }
  }

  & .ArticleHero__dateContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    @media (--tablet) {
      margin-top: 24px;
    }

    @media (--desktop) {
      grid-area: date;
    }
  }
}
