.Product {
  & .Product__loader {
    margin-top: calc(32px + var(--mobileHeaderHeight));

    @media (--tablet) {
      margin-top: calc(64px + var(--headerHeight));
    }
  }

  & .Product__blocksList {
    @mixin clearList;

    & .Product__sectionItem--lastImageBlock {
      overflow: hidden;
      border-bottom-left-radius: 60px;
    }

    & .Product__sectionItem--textBlockWithImageBlockNext {
      background-color: var(--grayscaleWhiteBase);
    }
  }

  & .Product__sectionItem--nextQuoteBlock {
    & > * {
      &::after {
        content: "";
        width: 100%;
        display: block;
        height: 240px;

        @media (--tablet) {
          height: 180px;
        }
      }
    }
  }
}
