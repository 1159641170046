.StatusTag {
  @mixin p50;
  font-family: var(--headingsFontFamily);
  font-weight: var(--boldFontWeight);
  text-align: center;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 16px;

  @media (--tablet) {
    @mixin p40;
  }

  &.StatusTag--error {
    background-color: var(--primaryDarken20);
    color: var(--grayscaleWhiteBase);
  }

  &.StatusTag--warning {
    background-color: var(--actionWarningBase);
    color: var(--grayscaleBlackBase);
  }

  &.StatusTag--success {
    background-color: var(--actionSuccessBase);
    color: var(--grayscaleBlackBase);
  }

  &.StatusTag--small {
    @mixin p50;
    padding: 2px 12px;
  }
}
