.ImageContainer {
  margin: 0;

  & > img {
    object-fit: contain;
  }

  &.ImageContainer--16x9 {
    @mixin aspectRatio 9/16;
  }

  &.ImageContainer--3x2 {
    @mixin aspectRatio 2/3;
  }

  &.ImageContainer--4x3 {
    @mixin aspectRatio 3/4;
  }

  &.ImageContainer--1x1 {
    @mixin aspectRatio 1/1;
  }

  &.ImageContainer--3x4 {
    @mixin aspectRatio 4/3;
  }

  &.ImageContainer--2x3 {
    @mixin aspectRatio 3/2;
  }

  &.ImageContainer--9x16 {
    @mixin aspectRatio 16/9;
  }
}

.ImageCredit.ImageCredit {
  @mixin aspectRatioClearChild;
  position: absolute;

  &.ImageCredit--topStart {
    top: 8px;
    left: 8px;
  }

  &.ImageCredit--topEnd {
    top: 8px;
    right: 8px;
  }

  &.ImageCredit--bottomStart {
    bottom: 8px;
    left: 8px;
  }

  &.ImageCredit--bottomEnd {
    bottom: 8px;
    right: 8px;
  }
}
