.LessonHeading {
  margin-bottom: 16px;

  @media (--tablet) {
    margin-bottom: 32px;
  }

  & .LessonHeading__titleWrapper {
    display: none;

    @media (--tablet) {
      display: block;
      margin-top: 32px;
    }
  }

  & .LessonHeading__title {
    @mixin h50;
    @mixin lineAfter 24px, var(--grayscaleBlackLighten20), none;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    width: 100%;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
    }
  }
}
