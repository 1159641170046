.FinishConfirmationModal {
  & .FinishConfirmationModal__container {
    padding: 32px 32px 48px;

    @media (--tablet) {
      padding: 64px 64px 80px;
    }
  }

  & .FinishConfirmationModal__title {
    @mixin h50;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
    color: var(--grayscaleBlackLighten10);
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, currentColor, 128px, 2px;
    }
  }

  & .FinishConfirmationModal__content {
    color: var(--grayscaleBlackLighten10);
    margin-top: 16px;

    @media (--tablet) {
      margin-top: 24px;
    }
  }

  & .FinishConfirmationModal__description {
    @mixin p20;
    margin: 0;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .FinishConfirmationModal__action {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    @media (--tablet) {
      flex-direction: row;
    }

    & .FinishConfirmationModal__closeModalButton {
      margin-right: 0;
      margin-bottom: 16px;

      @media (--tablet) {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
  }

  & .FinishConfirmationModal__actionItem {
    width: 100%;

    @media (--tablet) {
      width: auto;
    }
  }
}
