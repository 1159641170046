.ThemeSelectorImage.ThemeSelectorImage {
  display: none;

  @media (--tablet) {
    display: block;
    width: calc(100% + 64px);
    max-height: 100%;
    margin: auto 0 0 -64px;
  }

  @media (--desktopHD) {
    width: calc(100% + 128px);
    margin-left: -128px;
  }

  & .ThemeSelectorImage__basePicture {
    transition: filter 0.7s linear;

    &.ThemeSelectorImage__basePicture--darken {
      filter: brightness(60%);
    }
  }

  & .ThemeSelectorImage__image {
    @mixin absoluteParentSize;
    object-fit: contain;
    object-position: left bottom;
  }

  & .ThemeSelectorImage__themePicture {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
    transition-delay: 0.2s;

    &.ThemeSelectorImage__themePicture--visible {
      visibility: visible;
      opacity: 1;
    }
  }
}
