.ThemeArticleSection {
  & .ThemeArticleSection__articleList {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 24px;
    margin-top: 24px;

    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 40px;
      margin-top: 32px;
    }

    &.ThemeArticleSection__articleList--odd li:first-child {
      @media (--desktop) {
        grid-column: 1 / span 2;

        & .ThemeArticleItem__info {
          padding: 40px 40px 40px 0;
        }

        & .ThemeArticleItem__category {
          @mixin p40;
        }

        & .ThemeArticleItem__imageContainer {
          @mixin aspectRatio 9/16;
        }

        & .ThemeArticleItem__title {
          @mixin h30;
          @mixin lineUnder 16px;
        }

        & .ThemeArticleItem__description {
          @mixin p10;
          margin-top: 32px;
        }
      }
    }
  }
}
