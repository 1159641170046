.SectionIconBlock {
  @mixin defaultGutter;
  display: flex;

  & .SectionIconBlock__icon {
    & img {
      object-fit: contain;
      width: 48px;
      height: 48px;

      @media (--tablet) {
        width: 72px;
        height: 72px;
      }
    }
  }

  & .SectionIconBlock__title {
    @mixin p30;
    margin: 0 0 8px;

    @media (--tablet) {
      @mixin h40;
    }
  }

  & .SectionIconBlock__description {
    @mixin p30;
    white-space: pre-line;
    margin: 0;

    @media (--tablet) {
      @mixin p20;
    }
  }
}
