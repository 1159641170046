.SiteSearch {
  & .SiteSearch__search {
    display: flex;
    align-items: center;

    @media (--tablet) {
      width: 100%;
    }

    @media (--desktop) {
      @mixin lost-column 5/10;
    }
  }

  & .SiteSearch__input {
    flex: 1;
    margin-right: 8px;
  }

  & .SiteSearch__submit {
    flex-shrink: 0;
  }

  & .SiteSearch__submitIcon.SiteSearch__submitIcon.SiteSearch__submitIcon {
    @mixin strokeIconColor var(--grayscaleWhiteBase);
  }

  & .SiteSearch__formContainer {
    @media (--desktop) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  & .SiteSearch__filtersWrapper {
    margin-top: 24px;

    @media (--desktop) {
      @mixin lost-column 5/10;
      margin-top: 12px;
    }
  }

  & .SiteSearch__filterList {
    display: flex;
    flex-wrap: wrap;

    @media (--desktop) {
      justify-content: flex-end;
    }

    & li {
      @mixin horizontalGap 24px;
    }
  }
}
