.HintToggleImage {
  display: inline-flex;
  position: relative;
  transition: transform 0.3s ease-out;

  & .HintToggleImage__iconWrapper {
    display: inline-flex;

    & div {
      display: inline-flex;
    }

    &.HintToggleImage__iconWrapper--message {
      position: absolute;
      top: 15px;
      right: 110%;
      visibility: visible;
      opacity: 1;
      transition: visibility 0.3s linear, opacity 0.3s linear;
    }
  }

  &.HintToggleImage--hiddenMessage {
    & .HintToggleImage__iconWrapper--message {
      visibility: hidden;
      opacity: 0;
    }
  }
}
