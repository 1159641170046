.CardThemeBasic {
  @mixin linkOverlay;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: var(--support01Lighten20);
  transition: box-shadow 0.2s ease-out, border-color 0.2s ease-out;
  padding: 16px 24px 24px;
  border: 1px solid var(--grayscaleBlackLighten30);
  border-radius: 8px;

  @media (--tablet) {
    padding: 24px 32px 40px;
    border-radius: 16px;

    &:hover {
      box-shadow: inset 0 0 0 1px var(--support04Darken20);
      border-color: var(--support04Darken20);
    }
  }

  & .CardThemeBasic__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  & .CardThemeBasic__cardNumber {
    @mixin p40;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 48px;
    flex: 1;
    font-weight: var(--boldFontWeight);
    font-family: var(--headingsFontFamily);

    @media (--tablet) {
      @mixin h40;
      @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
    }
  }

  & .CardThemeBasic__tagsList {
    overflow: hidden;
    margin-top: 8px;

    @media (--tablet) {
      margin-top: 16px;
    }

    &:first-child {
      margin: 0;
    }
  }

  & .CardThemeBasic__tagsListItem {
    @mixin verticalGap 4px;
  }

  & .CardThemeBasic__imageWrapper {
    @mixin aspectRatio 1/1;
    width: 100%;
    max-width: 80px;
    margin-left: 16px;

    @media (--tablet) {
      max-width: 112px;
    }
  }

  & .CardThemeBasic__image {
    object-fit: contain;
  }

  & .CardThemeBasic__title {
    @mixin lineClamp 3;
    @mixin p30;
    width: 100%;
    margin: 16px 0 0;

    @media (--tablet) {
      @mixin h40;
      margin-top: 24px;
    }
  }

  & .CardThemeBasic__themeContent {
    margin-top: 8px;
  }

  & .CardThemeBasic__statusContent {
    position: relative;
    width: 100%;
    min-height: 48px;
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }

  & .CardThemeBasic__linkOverlay {
    z-index: 2;
  }

  & .CardThemeBasic__link {
    position: relative;
    z-index: 3;
  }

  & .CardThemeBasic__actionsWrapper {
    display: grid;
    align-items: end;
    column-gap: 16px;
    row-gap: 16px;

    @media (--tablet) {
      grid-template-columns: 1fr min-content;
    }

    @media (--desktopHD) {
      column-gap: 24px;
      row-gap: 24px;
    }
  }

  &.CardThemeBasic--underDevelopment {
    background-color: var(--support01Lighten40);
    border-color: var(--grayscaleBlackLighten40);

    &:hover {
      box-shadow: none;
      border-color: var(--grayscaleBlackLighten40);
    }

    & .CardThemeBasic__cardNumber,
    & .CardThemeBasic__imageWrapper,
    & .CardThemeBasic__title,
    & .CardThemeBasic__statusContent {
      opacity: 0.5;
    }

    & .CardThemeBasic__themeContent {
      display: none;
    }
  }
}
