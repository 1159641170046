.FreeInfoLayer {
  @mixin absoluteParentSize;
  pointer-events: none;
  z-index: 1;

  & .FreeInfoLayer__tagWrapper {
    position: absolute;
    top: 4px;
    left: 4px;

    @media (--tablet) {
      top: 8px;
      left: 8px;
    }

    &.FreeInfoLayer__tagWrapper--small {
      top: 4px;
      left: 4px;
    }
  }
}
