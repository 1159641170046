.NavigationLink.NavigationLink {
  @mixin p30;
  color: var(--grayscaleBlackLighten10);
  font-weight: var(--mediumFontWeight);
  display: flex;
  align-items: center;
  padding: 12px 8px;

  & .NavigationLink__icon {
    fill: var(--primaryBase);
    width: 16px;
    height: 16px;
    transition: transform 0.2s;

    &.NavigationLink__icon--back {
      margin-right: 8px;
    }

    &.NavigationLink__icon--forward {
      margin-left: 8px;
    }
  }

  & .NavigationLink__text {
    text-align: center;
  }

  &:hover {
    & .NavigationLink__icon {
      &.NavigationLink__icon--back {
        transform: translateX(-4px);
      }

      &.NavigationLink__icon--forward {
        transform: translateX(4px);
      }
    }
  }
}
