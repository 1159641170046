.GapTaskGap {
  display: inline-flex;
  flex-direction: column;
  margin: 4px 0;

  & .GapTaskGap__choiceGapTestOptionStatus {
    display: inline-block;
    width: 160px;
    max-width: 100%;

    @media (--tablet) {
      width: 250px;
    }

    & .Select__control {
      max-height: 40px;
    }
  }

  & .GapTaskGap__choiceGapTestSelect {
    width: 100%;
  }

  & .GapTaskGap__choiceGapTestCorrectAnswer {
    @mixin p40;
    margin: 0;
  }
}
