.ActivityIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  margin-top: 64px;

  & > * {
    display: block !important;
  }
}
