.SectionArticleListBlock {
  background-color: var(--support01Lighten60);
  padding-top: 80px;
  padding-bottom: 96px;

  & .SectionArticleListBlock__inner {
    @mixin wrapper;
  }

  & .SectionArticleListBlock__actionLink.SectionArticleListBlock__actionLink {
    @media (--mobileOnly) {
      display: none;
    }
  }

  & .SectionArticleListBlock__list {
    display: grid;
    row-gap: 32px;
    margin-top: 24px;

    @media (--mobileXLarge) {
      @mixin defaultGutter;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (--tablet) {
      row-gap: 48px;
      margin-top: 32px;
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
