@define-mixin defaultGutter {
  column-gap: var(--mobileGutter);

  @media (--tablet) {
    column-gap: var(--tabletGutter);
  }

  @media (--desktopHD) {
    column-gap: var(--desktopLargeGutter);
  }
}
