.Switch {
  height: 28px;
  width: 48px;
  border-width: 0;

  &:focus {
    box-shadow: 0 0 0 4px var(--notificationFocusBase);
  }

  &::after {
    height: 24px;
    width: 24px;
  }

  &.Switch--light {
    background-color: var(--grayscaleBlackLighten50);

    &::after {
      background-color: var(--grayscaleBlackLighten20);
    }

    &:hover {
      background-color: var(--grayscaleBlackLighten40);

      &::after {
        background-color: var(--grayscaleBlackLighten10);
      }
    }

    &[aria-checked="true"] {
      background-color: var(--grayscaleBlackLighten10);

      &::after {
        background-color: var(--actionSuccessBase);
      }
    }
  }

  &.Switch--dark {
    background-color: var(--grayscaleBlackLighten20);

    &::after {
      background-color: var(--grayscaleBlackLighten40);
    }

    &:hover {
      &::after {
        background-color: var(--grayscaleBlackLighten30);
      }
    }

    &[aria-checked="true"] {
      background-color: var(--grayscaleBlackLighten20);

      &::after {
        background-color: var(--actionSuccessBase);
      }
    }
  }

  &.Switch--disabled {
    opacity: 0.5;
  }
}
