@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/SuisseWorks-Bold-WebS.woff2") format("woff2"),
    url("../fonts/SuisseWorks-Bold-WebS.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("../fonts/calibre-web-regular.woff2") format("woff2"),
    url("../fonts/calibre-web-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("../fonts/calibre-web-medium.woff2") format("woff2"), url("../fonts/calibre-web-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("../fonts/calibre-web-semibold.woff2") format("woff2"),
    url("../fonts/calibre-web-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
