@define-mixin focus {
  &.focus-visible:focus {
    outline: none;
    box-shadow: 0 0 0 4px var(--notificationFocusBase);
  }
}

@define-mixin insetFocus {
  &.focus-visible:focus {
    outline: none;
    box-shadow: inset 0 0 0 4px var(--notificationFocusBase);
  }
}

@define-mixin offsetFocus $offset: 4px {
  &.focus-visible:focus {
    outline: 4px solid var(--notificationFocusBase);
    outline-offset: $offset;
    box-shadow: none;
  }
}

@define-mixin resetFocus {
  &.focus-visible:focus {
    outline: none;
    box-shadow: none;
  }
}
