.TestTaskCompletionInfo {
  display: flex;
  align-items: center;
  min-height: 24px;
  margin-top: 8px;

  @media (--tablet) {
    margin-top: 16px;
  }

  & .TestTaskCompletionInfo__icon {
    @mixin strokeIconColor var(--notificationFocusBase);
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 16px;

    @media (--tablet) {
      margin-right: 8px;
    }
  }

  & .TestTaskCompletionInfo__text {
    @mixin p30;
    font-weight: var(--mediumFontWeight);
    color: var(--notificationFocusBase);
    margin: 0;

    &.TestTaskCompletionInfo__text--error {
      color: var(--notificationErrorBase);
    }
  }
}
