.TestTaskInfo {
  padding-top: 24px;

  @media (--tablet) {
    padding-top: 32px;
  }

  & .TestTaskInfo__title {
    @mixin h50;
    margin: 0;

    @media (--tablet) {
      @mixin h40;
    }
  }

  & .TestTaskInfo__description {
    @mixin p30;
    white-space: pre-line;
    padding-top: 8px;

    @media (--tablet) {
      @mixin p10;
      padding-top: 16px;
    }
  }

  &.TestTaskInfo--withSpacing {
    margin-bottom: 24px;
  }
}
