.DetailedProgressBar {
  width: 100%;

  & .DetailedProgressBar__heading {
    display: flex;
    align-items: flex-end;
    min-height: 28px;
  }

  & .DetailedProgressBar__progressSteps {
    @mixin p10;
    color: var(--grayscaleBlackBase);
    font-weight: var(--mediumFontWeight);
    margin-right: 8px;
  }

  & .DetailedProgressBar__progressValueText {
    @mixin p40;
    color: var(--grayscaleBlackLighten20);
  }

  & .DetailedProgressBar__progressValueCompleted {
    @mixin p40;
    color: var(--grayscaleBlackLighten20);
    font-weight: var(--mediumFontWeight);
    display: flex;
    align-items: center;

    & .SvgIcon {
      width: 24px;
      height: 24px;
      fill: var(--grayscaleBlackLighten20);
      margin-right: 8px;
    }
  }

  & .DetailedProgressBar__progressBar {
    margin-top: 8px;
  }

  &.DetailedProgressBar--small {
    & .DetailedProgressBar__heading {
      min-height: 24px;
    }

    & .DetailedProgressBar__progressSteps {
      @mixin p40;
    }

    & .DetailedProgressBar__progressValueText {
      @mixin p50;
    }
  }

  &.DetailedProgressBar--large {
    & .DetailedProgressBar__progressBar {
      margin-top: 4px;
    }
  }
}
