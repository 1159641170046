.EmptyTheme {
  @mixin wrapper;

  @media (--tablet) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    padding-top: 64px;
  }

  & .EmptyTheme__message {
    color: var(--grayscaleBlackLighten10);
    margin-top: 64px;

    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 5/12;
      margin-top: 0;
    }
  }

  & .EmptyTheme__messageTitle {
    @mixin h50;
    @mixin lineUnder 8px;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px;
    }
  }

  & .EmptyTheme__messageDescription {
    @mixin p10;
    font-weight: var(--mediumFontWeight);
    margin: 32px 0 0;

    @media (--tablet) {
      @mixin h40;
    }
  }

  & .EmptyTheme__imageContainer {
    @mixin aspectRatio 2/3;
    position: relative;

    @media (--mobileOnly) {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
    }

    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 5/12;
      margin-bottom: 32px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 32px;
      top: 24px;
      background: no-repeat svg-load("icons/crate.svg", stroke=#D1D3D4);

      @media (--tablet) {
        left: 80px;
        top: 32px;
      }
    }
  }

  & .EmptyTheme__image {
    position: absolute;
    object-fit: contain;
    z-index: 1;

    @media (--tablet) {
      width: 50%;
    }
  }
}
