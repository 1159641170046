/* Because of the technical limitations we need to specify gutter values in pixels (CSS variables are populated on the client side) */
/* Values should be the same as: mobileGutter, tabletGutter and desktopLargeGutter */

@define-mixin lost-column $fraction, $cycle {
  lost-column: $fraction $cycle 16px flex;

  @media (--tablet) {
    lost-column: $fraction $cycle 24px flex;
  }

  @media (--desktopHD) {
    lost-column: $fraction $cycle 40px flex;
  }
}

@define-mixin lost-offset $fraction, $direction {
  lost-offset: $fraction $direction 16px;

  @media (--tablet) {
    lost-offset: $fraction $direction 24px;
  }

  @media (--desktopHD) {
    lost-offset: $fraction $direction 40px;
  }
}
