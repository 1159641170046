.Dialog {
  @mixin fixedFullSize;
  z-index: 200;
  pointer-events: none;

  & .Dialog__background {
    pointer-events: auto;
    width: 100%;
    height: 100%;
    background-color: var(--transparent);

    &:hover {
      background-color: var(--transparent);
    }
  }

  & .Dialog__dialog {
    z-index: 201;
    pointer-events: auto;
    background-color: var(--grayscaleWhiteBase);
    box-shadow: var(--boxShadow30);
    padding: 16px 24px;
    border-radius: 16px;
  }
}
