@define-mixin visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  padding: 0;
  border: 0;
}

@define-mixin aspectRatio $ratio {
  position: relative;

  &::after {
    display: block;
    content: "";
    padding-bottom: calc(100% * $(ratio));
  }

  & > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@define-mixin aspectRatioClearChild {
  left: unset;
  top: unset;
  width: unset;
  height: unset;
}

@define-mixin actionOverlay {
  position: relative;

  & a[class*="__actionOverlay"],
  & button[class*="__actionOverlay"] {
    @mixin absoluteParentSize;
  }
}

/* THIS IS THE LEGACY MIXIN USE actionOverlay INSTEAD */

@define-mixin linkOverlay {
  position: relative;

  & a[class*="__linkOverlay"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

@define-mixin fullWidthBackground $color, $zIndex: -1 {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(-50vw + 50%);
    height: 100%;
    width: 100vw;
    z-index: $zIndex;
    background-color: $color;
  }
}

@define-mixin absoluteParentSize {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* THIS IS THE LEGACY MIXIN => PLEASE USE lineUnder FROM text.css */

@define-mixin titleUnderline {
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 32px;

  @media (--tablet) {
    padding-bottom: 24px;
    margin-bottom: 48px;
  }

  &:is(h2, h3, h4, h5, h6) {
    padding-bottom: 16px;
    margin-bottom: 32px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: var(--grayscaleBlackLighten20);
    width: 40%;
    max-width: 180px;
  }
}

@define-mixin shiftBy $horizontalValue, $verticalValue {
  transform: translateX($horizontalValue) translateY($verticalValue);
  margin: 0 $horizontalValue $verticalValue 0;
}

@define-mixin shiftHorizontallyBy $value {
  transform: translateX($value);
  padding-right: $value;
  margin-right: calc(-1 * $value);
}

@define-mixin fixedFullSize {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@define-mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@define-mixin horizontalGap $spacing {
  &:not(:last-child) {
    margin-right: $spacing;
  }
}

@define-mixin horizontalLeftSpacing $spacing {
  &:not(:first-child) {
    margin-left: $spacing;
  }
}

@define-mixin verticalGap $spacing {
  &:not(:first-child) {
    margin-top: $spacing;
  }
}

@define-mixin tabFlowOnlyElement {
  position: fixed;
  left: -9999px;

  &:focus {
    z-index: 999;
    left: 16px;
    top: 16px;
  }
}

@define-mixin gridList $mobileRowGap, $tabletRowGap, $mobileColumns, $tabletColumns, $desktopColumns, $desktopHDColumns {
  @mixin defaultGutter;
  display: grid;
  row-gap: $mobileRowGap;

  @media (--mobileXLarge) {
    grid-template-columns: repeat($mobileColumns, minmax(0, 1fr));
  }

  @media (--tablet) {
    row-gap: $tabletRowGap;
    grid-template-columns: repeat($tabletColumns, minmax(0, 1fr));
    margin-top: $tabletTopSpacing;
  }

  @media (--desktop) {
    grid-template-columns: repeat($desktopColumns, minmax(0, 1fr));
  }

  @media (--desktopHD) {
    grid-template-columns: repeat($desktopHDColumns, minmax(0, 1fr));
  }
}

@define-mixin splitLine $height: 24px, $spacing: 24px, $backgroundColor: var(--grayscaleBlackLighten50) {
  height: $height;
  background-color: $backgroundColor;
  width: 1px;
  margin: 0 $spacing;
}

@define-mixin card $mobilePadding, $tabletPadding {
  cursor: pointer;
  transition: border-color 250ms;
  padding: $mobilePadding;
  border: 1px solid var(--grayscaleBlackLighten40);
  border-radius: 8px;

  @media (--tablet) {
    padding: $tabletPadding;
  }

  &:hover {
    border-color: var(--grayscaleBlackLighten30);
  }

  &[class*="--active"] {
    border-color: var(--primaryBase);

    &:hover {
      border-color: var(--primaryDarken20);
    }
  }
}
