.ProgressRing {
  @mixin aspectRatio 1/1;
  width: 100%;
  position: relative;
  border-radius: 50%;

  & .ProgressRing__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ProgressRing__percent {
    @mixin h50;
    font-family: var(--headingsFontFamily);
    font-weight: 700;

    @media (--desktop) {
      @mixin h30;
    }
  }

  & .ProgressRating__indicator {
    transform: rotateZ(-90deg);
    transform-origin: center;
    stroke: var(--actionWarningBase);

    &.ProgressRating__indicator--success,
    &.ProgressRating__indicator--completed {
      stroke: var(--actionSuccessBase);
    }
  }

  & .ProgressRating_negativeIndicator {
    stroke: var(--grayscaleBlackLighten10);
  }
}
