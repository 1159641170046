.QuoteSlide {
  display: flex;
  align-items: center;
  flex-direction: column;

  & .QuoteSlide__content {
    max-width: 75%;
  }

  & .QuoteSlide__productTag {
    @mixin p20;
    text-align: center;
    font-weight: var(--mediumFontWeight);
  }

  & .QuoteSlide__quote {
    @mixin h40;
    text-align: center;
    font-family: var(--baseFontFamily);
    font-weight: var(--regularFontWeight);
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grayscaleBlackBase);
    margin: 0;

    @media (--desktop) {
      padding-bottom: 48px;
    }
  }

  & .QuoteSlide__authorInfo {
    display: flex;
    align-items: center;
    margin-top: 16px;

    @media (--desktop) {
      margin-top: 32px;
    }

    & .QuoteSlide__authorImageContainer {
      width: 48px;
      height: 48px;

      & .QuoteSlide__authorImage {
        object-fit: cover;
        border-radius: 50%;
      }
    }

    & .QuoteSlide__authorInfoSplitline {
      @mixin splitLine 48px, 24px, var(--grayscaleBlackBase);
    }

    & .QuoteSlide__authorName {
      align-self: flex-start;
      font-weight: var(--boldFontWeight);
      margin: 0;
    }

    & .QuoteSlide__authorRole {
      margin: 0;
    }
  }
}
