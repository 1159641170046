.ThumbnailImage {
  @mixin flexCenter;
  overflow: hidden;

  & .ThumbnailImage__picture {
    transition: transform 0.4s;

    &.ThumbnailImage__picture--default {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }

    &.ThumbnailImage__picture--fullWidth {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.ThumbnailImage__picture--fullWidthContain {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.ThumbnailImage__picture--bottom {
      object-position: bottom;
    }
  }

  &.ThumbnailImage--shadow {
    &::after {
      @mixin absoluteParentSize;
      content: "";
      background-color: var(--grayscaleBlackLighten20);
      opacity: 0.4;
    }
  }
}
