.HorizontalThumbnail {
  @mixin linkOverlay;
  display: flex;
  align-items: flex-start;

  & .HorizontalThumbnail__linkOverlay {
    z-index: 1;
  }

  & .HorizontalThumbnail__imageWrapper {
    @mixin aspectRatio 2/3;
    flex: 3;
    flex-shrink: 0;
    max-width: 120px;
    overflow: hidden;
    box-shadow: var(--boxShadow10);
    border: 1px solid var(--grayscaleBlackLighten40);
    border-radius: 8px;
    margin-right: 16px;
  }

  & .HorizontalThumbnail__linkWrapper {
    @mixin flexCenter;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s linear, opacity 0.2s linear;
    pointer-events: none;
    z-index: 1;
  }

  & .HorizontalThumbnail__link {
    pointer-events: auto;
  }

  & .HorizontalThumbnail__info {
    flex: 5;
    overflow: hidden;
  }

  & .HorizontalThumbnail__additionalInfo {
    @mixin p50;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 48px;
    color: var(--grayscaleBlackLighten20);
    display: flex;
    align-items: center;

    & > * {
      @mixin horizontalGap 8px;
    }

    & .HorizontalThumbnail__timeStampIcon {
      width: 16px;
      height: 16px;
      fill: var(--grayscaleBlackLighten30);
    }
  }

  & .HorizontalThumbnail__title {
    @mixin p40;
    @mixin breakWithHyphen;
    margin: 8px 0 0;

    &:first-child {
      margin: 0;
    }
  }

  &.HorizontalThumbnail:not(.HorizontalThumbnail--locked) {
    &:hover {
      @mixin stepIconsAnimation;
      @mixin zoomImageAnimation;

      & .HorizontalThumbnail__linkWrapper {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
