.AuthorSlide {
  @mixin actionOverlay;

  & .AuthorSlide__actionOverlay {
    z-index: 9;
  }

  & .AuthorSlide__imageContainer {
    max-width: 230px;
    max-height: 150px;
    overflow: hidden;
    border-radius: 16px;

    @media (--tablet) {
      max-width: 400px;
      max-height: 250px;
    }

    & .AuthorSlide__image {
      object-fit: cover;
    }
  }

  & .AuthorSlide__authorName {
    @mixin p40;
    font-weight: var(--boldFontWeight);
    font-family: var(--headingsFontFamily);
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin p30;
    }
  }

  & .AuthorSlide__authorRole {
    @mixin p50;
    color: var(--primaryBase);
    max-width: 230px;
    margin: 0;

    @media (--tablet) {
      max-width: auto;
    }
  }
}
