.PreviousExamsSection {
  @mixin fullWidthBackground var(--support01Lighten20);

  @media (--desktop) {
    display: flex;
    align-items: flex-start;
  }

  & .PreviousExamsSection__content {
    color: var(--grayscaleBlackLighten10);
    padding: 32px 0 48px;

    @media (--desktop) {
      @mixin lost-column 6/12 2;
      padding: 96px 0 144px;
    }
  }

  & .PreviousExamsSection__title {
    @mixin h30;
    margin: 0;

    @media (--desktop) {
      @mixin h10;
    }
  }

  & .PreviousExamsSection__description {
    @mixin p10;
    font-weight: var(--mediumFontWeight);
    white-space: pre-line;
    margin: 32px 0 0;

    @media (--desktop) {
      @mixin h40;
      margin-top: 48px;
    }
  }

  & .PreviousExamsSection__link {
    width: 100%;
    margin-top: 32px;

    @media (--tablet) {
      width: auto;
      margin-top: 48px;
    }
  }

  & .PreviousExamsSection__imageWrapper {
    width: 100%;

    @media (--tablet) {
      max-width: 500px;
      margin: 0 auto;
    }

    @media (--desktop) {
      @mixin lost-column 6/12 2;
      padding-top: 32px;
      margin-top: auto;
    }
  }

  & .PreviousExamsSection__image {
    object-position: bottom;
  }
}
