.Anchors {
  & .Anchors__list {
    @mixin clearList;

    & li {
      margin-bottom: 4px;

      &:last-of-type {
        margin: 0;
      }
    }

    & button {
      display: flex;
      align-items: center;
      color: var(--grayscaleBlackLighten10);
      padding: 0;
      margin-bottom: 16px;

      & .Anchor__box {
        display: inline-flex;
        align-items: center;
        transition: border-color 0.2s ease-out;
        padding: 6px 9px;
        border: 1px var(--grayscaleBlackLighten10) solid;
        border-radius: 20px;
        margin-right: 16px;
      }

      & .Anchors__anchorIcon {
        fill: var(--primaryBase);
        width: 15px;
        height: 15px;
        margin-right: 8px;
      }

      & .Anchors__anchorTimeStamp {
        @mixin tag;
        font-family: var(--headingsFontFamily);
        font-weight: 700;
        min-width: 40px;
      }

      &:hover {
        & .Anchor__box {
          border-color: var(--grayscaleBlackLighten40);
        }
      }

      & .Anchors__anchorTitle {
        @mixin b2;
        color: var(--grayscaleBlackLighten20);
        text-align: left;
        padding-left: 10px;
        margin: 0;
      }
    }
  }
}
