.ThemeList {
  @mixin wrapper;
  overflow-x: hidden;
  padding-top: calc(25px + var(--mobileHeaderHeight));

  @media (--tablet) {
    padding-top: calc(75px + var(--headerHeight));
    padding-bottom: 128px;
  }

  & .ThemeList__heading {
    @mixin listTitle;
  }

  & .ThemeList__filter {
    display: none;

    @media (--desktop) {
      display: block;
    }
  }

  & .ThemeList__filter--mobile {
    @mixin horizontalScrollbar;
    display: block;
    margin-bottom: 40px;

    @media (--desktop) {
      display: none;
    }
  }

  & .ThemeList__listHeading {
    margin-bottom: 24px;

    @media (--desktop) {
      margin-bottom: 64px;
    }
  }

  & .ThemeList__list {
    @media (--mobileLarge) {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 0;
    }
  }

  & .ThemeList__listItem {
    width: 100%;
    margin-bottom: 24px;

    @media (--mobileXLarge) {
      @mixin lost-column 6/12, 2;
      margin-bottom: 40px;
    }

    @media (--desktop) {
      @mixin lost-column 4/12, 3;
    }
  }
}
