.HintPanelContent {
  flex: 1;
  overflow-y: auto;

  & .HintPanelContent__content {
    @mixin p20;
    color: var(--grayscaleBlackLighten10);
  }

  & .HintPanelContent__relatedStep {
    margin-top: 8px;

    @media (--tablet) {
      margin-top: 16px;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
