.LockedLayer {
  @mixin absoluteParentSize;
  @mixin actionOverlay;
  z-index: 2;

  &:hover {
    & .LockedLayer__actions {
      visibility: visible;
      opacity: 1;
    }
  }

  & .LockedLayer__content {
    @mixin flexCenter;
    height: 100%;

    &::before {
      content: "";
      height: 100%;
      width: 100%;
      background-color: var(--grayscaleBlackBase);
      opacity: 0.6;
    }
  }

  & .LockedLayer__icon {
    position: absolute;
    top: 4px;
    left: 4px;
    pointer-events: none;
    z-index: 2;

    @media (--tablet) {
      top: 12px;
      left: 12px;
    }

    & svg {
      width: 16px;
      height: 16px;

      @media (--tablet) {
        width: 24px;
        height: 24px;
      }
    }
  }

  & .LockedLayer__actions {
    @mixin flexCenter;
    @mixin absoluteParentSize;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }

  & .LockedLayer__button {
    z-index: 2;
    margin: 8px;

    @media (--mobileOnly) {
      @mixin xSmallButton;
    }
  }

  &.LockedLayer--small {
    & .LockedLayer__icon {
      top: 4px;
      left: 4px;

      & svg {
        width: 16px;
        height: 16px;
      }
    }

    & .LockedLayer__button {
      @mixin xSmallButton;
    }
  }
}
