.Training {
  @mixin fixedFullSize;
  z-index: 102;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--grayscaleBlackLighten10);

  & .Training__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (--tablet) {
      @mixin wrapper;
      padding-bottom: 48px;
    }
  }

  & .Training__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;

    @media (--mobileOnly) {
      @mixin wrapper;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  & .Training__title {
    @mixin h50;
    color: var(--grayscaleWhiteBase);
    margin: 0;

    @media (--mobileOnly) {
      @mixin visuallyHidden;
    }
  }

  & .Training__finishButton {
    margin-left: auto;
  }

  & .Training__tabFinishButton {
    @mixin tabFlowOnlyElement;
  }
}
