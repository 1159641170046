.TrainingGround {
  overflow: hidden;
  padding-top: var(--mobileHeaderHeight);

  @media (--tablet) {
    padding-top: var(--headerHeight);
  }

  & .TrainingGround__inner {
    @mixin wrapper;
  }

  & .TrainingGround__loaderWrapper {
    width: 100%;
  }
}
