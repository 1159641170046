.TermsAndConditions {
  & .TermsAndConditions__wrapper {
    @mixin wrapper;
    margin: 48px auto 80px;

    @media (--tablet) {
      margin: 80px auto 160px;
    }
  }

  & .TermsAndCondition__accordionsList {
    @media (--desktop) {
      @mixin lost-column 8/12;
    }

    & .TermsAndConditions__accordion {
      &:not(:first-child) {
        margin-top: 16px;

        @media (--tablet) {
          margin-top: 24px;
        }
      }
    }
  }
}
