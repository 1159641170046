.Link {
  @mixin offsetFocus;

  &.Link--clear {
    @mixin insetFocus;
    color: var(--grayscaleBlackBase);
    text-decoration: none;
    display: inline-block;
  }

  &.Link--button {
    @mixin resetFocus;
    display: inline-flex;
  }
}
