.SectionIconListBlock {
  @mixin wrapper;

  & .SectionIconListBlock__iconList {
    display: block;
    padding: 40px 0 16px;

    @media (--tablet) {
      @mixin lost-column 10/12;
      @mixin lost-offset 1/12;
      display: flex;
      flex-wrap: wrap;
      padding: 96px 0 56px;
    }

    & li {
      margin-bottom: 32px;

      @media (--tablet) {
        @mixin lost-column 6/12, 2;
        margin-bottom: 40px;
      }
    }
  }
}
