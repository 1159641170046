.MatchTaskResult {
  & .MatchTaskResult__answerWrapper {
    &:not(.MatchTaskResult__answerWrapper--default) {
      @mixin visuallyHidden;
    }

    &.MatchTaskResult__answerWrapper--default {
      width: 0;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  & .MatchTaskResult__listWrapper {
    margin-top: 40px;
  }

  & .MatchTaskResult__tableWrapper {
    margin-top: 48px;
  }
}
