@define-mixin choice {
  &:checked,
  &:not(:checked) {
    @mixin choiceDefaultColors;
    position: absolute;
    left: 0;
    opacity: 0.01;
  }

  &:not(:checked) + label,
  &:checked + label {
    --defaultLineHeight: 28px;

    @mixin p10;
    @mixin shiftPseudoElementsVertically calc((var(--lineHeight, var(--defaultLineHeight)) - var(--choiceSize)) / 2);
    position: relative;
    cursor: pointer;
    padding-left: var(--choicePaddingLeft);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: var(--choiceSize);
      height: var(--choiceSize);
      transition: border-color 0.3s;
      border: 1px solid var(--grayscaleBlackLighten30);
      border-radius: 2px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      transition: opacity 0.2s, transform 0.2s;
    }

    &:hover {
      &::before {
        border-color: var(--grayscaleBlackLighten10);
      }
    }
  }

  &:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }

  &:checked + label::after {
    opacity: 1;
    transform: scale(1) rotate(0);
  }

  &:disabled:not(:checked) + label,
  &:disabled:checked + label {
    cursor: default;
    color: var(--grayscaleBlackLighten30);

    &::before {
      border-color: var(--grayscaleBlackLighten40);
    }

    &::after {
      background: var(--grayscaleBlackLighten40);
    }

    &:hover {
      &::before {
        border-color: var(--grayscaleBlackLighten40);
      }
    }
  }

  &.focus-visible:focus + label::before {
    box-shadow: inset 0 0 0 2px var(--notificationFocusBase);
  }
}

@define-mixin choiceDefaultColors {
  & + label {
    color: var(--grayscaleBlackLighten10);

    &::before {
      border-color: var(--grayscaleBlackLighten30);
    }

    &::after {
      background: var(--grayscaleBlackLighten10);
    }

    &:hover {
      &::before {
        border-color: var(--grayscaleBlackLighten30);
      }
    }
  }
}

@define-mixin choiceStatusColors $backgroundColor, $checkmarkBackground,
  $containerBorderColor: var(--grayscaleBlackLighten10) {
  & + label {
    &::before {
      background: $backgroundColor;
      outline: 2px solid $backgroundColor;
      border-color: $containerBorderColor;
    }

    &::after {
      background: $checkmarkBackground;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      &::before {
        border-color: $containerBorderColor;
      }
    }
  }
}

@define-mixin checkboxStatusCheckmark {
  & + label {
    &::after {
      width: var(--choiceSize);
      height: var(--choiceSize);
      left: 0;
      top: 0;
    }
  }
}

@define-mixin correctCheckboxStatusCheckmark {
  & + label {
    &::after {
      left: 4px;
      top: -4px;
    }
  }
}

@define-mixin resetChoiceSpacing {
  &:checked,
  &:not(:checked) {
    & + label {
      @mixin shiftPseudoElementsVertically 0;
      width: var(--choiceSize);
      height: var(--choiceSize);
      padding: 0;
    }
  }
}

@define-mixin shiftPseudoElementsVertically $value {
  &::before,
  &::after {
    margin-top: $value;
  }
}
