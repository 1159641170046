.ChoiceTask {
  & .ChoiceTask__content {
    @mixin p30;
    color: var(--grayscaleBlackLighten10);
    margin-bottom: 16px;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .ChoiceTask__optionsWrapper {
    display: block;
    width: 100%;
  }

  & .ChoiceTask__options {
    & .ChoiceTask__singleOptionItem {
      @mixin verticalGap 8px;
      width: 100%;
    }

    & .PortableTextContent__mathBlock {
      overflow: visible;
    }
  }
}
