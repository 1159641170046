.SectionQuoteBlockBase {
  @mixin wrapper;

  @media (--tablet) {
    margin: -40px auto 95px;
  }

  & .SectionQuoteBlockBase__inner {
    display: flex;
    flex-direction: column;
    background-color: var(--support01Base);
    width: calc(100% - 32px);
    border-radius: 20px;
    margin: 0 16px;

    @media (--desktop) {
      flex-direction: row;
      width: calc(100% - 120px);
      margin: 0 60px;
    }
  }

  & .SectionQuoteBlockBase__imageContainer {
    margin: -40px 0 20px 30px;

    @media (--desktop) {
      @mixin lost-column 3/11;
      @mixin lost-offset 1/11;
      margin-bottom: 0;
    }

    & .SectionQuoteBlockBase__authorContainer {
      @mixin aspectRatio 1/1;
      width: 154px;
      position: relative;
      background-color: var(--grayscaleWhiteBase);
      border-radius: 50%;

      @media (--mobileOnly) {
        top: 24px;
        left: 0;
        width: 72px;
      }

      @media (--desktop) {
        @mixin lost-column 2/3;
        min-width: 154px;
        min-height: 154px;
      }

      & img {
        object-fit: cover;
        border-radius: 50%;
      }
    }

    & .SectionQuoteBlockBase__apostropheContainer {
      z-index: 2;
      height: 93px;
      width: 93px;
      left: unset;
      top: unset;
      bottom: -30px;
      right: -50px;
      background-color: var(--primaryBase);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      @media (--mobileOnly) {
        width: 42px;
        height: 42px;
        bottom: -20px;
        right: -20px;
      }

      @media (--desktop) {
        height: 60%;
        min-height: 50px;
        width: 60%;
        min-width: 50px;
      }

      & .SvgIcon {
        fill: var(--grayscaleWhiteBase);
        width: 31px;
        height: 27px;

        @media (--mobileOnly) {
          width: 20px;
          height: 20px;
        }

        @media (--desktop) {
          width: 37px;
          height: 32px;
        }
      }
    }
  }

  & .SectionQuoteBlockBase__quote {
    @mixin wrapper;
    @mixin lost-column 6/8;
    @mixin lost-offset 1/8;
    padding: 25px 0 66px;

    @media (--desktop) {
      @mixin lost-column 5/11;
      @mixin lost-offset 0/11;
      padding: 110px 0 85px;
    }

    & blockquote {
      @mixin b0;
      @mixin lineClamp 8;
      font-weight: 700;
      white-space: pre-line;
      margin: 0 0 16px;

      @media (--desktop) {
        @mixin h3;
        margin-bottom: 24px;
      }
    }

    & .SectionQuoteBlockBase__author {
      @mixin p50;
      text-transform: uppercase;
      font-weight: var(--mediumFontWeight);
      letter-spacing: 0.2em;

      @media (--desktop) {
        @mixin p40;
      }
    }
  }
}
