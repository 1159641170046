.BuyCourse {
  height: 100%;

  & .BuyCourse__title {
    @mixin h50;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, var(--grayscaleBlackLighten10), 128px, 2px;
    }
  }

  & .BuyCourse__description {
    @mixin p20;
    color: var(--grayscaleBlackLighten10);
    margin-top: 16px;

    @media (--tablet) {
      @mixin p10;
      margin-top: 24px;
    }
  }

  & .BuyCourse__pricingMessage {
    @mixin p10;
    font-family: var(--baseFontFamily);
    font-weight: var(--mediumFontWeight);
    margin: 32px 0 0;
  }

  & .BuyCourse__priceOptions {
    display: grid;
    row-gap: 16px;
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }

    @media (--desktop) {
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      column-gap: 24px;
    }
  }

  & .BuyCourse__buyCourseButtonWrapper {
    display: block;
    align-items: center;
    margin-top: 32px;

    @media (--tablet) {
      display: flex;
      margin-top: 64px;
    }

    & .BuyCourse__paymentInProgress {
      display: flex;
      min-width: fit-content;
      align-items: center;
      color: var(--primaryBase);
      margin-top: 24px;
      margin-left: 24px;

      @media (--tablet) {
        margin-top: 0;
      }

      & .BuyCourse__paymentSpinner {
        margin-right: 8px;
      }
    }
  }

  & .BuyCourse__buyCourseButton {
    width: 100%;
    min-width: 235px;

    @media (--tablet) {
      width: auto;
    }
  }
}
