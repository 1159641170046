.ErrorHeader {
  @mixin headerContainer;
  z-index: 100;

  & .ErrorHeader__inner {
    @mixin wrapper;
    height: 100%;
  }

  & .ErrorHeader__content {
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grayscaleBlackLighten50);
  }

  &.ErrorHeader--sticky {
    box-shadow: var(--boxShadow10);

    & .ErrorHeader__content {
      border-bottom: 0;
    }
  }
}
