.AuthorDetails {
  & .AuthorDetails__wrapper {
    @mixin wrapper;
  }

  & .AuthorDetails__defaultImage {
    object-fit: cover;
  }

  & .AuthorDetails__name {
    @mixin h50;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin h20;
      margin-top: 32px;
    }
  }

  & .AuthorDetails__role {
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px, 1px;
    color: var(--primaryBase);
    margin: 0;

    @media (--tablet) {
      @mixin lineUnder 24px, var(--grayscaleBlackLighten30), 96px, 1px;
    }
  }

  & .AuthorDetails__description {
    @mixin p10;
    margin-top: 24px;

    @media (--tablet) {
      @mixin p30;
    }
  }

  & .AuthorDetails__content {
    padding-bottom: 48px;
  }

  & .AuthorDetails__authorContentTitle {
    @mixin p30;
    @mixin lineAfter 16px, var(--grayscaleBlackBase);
    margin: 40px 0 0;

    @media (--tablet) {
      @mixin h50;
      @mixin lineAfter 24px, var(--grayscaleBlackBase);
      margin-top: 48px;
    }
  }

  & .AuthorDetails__articlesList {
    @mixin gridList 32px, 48px, 2, 2, 2, 2;
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }
}
