.Lesson {
  padding-top: calc(8px + var(--mobileHeaderHeight));

  @media (--tablet) {
    padding-top: calc(8px + var(--headerHeight));
  }

  & .Lesson__inner {
    @mixin wrapper;
    padding-bottom: 64px;

    @media (--tablet) {
      padding-bottom: 96px;
    }
  }

  & .Lesson__content {
    @media (--desktop) {
      display: flex;
    }
  }

  & .Lesson__step {
    @media (--desktop) {
      @mixin lost-column 8/12;
    }
  }

  & .Lesson__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  & .Lesson__title {
    @mixin h50;
    @mixin breakWithHyphen;
    font-family: var(--headingsFontFamily);
    margin: 0;
    margin-right: 16px;
  }

  & .Lesson__bookmarkWrapper {
    flex-shrink: 0;
  }

  & .Lesson__description {
    @mixin p10;
    color: var(--grayscaleBlackLighten20);
    white-space: pre-line;
    margin: 0 0 24px;

    @media (--tablet) {
      margin-bottom: 32px;
    }
  }

  & .Lesson__stepActions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 64px;

    @media (--mobileOnly) {
      margin-bottom: 64px;
    }
  }

  & .Lesson__markStep {
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grayscaleBlackLighten30);
    margin-bottom: 24px;

    & .CompleteSwitch {
      margin-top: 0;
    }
  }

  & .Lesson__stepButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & .Lesson__previousStep,
    & .Lesson__nextStep {
      display: flex;
      align-items: center;
    }

    & .Lesson__previousStep {
      flex-direction: row-reverse;
    }

    & .Lesson__stepsProgress {
      display: block;

      @media (--desktop) {
        display: none;
      }
    }

    & .Lesson__navigationStepText {
      display: none;

      @media (--tablet) {
        display: initial;
        margin: 0 16px;
      }
    }
  }

  & .Lesson__stepsListWrapper {
    display: none;

    @media (--desktop) {
      @mixin lost-column 4/12;
      display: block;
      position: sticky;
      top: calc(16px + var(--headerHeight));
      left: 0;
      height: 600px;
      max-height: calc(100vh - var(--headerHeight) - 32px);
    }
  }

  & .Lesson__relatedSection {
    background-color: var(--support01Lighten20);
    padding: 32px 0 96px;

    @media (--tablet) {
      padding: 40px 0 128px;
    }

    & .Lesson__relatedContent {
      @mixin wrapper;
    }
  }
}
