.CongratulationPopup {
  position: relative;
  flex: 1;

  & .CongratulationPopup__inner {
    @mixin wrapper;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-top: 32px;
    padding-bottom: 48px;

    @media (--desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content min-content 1fr;
      grid-template-areas:
        "progress progress"
        "title title"
        "link link"
        "training image";
      align-items: start;
      padding-top: 80px;
      padding-bottom: 0;
    }
  }

  & .CongratulationPopup__progressWrapper {
    display: flex;
    align-items: center;
    width: 100%;

    @media (--desktop) {
      grid-area: progress;
    }
  }

  & .CongratulationPopup__progressInfo {
    @mixin h50;
    font-weight: var(--boldFontWeight);
    font-family: var(--headingsFontFamily);
    flex-shrink: 0;
    margin-right: 16px;
  }

  & .CongratulationPopup__buyCourseWrapper {
    margin-top: 32px;

    @media (--desktop) {
      grid-column-start: span 2;
      grid-row-start: span 3;
      margin-top: 64px;
    }
  }

  & .CongratulationPopup__title {
    @mixin h50;
    margin: 32px 0 0;

    @media (--tablet) {
      @mixin h40;
    }

    @media (--desktop) {
      @mixin h30;
      grid-area: title;
      margin-top: 64px;
    }
  }

  & .CongratulationPopup__actionWrapper {
    margin-top: 32px;

    @media (--tablet) {
      display: flex;
    }

    @media (--desktop) {
      grid-area: link;
      margin-top: 40px;
    }
  }

  & .CongratulationPopup__link.CongratulationPopup__link {
    @mixin horizontalGap 16px;

    @media (--mobileOnly) {
      width: 100%;
    }

    &.CongratulationPopup__link--secondary {
      @media (--mobileOnly) {
        margin-top: 16px;
      }
    }
  }

  & .CongratulationPopup__trainingSection {
    margin: 32px 0 0;

    @media (--desktop) {
      grid-area: training;
      padding-bottom: 80px;
      margin-top: 64px;
    }
  }

  & .CongratulationPopup__trainingTitle {
    @mixin p30;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    margin: 0;

    @media (--tablet) {
      @mixin h50;
    }
  }

  & .CongratulationPopup__trainingList {
    @mixin clearList;
    width: 100%;
    margin-top: 16px;

    @media (--tablet) {
      max-width: 356px;
      margin-top: 32px;
    }

    & > li {
      @mixin verticalGap 16px;

      @media (--tablet) {
        @mixin verticalGap 24px;
      }
    }
  }

  & .CongratulationPopup__imageWrapper {
    display: none;

    @media (--desktop) {
      @mixin aspectRatio 3/4;
      display: block;
      grid-area: image;
      align-self: stretch;
      width: 100%;
      max-width: 600px;
      box-sizing: content-box;
      padding-right: var(--tabletWrapperPadding);
      margin: 0 auto;
    }

    @media (--desktop) {
      padding-right: var(--desktopSmallWrapperPadding);
    }

    @media (--desktopHD) {
      padding-right: var(--desktopLargeWrapperPadding);
    }
  }

  & .CongratulationPopup__image {
    object-fit: contain;

    @media (--desktop) {
      object-position: right bottom;
    }
  }
}
