.CompletedMark {
  @mixin tag;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: var(--actionSuccessBase);
  color: var(--grayscaleBlackBase);
  padding: 2px 10px;
  border-radius: 6px 0;
}
