.MatchTaskTable {
  @mixin horizontalScrollbar 0, var(--support01Lighten60), var(--grayscaleBlackLighten20);
  width: 100%;
  overflow-x: auto;
  color: var(--grayscaleBlackLighten10);
  margin-top: 48px;

  & .MatchTaskTable__fixed {
    position: sticky;
    left: 0;
    background-color: var(--support01Lighten60);
    z-index: 2;
  }

  & .MatchTaskTable__topAnswersCell {
    padding: 16px 24px 16px 16px;
  }

  & .MatchTaskTable__topAnswersCellText {
    @mixin p30;
  }

  & .MatchTaskTable__questionCell {
    text-align: left;
    min-width: 250px;
    padding: 16px 24px 16px 16px;
    border-right: 1px solid var(--grayscaleBlackLighten50);
    border-bottom: 1px solid var(--grayscaleBlackLighten50);
  }

  & .MatchTaskTable__questionCellText {
    @mixin p20;
  }

  & .MatchTaskTable__answerCell {
    min-width: 200px;
    padding: 16px 24px 16px 16px;
    border-right: 1px solid var(--grayscaleBlackLighten50);
    border-bottom: 1px solid var(--grayscaleBlackLighten50);

    &:last-child {
      border-right: none;
    }
  }
}
