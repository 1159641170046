.ThemesWithFreeStepsSection {
  @mixin fullWidthBackground var(--support02Base);
  padding: 48px 0 64px;

  @media (--tablet) {
    padding: 80px 0 96px;
  }

  @media (--desktop) {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: 7fr 1fr 4fr;
    grid-template-areas:
      "header . circle"
      "content content content"
      "action action action";
  }

  @media (--desktopHD) {
    grid-template-columns: 5fr 3fr 4fr;
    grid-template-areas:
      "header . circle"
      "content content content"
      "action action action";
  }

  & .ThemesWithFreeStepsSection__header {
    grid-area: header;
    color: var(--grayscaleBlackLighten10);
    overflow: hidden;
  }

  & .ThemesWithFreeStepsSection__title {
    @mixin h30;
    @mixin lineUnder 16px, currentColor, 128px, 2px;
    font-weight: var(--boldFontWeight);
    margin: 0;

    @media (--desktop) {
      @mixin h10;
    }
  }

  & .ThemesWithFreeStepsSection__description {
    @mixin p10;
    white-space: pre-line;
    margin: 16px 0 0;

    @media (--tablet) {
      margin-top: 24px;
    }
  }

  & .ThemesWithFreeStepsSection__circle {
    display: none;

    @media (--desktop) {
      @mixin h30;
      color: var(--grayscaleWhiteBase);
      font-weight: var(--boldFontWeight);
      font-family: var(--headingsFontFamily);
      grid-area: circle;
      align-self: center;
      justify-self: center;
      background-color: var(--primaryBase);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 240px;
      height: 240px;
      padding: 32px 24px;
      border-radius: 50%;
    }
  }

  & .ThemesWithFreeStepsSection__content {
    grid-area: content;
    margin-top: 48px;

    @media (--desktop) {
      margin-top: 80px;
    }
  }

  & .ThemesWithFreeStepsSection__themesList {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;

    @media (--mobileXLarge) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: 40px;
    }
  }

  & .ThemesWithFreeStepsSection__linkWrapper {
    grid-area: action;
    margin-top: 48px;

    @media (--desktop) {
      margin-top: 80px;
    }
  }

  & .ThemesWithFreeStepsSection__link {
    @media (--mobileOnly) {
      width: 100%;
    }
  }
}
