.ProductHero {
  position: relative;

  & .ProductHero__inner {
    @mixin wrapper;
    position: relative;
    display: block;
    padding-top: var(--mobileHeaderHeight);

    @media (--tablet) {
      display: flex;
      align-items: flex-start;
      padding-top: var(--headerHeight);
    }

    & .ProductHero__info {
      padding: 32px 0;

      @media (--tablet) {
        @mixin lost-column 6/12;
        padding: 64px 0;
      }
    }

    & .ProductHero__arrow {
      transform: rotate(90deg);
    }

    & .ProductHero__title {
      @mixin h30;
      @mixin lineUnder 16px, var(--grayscaleBlackBase), 60%, 2px;
      margin: 0 0 32px;

      @media (--tablet) {
        @mixin h10;
        @mixin lineUnder 24px, var(--grayscaleBlackBase), 60%, 2px;
        margin-bottom: 48px;
      }
    }

    & .ProductHero__description {
      @mixin p10;
      font-weight: var(--mediumFontWeight);
      white-space: pre-line;
      margin: 0 0 32px;

      @media (--tablet) {
        @mixin h40;
        margin-bottom: 48px;
      }
    }

    & .ProductHero__imageWrapper {
      @mixin aspectRatio 1/1;
      width: 80%;
      max-width: 300px;
      margin: 32px auto 48px;

      @media (--tablet) {
        @mixin lost-column 6/12;
        box-sizing: content-box;
        padding: 0 var(--tabletWrapperPadding) var(--tabletWrapperPadding) 0;
        margin: 0;
      }

      @media (--desktop) {
        padding: 0 var(--desktopSmallWrapperPadding) var(--desktopSmallWrapperPadding) 0;
      }

      @media (--desktopHD) {
        padding: 0 var(--desktopLargeWrapperPadding) var(--desktopLargeWrapperPadding) 0;
      }

      & img {
        object-fit: contain;
        object-position: right;
      }
    }
  }
}
