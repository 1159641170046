.Theme {
  overflow-x: hidden;

  & .Theme__mainSection {
    background-color: var(--grayscaleWhiteBase);
    padding-bottom: 64px;

    @media (--tablet) {
      padding-bottom: 96px;
    }
  }

  & .Theme__progress {
    @mixin wrapper;
    transform: translateY(-64px);
  }

  & .Theme__lessons {
    @mixin wrapper;
  }

  & .Theme__trainingSectionWrapper {
    @mixin wrapper;
  }

  & .Theme__lessons + .Theme__trainingSectionWrapper {
    margin-top: 48px;

    @media (--tablet) {
      margin-top: 96px;
    }
  }

  & .Theme__bottomNavigation {
    display: none;

    @media (--tablet) {
      @mixin wrapper;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 64px;
    }

    &:empty {
      display: none;
    }
  }

  & .Theme__bottomNavigationElement {
    @mixin horizontalGap 32px;
    @mixin actionOverlay;
    display: flex;
    align-items: center;

    &.Theme__bottomNavigationElement--next {
      margin-left: auto;
    }
  }

  & .Theme__navigationInfo {
    &:first-child {
      margin-right: 24px;
    }

    &:last-child {
      margin-left: 24px;
    }
  }

  & .Theme__navigationText {
    @mixin h50;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    margin: 0;
  }

  & .Theme__navigationThemeTitle {
    @mixin p30;
    color: var(--primaryBase);
    margin: 4px 0 0;
  }

  & .Theme__navigationLink {
    z-index: 1;
  }

  & .Theme__extraContent {
    @mixin wrapper;
    margin: 32px auto 96px;

    @media (--tablet) {
      margin: 48px auto 128px;
    }

    &:empty {
      margin: 0 auto 0;
    }
  }

  & .Theme__relatedContent {
    margin-top: 48px;

    @media (--tablet) {
      margin-top: 96px;
    }
  }
}
