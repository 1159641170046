.PersonCard {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .PersonCard__imageContainer {
    width: 224px;
    height: 224px;

    @media (--tablet) {
      width: 290px;
      height: 290px;
    }
  }

  & .PersonCard__image {
    object-fit: cover;
    border-radius: 50%;
  }

  & .PersonCard__name {
    @mixin h50;
    font-weight: var(--boldFontWeight);
    font-family: var(--headingsFontFamily);
    margin: 24px 0 0;
  }

  & .PersonCard__role {
    @mixin p50;
    color: var(--primaryBase);
    margin: 0;
  }
}
