.VimeoPlayer {
  width: 100%;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
