.CourseThumbnail {
  display: flex;
  flex-direction: column;
  background-color: var(--support01Lighten20);
  overflow: hidden;
  height: 100%;
  border-radius: 16px;

  & .CourseThumbnail__imageWrapper {
    background-color: var(--grayscaleWhiteBase);
  }

  & .CourseThumbnail__productTitle {
    @mixin p50;
    @mixin highlightedText;
    margin: 16px 0 0;

    @media (--tablet) {
      margin: 24px 0 0;
    }
  }

  & .CourseThumbnail__content {
    text-align: center;
    flex-grow: 1;
    padding: 0 16px;
  }

  & .CourseThumbnail__courseTitle {
    @mixin h40;
    margin: 5px 0 0 0;
  }

  & .CourseThumbnail__content .CourseButton {
    width: 55%;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 36px auto 8px;
    margin-top: 16px;

    @media (--mobileOnly) {
      height: 50%;
    }

    @media (--tablet) {
      margin: 32px auto 16px;
    }

    & span {
      white-space: nowrap;
    }
  }
}
