@define-mixin headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: var(--mobileHeaderHeight);
  width: 100%;
  background-color: var(--support01Lighten60);

  @media (--tablet) {
    height: var(--headerHeight);
  }
}
