.AuthorThumbnail {
  @mixin actionOverlay;
  height: 100%;
  background-color: var(--grayscaleWhiteBase);
  overflow: hidden;
  box-shadow: var(--boxShadow20);
  outline: 1px solid transparent;
  transition: box-shadow 0.2s ease-out, outline 0.2s ease-out;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  --buttonHeight: calc(40px + 32px);

  @media (--tablet) {
    border-radius: 16px;
  }

  &:hover {
    @media (--desktop) {
      box-shadow: none;
      outline-color: var(--grayscaleBlackLighten10);

      & .ThumbnailImage__picture {
        transform: scale(1.11) translateY(calc(-1 * var(--buttonHeight)));
      }

      & .AuthorThumbnail__detailsText {
        transform: translateY(calc(-1 * var(--buttonHeight)));
      }

      & .AuthorThumbnail__learMoreButton {
        transform: translateY(0);
      }
    }
  }

  & .AuthorThumbnail__details {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px 24px 0;

    @media (--tablet) {
      padding: 24px 32px 0;
    }
  }

  & .AuthorThumbnail__detailsText {
    flex: 1;
    transition: transform 0.4s;
  }

  & .AuthorThumbnail__role {
    @mixin p40;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
    display: block;
    color: var(--primaryBase);

    @media (--tablet) {
      @mixin lineUnder 24px, var(--grayscaleBlackLighten30), 96px;
    }
  }

  & .AuthorThumbnail__title {
    @mixin h50;
    color: var(--grayscaleBlackLighten10);
    margin: 0;
  }

  & .AuthorThumbnail__description {
    @mixin lineClamp 3;
    @mixin p30;
    color: var(--grayscaleBlackLighten10);
    margin: 16px 0 0;

    @media (--desktop) {
      @mixin p10;
      margin: 24px 0 0;
    }
  }

  & .AuthorThumbnail__learMoreButton {
    display: flex;
    position: relative;
    z-index: 99;
    margin: 24px 0 32px;

    @media (--desktop) {
      transform: translateY(var(--buttonHeight));
      transition: transform 0.4s;
      margin: 0 0 32px;
    }
  }
}
