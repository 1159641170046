.ThemeResultItem {
  @media (--tablet) {
    display: grid;
    grid-template-columns: 7fr 5fr;
    align-items: center;
    column-gap: 40px;
  }

  @media (--desktop) {
    grid-template-columns: 1fr 1fr;
  }

  & .ThemeResultItem__themeInfo {
    @mixin actionOverlay;
    display: flex;
    align-items: flex-end;

    @media (--tablet) {
      align-items: center;
    }
  }

  & .ThemeResultItem__imageContainer {
    width: 48px;

    @media (--tablet) {
      width: 64px;
    }
  }

  & .ThemeResultItem__title {
    @mixin p30;
    color: var(--grayscaleBlackLighten10);
    font-weight: var(--boldFontWeight);
    font-family: var(--headingsFontFamily);
    flex: 1;
    overflow: hidden;
    margin: 0 0 0 8px;

    @media (--tablet) {
      @mixin p20;
      margin-left: 24px;
    }

    @media (--desktop) {
      @mixin h50;
    }
  }

  & .ThemeResultItem__progressInfo {
    @media (--mobileOnly) {
      margin-top: 16px;
    }
  }
}
