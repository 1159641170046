.Bookmark {
  @mixin defaultGutter;
  display: grid;
  grid-template-areas: "thumbnail info";
  grid-template-columns: 3fr 5fr;
  align-items: start;

  @media (--tablet) {
    grid-template-columns: 3fr 7fr;
  }

  & .Bookmark__thumbnail {
    grid-area: thumbnail;
  }

  & .Bookmark__info {
    grid-area: info;
    display: grid;
    grid-template-areas:
      "subtitle popover"
      "title title"
      "message message";
    grid-template-columns: 1fr min-content;
    column-gap: 4px;

    @media (--tablet) {
      @mixin defaultGutter;
    }
  }

  & .Bookmark__subtitle {
    @mixin p50;
    @mixin highlightedText;
    grid-area: subtitle;

    @media (--tablet) {
      @mixin p40;
    }
  }

  & .Bookmark__title {
    @mixin p40;
    grid-area: title;
    margin: 4px 0 0;

    @media (--tablet) {
      @mixin h30;
      margin: 0;
    }
  }

  & .Bookmark__message {
    grid-area: message;
    margin-top: 8px;

    @media (--tablet) {
      margin-top: 16px;
    }
  }

  & .Bookmark__popoverwrapper {
    grid-area: popover;
    margin-bottom: auto;
  }

  & .Bookmark__popover {
    display: flex;
    flex-direction: column;
  }

  & .Bookmark__popoverElement.Bookmark__popoverElement {
    display: flex;
    align-items: center;
    font-weight: var(--mediumFontWeight);
    margin-top: 8px;

    &:hover {
      color: var(--grayscaleBlackLighten20);
    }

    &:first-child {
      margin-top: 0;
    }
  }

  & .Bookmark__popoverIcon {
    fill: currentColor;
    margin-right: 8px;
  }
}
