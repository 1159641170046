/* THESE ARE LEGACY MIXINS AND VARIABLES. DON'T USE THEM I NEW COMPONENTS */

@define-mixin h0 {
  font-size: var(--h0FontSize);
  line-height: var(--h0LineHeight);
}

@define-mixin h1 {
  font-size: var(--h1FontSize);
  line-height: var(--h1LineHeight);
}

@define-mixin h2 {
  font-size: var(--h2FontSize);
  line-height: var(--h2LineHeight);
}

@define-mixin h3 {
  font-size: var(--h3FontSize);
  line-height: var(--h3LineHeight);
}

@define-mixin h4 {
  font-size: var(--h4FontSize);
  line-height: var(--h4LineHeight);
}

@define-mixin h5 {
  font-size: var(--h5FontSize);
  line-height: var(--h5LineHeight);
}

@define-mixin b0 {
  font-size: var(--b0FontSize);
  line-height: var(--b0LineHeight);
}

@define-mixin b1 {
  font-size: var(--b1FontSize);
  line-height: var(--b1LineHeight);
}

@define-mixin b2 {
  font-size: var(--b2FontSize);
  line-height: var(--b2LineHeight);
}

@define-mixin b3 {
  font-size: var(--b3FontSize);
  line-height: var(--b3LineHeight);
}

@define-mixin tag {
  font-size: 0.75rem; /* 12px */
  line-height: 1.16; /* 14px */
}
