@define-mixin scrollbar $trackBackgroundColor, $thumbBackgroundColor {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    width: 16px;
    height: 0;
    background-color: $trackBackgroundColor;
    margin-top: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumbBackgroundColor;
    min-height: 44px;
    border: 4px solid $trackBackgroundColor;
    border-radius: 12px;
  }
}

@define-mixin horizontalScrollbar $paddingBottom, $trackBackgroundColor: var(--grayscaleWhiteBase),
  $thumbBackgroundColor: var(--grayscaleBlackLighten10) {
  overflow-x: auto;
  padding-bottom: calc($paddingBottom + 16px);

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    height: 8px;
    width: 0;
    background-color: $trackBackgroundColor;
    border-radius: 80px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumbBackgroundColor;
    border-radius: 80px;
  }
}
