.TrainingGroundSection {
  & .TrainingGroundSection__list {
    & li {
      margin-top: 24px;

      @media (--tablet) {
        margin-top: 32px;
      }
    }

    &.TrainingGroundSection__list--vertical {
      @media (--tablet) {
        display: flex;
        flex-wrap: wrap;
      }

      & li {
        @media (--tablet) {
          @mixin lost-column 6/12, 2;
        }

        @media (--desktop) {
          @mixin lost-column 4/12, 3;
        }
      }
    }
  }
}
