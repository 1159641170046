.CoursesSection {
  @mixin fullWidthBackground var(--support02Base);
  padding: 32px 0;

  @media (--tablet) {
    padding: 80px 0 96px 0;
  }
}

& .CoursesSection__inner {
  @mixin wrapper;
  text-align: center;
}

& .CoursesSection__title {
  @mixin h2;
  @mixin lineUnder 16px, var(--grayscaleBlackLighten10), 45%, 1px, 0;
  text-align: center;
  position: relative;
  margin: 0;

  @media (--tablet) {
    @mixin h1;
    @mixin lineUnder 24px, var(--grayscaleBlackLighten10), 40%, 1px, 30%;
  }
}

& .CoursesSection__listWrapper {
  @media (--tablet) {
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
    grid-template-areas: ". content .";
  }
}

& .CoursesSection__list.CoursesSection__list {
  width: 100%;
  margin-top: 32px;

  @media (--tablet) {
    @mixin defaultGutter;
    grid-area: content;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 4fr));
    row-gap: 32px;
    margin-top: 64px;
  }

  & li {
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child:nth-child(2n - 1) {
      grid-column-start: span 2;
      justify-self: center;
    }

    &:only-child {
      grid-column-start: span 2;
      justify-self: center;
    }
  }
}
