.Modal {
  @mixin fixedFullSize;
  z-index: 2000;

  & .Modal__closeBackgroundButton {
    @mixin fixedFullSize;
    background-color: var(--backgroundShadow);

    &:hover {
      background-color: var(--backgroundShadow);
    }
  }

  & .Modal__outer {
    @media (--mobileOnly) {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    @media (--tablet) {
      @mixin wrapper;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  & .Modal__wrapper {
    width: 100%;
    position: relative;
    z-index: 1;

    @media (--tablet) {
      @mixin lost-column 8/12;
      min-width: 620px;
    }

    &.Modal__wrapper--small {
      @media (--tablet) {
        @mixin lost-column 6/12;
        min-width: 500px;
      }
    }
  }

  & .Modal__panel {
    display: flex;
    flex-direction: column;
    background-color: var(--grayscaleWhiteBase);
    color: var(--grayscaleBlackLighten10);
    max-height: calc(100vh - 64px - var(--mobileSafariBottomInset));
    overflow: hidden;
    border-radius: 16px 16px 0 0;

    @media (--tablet) {
      border-radius: 24px;
    }
  }

  & .Modal__inner {
    @mixin scrollbar var(--grayscaleWhiteBase), var(--grayscaleBlackLighten10);
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  & .Modal__closeButton {
    display: none;

    @media (--tablet) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      z-index: 101;
    }
  }
}
