.Popover {
  & .Popover__button {
    @mixin aspectRatio 1/1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .Popover__icon {
    position: relative;
    stroke: var(--grayscaleBlackBase);
  }
}
