.BetaNewsletterPopup {
  & .BetaNewsletterPopup__container {
    padding: 32px 32px 48px;

    @media (--tablet) {
      padding: 48px 64px 32px;
    }
  }

  & .BetaNewsletterPopup__title {
    @mixin h40;
    color: var(--grayscaleBlackLighten10);
    margin: 0;

    @media (--tablet) {
      @mixin h30;
    }
  }

  & .BetaNewsletterPopup__description {
    @mixin p20;
    color: var(--grayscaleBlackLighten10);
    margin: 16px 0 0;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .BetaNewsletterPopup__content {
    margin-top: 32px;
  }

  & .BetaNewsletterPopup__fieldContainer {
    width: 100%;
  }

  & .BetaNewsletterPopup__formConsent {
    margin-top: 8px;
  }

  & .BetaNewsletterPopup__processingAgreementLabel {
    @mixin p30;
  }

  & .BetaNewsletterPopup__submitButton {
    width: 100%;
    margin-top: 16px;

    @media (--tablet) {
      width: auto;
    }
  }
}
