.LoadMoreList {
  & .LoadMoreList__showMore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;

    @media (--tablet) {
      margin-top: 64px;
    }

    & .LoadMoreList__showingOf {
      @mixin p30;
      font-weight: var(--mediumFontWeight);
      margin: 0 0 0 24px;
    }
  }
}
