.UserLinksSheet {
  @mixin fixedFullSize;
  pointer-events: none;
  z-index: 201;

  @media (--tablet) {
    display: none;
  }

  & .UserLinksSheet__inner {
    pointer-events: all;
  }
}
