.PortableTextContent {
  @mixin p10;

  & * {
    max-width: 100%;

    & .GeoGebraApplet * {
      max-width: unset;
    }
  }

  & > *,
  & > div > * {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  & figure {
    margin: 32px 0;
  }

  & .PortableTextContent__mathBlock {
    @mixin p10;
    overflow-x: auto;
    overflow-y: hidden;

    &:first-child {
      & .katex-display {
        margin-top: 0;
      }
    }

    &:last-child {
      & .katex-display {
        margin-bottom: 0;
      }
    }
  }

  & .PortableTextContent__listItem {
    & div {
      display: inline;

      & .katex-display {
        display: inline;

        & .katex {
          display: inline;
        }

        & .katex-html {
          display: inline;
        }
      }
    }

    &.PortableTextContent__listItem--roman {
      list-style-type: none;
      counter-increment: listItemRomanNumber 1;

      &::marker {
        content: counter(listItemRomanNumber, upper-roman) " ";
      }
    }
  }

  & .PortableTextContent__imageWrapper {
    @media (--mobileOnly) {
      width: 20rem;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    & figure {
      margin: 0;
    }

    &.PortableTextContent__imageWrapper--extraLarge {
      width: 100%;
    }

    &.PortableTextContent__imageWrapper--large {
      width: 80%;
    }

    &.PortableTextContent__imageWrapper--medium {
      width: 60%;
    }

    &.PortableTextContent__imageWrapper--small {
      width: 40%;
    }

    &.PortableTextContent__imageWrapper--ratio {
      position: relative;

      & img {
        @mixin absoluteParentSize;
        object-fit: contain;
      }
    }
  }

  & .PortableTextContent__videoPlayer {
    @mixin aspectRatio 9/16;
    position: relative;
    width: 100%;
    background-color: var(--grayscaleBlackLighten20);
  }
}
