.Text {
  &.Text--h2 {
    @mixin lineUnder 16px, var(--grayscaleBlackLighten30), 96px, 1px;
    font-size: 1.2em;
    margin: 0 0 16px;

    @media (--tablet) {
      @mixin lineUnder 16px, currentColor, 128px, 2px;
      font-size: 1.6em;
      line-height: 1.5em;
      margin-bottom: 32px;
    }
  }

  &.Text--h3 {
    @mixin lineUnder 16px, var(--grayscaleBlackLighten30), 96px, 1px;
    font-size: 1em;
    line-height: 1.6em;
    margin: 0 0 16px;

    @media (--tablet) {
      margin-bottom: 24px;
    }
  }

  &.Text--normal {
    margin: 0 0 1em;
  }

  &.Text {
    display: block;
  }
}
