.AuthorsForProductPage {
  @mixin wrapper;
  padding-top: calc(48px + var(--mobileHeaderHeight));

  @media (--tablet) {
    padding-top: calc(88px + var(--headerHeight));
  }

  & .AuthorsForProductPage__header {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      "text"
      "image";
    margin-bottom: 48px;

    @media (--desktop) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas: "text image";
      margin-bottom: 56px;
    }
  }

  & .AuthorsForProductPage__headerText {
    grid-area: text;
  }

  & .AuthorsForProductPage__title {
    @mixin h30;
    @mixin lineUnder 16px, var(--grayscaleBlackLighten10), 128px, 2px;
    margin: 0;

    @media (--desktop) {
      @mixin lineUnder 24px, var(--grayscaleBlackLighten10), 240px, 2px;
      @mixin h10;
    }
  }

  & .AuthorsForProductPage__description {
    @mixin p10;
    font-weight: var(--mediumFontWeight);
    margin-top: 32px;

    @media (--desktop) {
      @mixin h40;
    }
  }

  & .AuthorsForProductPage__headerImageWrapper {
    grid-area: image;
    margin-top: 24px;
  }

  & .AuthorsForProductPage__content {
    @mixin fullWidthBackground var(--support01Base);
    padding: 48px 0 80px;

    @media (--desktop) {
      padding: 80px 0 160px;
    }
  }

  & .AuthorsForProductPage__authorsList {
    display: grid;
    row-gap: 32px;

    @media (--mobileXLarge) {
      @mixin defaultGutter;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (--tablet) {
      row-gap: 48px;
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
