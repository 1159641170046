.StepsListNavigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (--desktop) {
    flex-direction: row;
  }

  & .StepsListNavigation__button.StepsListNavigation__button {
    width: 100%;

    @media (--desktop) {
      width: auto;
    }

    @media (--desktopLarge) {
      @mixin mediumButton;
    }

    &:not(:last-child) {
      margin-bottom: 16px;

      @media (--desktop) {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
  }
}
