.AboutUs {
  padding-top: var(--mobileHeaderHeight);

  @media (--tablet) {
    padding-top: var(--headerHeight);
  }

  & .AboutUs__pageHeader {
    background-color: var(--grayscaleBlackBase);
    position: relative;
    overflow: hidden;

    & .AboutUs__pageHeaderImageContainer {
      width: 100%;
      max-height: 665px;
      opacity: 0.5;
    }

    & .AboutUs__pageHeaderImage {
      object-fit: cover;
    }

    & .AboutUs__pageHeaderText {
      @mixin wrapper;
      text-align: center;
      position: absolute;
      color: var(--grayscaleWhiteBase);
      z-index: 9;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      & .AboutUs__pageHeaderTitle {
        @mixin h30;
        font-weight: var(--boldFontWeight);
        margin: 0;

        @media (--mobileLarge) {
          @mixin h20;
        }

        @media (--tablet) {
          @mixin h10;
        }
      }

      & .AboutUs__pageHeaderSubtitle {
        @mixin p30;
        margin: 0;

        @media (--mobileLarge) {
          @mixin p20;
        }

        @media (--tablet) {
          @mixin p10;
        }
      }
    }
  }

  & .AboutUs__aboutProduct {
    @mixin wrapper;
    display: flex;
    align-items: center;
    margin: 80px 0;

    @media (--tablet) {
      margin: 140px auto;
    }

    & .AboutUs__aboutProductContent {
      @mixin defaultGutter;
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      row-gap: 24px;

      @media (--tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    & .AboutUs__aboutProductText {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & .AboutUs__aboutProductTitle {
      @mixin h30;
      @mixin lineUnder 16px, var(--grayscaleBlackBase);
      font-weight: var(--boldFontWeight);
      font-family: var(--headingsFontFamily);
      margin: 0;

      @media (--tablet) {
        @mixin h10;
        @mixin lineUnder 24px, var(--grayscaleBlackBase);
      }
    }

    & .AboutUs__aboutProductDescription {
      margin: 24px 0 0;
    }
  }

  & .AboutUs__authorsOfProduct {
    background-color: var(--support01Lighten40);
    padding: 80px 0 128px;

    & .AboutUs__authorsOfProductContent {
      @mixin wrapper;

      & .AboutUs__authorsOfProductText {
        @media (--desktop) {
          max-width: 50%;
        }
      }

      & .AboutUs__authorsOfProductTitle {
        @mixin h30;
        @mixin lineUnder 16px, var(--grayscaleBlackBase);
        font-weight: var(--boldFontWeight);
        font-family: var(--headingsFontFamily);

        @media (--tablet) {
          @mixin h10;
          @mixin lineUnder 24px, var(--grayscaleBlackBase);
        }
      }

      & .AboutUs__authorsOfProductDescription {
        @mixin p30;

        @media (--tablet) {
          @mixin p20;
        }
      }

      & .AboutUs__authorsOfProductSlider {
        margin-top: 64px;

        @media (--tablet) {
          margin-top: 80px;
        }
      }
    }
  }

  & .AboutUs__studentsQuotes {
    background-color: var(--support01Base);
    padding: 256px 0 128px;

    @media (--desktop) {
      padding: 128px 0 200px;
    }

    & .AboutUs__studentsQuotesContent {
      @mixin wrapper;
      position: relative;

      & .AboutUs__studentsQuotesTitle {
        @mixin h40;
        text-align: center;
        font-weight: var(--boldFontWeight);
        font-family: var(--headingsFontFamily);
        margin: 0;

        @media (--tablet) {
          @mixin h20;
        }
      }

      & .AboutUs__studentsQuotesImageContainer {
        position: absolute;
        width: 134px;
        height: 116px;
        top: -50%;
        right: 50%;
        transform: translateX(50%);

        @media (--desktop) {
          top: 0;
          right: 10%;
        }
      }

      & .AboutUs__studentsQuotesSlider {
        margin-top: 40px;

        @media (--desktop) {
          margin-top: 128px;
        }
      }
    }
  }

  & .AboutUs__peopleBehindProduct {
    @mixin wrapper;
    padding-top: 160px;
    padding-bottom: 200px;

    & .AboutUs__peopleBehindProductHeader {
      display: block;

      @media (--tablet) {
        display: flex;
        justify-content: space-between;
      }

      & .AboutUs__peopleBehindProductText {
        @media (--tablet) {
          width: 50%;
        }
      }

      & .AboutUs__peopleBehindProductTitle {
        @mixin h30;
        @mixin lineUnder 16px, var(--grayscaleBlackBase);
        font-weight: var(--boldFontWeight);
        font-family: var(--headingsFontFamily);
        margin: 0;

        @media (--tablet) {
          @mixin h10;
          @mixin lineUnder 24px, var(--grayscaleBlackBase);
        }
      }

      & .AboutUs__peopleBehindProductDescription {
        @mixin p30;
        margin-top: 24px;

        @media (--tablet) {
          @mixin p20;
        }
      }
    }

    & .AboutUs__peopleBehingProductImage {
      max-width: 510px;
      margin-top: 40px;

      @media (--tablet) {
        flex: 1;
        justify-self: flex-end;
        margin-top: 0;
      }
    }

    & .AboutUs__peopleBehindProductList {
      margin-top: 136px;

      @media (--tablet) {
        margin-top: 160px;
      }
    }
  }

  & .AboutUs__bottomSection {
    background-color: var(--grayscaleBlackBase);

    & .AboutUs__bottomSectionImageContainer {
      width: 100%;
      opacity: 0.5;

      & .AboutUs__bottomSectionImage {
        object-fit: cover;
      }
    }
  }

  & .AboutUs__ourCourses {
    @mixin wrapper;
    padding-top: 128px;
    padding-bottom: 196px;

    & .AboutUs__ourCoursesTitle {
      @mixin h30;
      @mixin lineUnder 16px, var(--grayscaleBlackBase);
      font-weight: var(--boldFontWeight);
      font-family: var(--headingsFontFamily);
      margin: 0;

      @media (--tablet) {
        @mixin lineUnder 24px, var(--grayscaleBlackBase);
        @mixin h10;
      }
    }

    & .AboutUs__ourCoursesDescription {
      @mixin p30;
      margin-top: 24px;

      @media (--tablet) {
        @mixin p20;
      }
    }

    & .AboutUs__ourCoursesList {
      margin-top: 48px;

      @media (--tablet) {
        @mixin defaultGutter;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 6fr));
        row-gap: var(--tabletGutter);
      }

      @media (--desktop) {
        margin-top: 80px;
      }

      @media (--desktopHD) {
        row-gap: var(--desktopLargeGutter);
      }
    }

    & .AboutUs__ourCoursesListElement {
      @media (--mobileOnly) {
        &:not(:first-child) {
          margin-top: 32px;
        }
      }
    }
  }
}
