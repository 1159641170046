.TestTask {
  color: var(--grayscaleBlackLighten10);

  & .TestTask__taskContent {
    padding-top: 24px;

    @media (--tablet) {
      padding-top: 56px;
    }
  }
}
