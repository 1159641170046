@define-mixin breakWithHyphen {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: manual;
}

@define-mixin lineClamp $line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
}

@define-mixin lineUnder $bottomSpacing: 24px, $color: var(--grayscaleBlackLighten20), $width: 60%, $height: 1px,
  $left: 0 {
  position: relative;
  display: inline-block;
  padding-bottom: $bottomSpacing;

  &::after {
    content: "";
    position: absolute;
    left: $left;
    bottom: 0;
    height: $height;
    width: $width;
    transition: width 0.2s ease-out;
    background-color: $color;
  }
}

@define-mixin lineAbove $topSpacing: 24px, $color: var(--grayscaleBlackLighten20), $width: 60%, $height: 1px {
  position: relative;
  display: inline-block;
  padding-top: $topSpacing;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: $height;
    width: $width;
    transition: width 0.2s ease-out;
    background-color: $color;
  }
}

@define-mixin lineAfter $leftSpacing: 24px, $color: var(--grayscaleBlackLighten20), $mobileDisplay: block {
  display: flex;
  align-items: center;

  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: $color;
    margin-left: $leftSpacing;

    @media (--mobileOnly) {
      display: $mobileDisplay;
    }
  }
}

@define-mixin highlightedText {
  color: var(--primaryBase);
  font-weight: var(--mediumFontWeight);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
