.Footer {
  background-color: var(--primaryBase);
  color: var(--grayscaleWhiteBase);
  width: 100%;
  min-height: var(--mobileFooterMinHeight);

  @media (--tablet) {
    min-height: var(--footerMinHeight);
  }

  & .Footer__wrapper {
    @mixin wrapper;
    display: block;
    padding-top: 40px;
    padding-bottom: 32px;

    @media (--desktopHD) {
      display: grid;
      grid-template-rows: min-content 1fr min-content;
      grid-template-columns: 4fr 8fr;
      grid-template-areas:
        "logo logo"
        ". middleSection"
        "copyright copyright";
    }
  }

  & .Footer__middleSection {
    display: block;
    grid-area: middleSection;
    margin-top: 48px;

    @media (--desktop) {
      display: flex;
      justify-content: center;
      margin-top: 56px;
    }

    & .Footer__middleSectionSplitLine {
      display: none;
      grid-area: splitLine;

      @media (--desktop) {
        @mixin splitLine auto, 64px, var(--primaryLighten40);
        display: block;
      }
    }

    & .Footer__linksSectionsList {
      display: block;
      grid-area: links;

      @media (--desktop) {
        display: flex;
      }
    }

    & .Footer__feedbackSection {
      text-align: center;
      grid-area: feedback;
      margin-top: 64px;

      @media (--desktop) {
        margin-top: 0;
      }

      & .Footer__feedbackTitle {
        @mixin h30;
        font-weight: var(--boldFontWeight);
        margin: 0;
      }

      & .Footer__feedbackDescription {
        @mixin p30;
        margin: 0;
      }

      & .Footer__feedbackButton {
        color: var(--grayscaleWhiteBase);
        margin: 24px auto;
      }
    }

    &.Footer__middleSection--withFeedbackButton {
      @media (--desktop) {
        display: grid;
        grid-template-columns: 2fr min-content 1fr;
        grid-template-areas: "links splitLine feedback";
      }
    }
  }

  & .Footer__logo {
    grid-area: logo;
  }

  & .Footer__sectionLinksWrapper {
    margin-bottom: 16px;
  }

  & .Footer__sectionLinksWrapper,
  & .Footer__sectionTitle {
    display: none;

    @media (--desktop) {
      display: block;
    }
  }

  & .Footer__sectionTitle {
    @mixin p20;
    font-weight: var(--boldFontWeight);
  }

  & .Footer__sectionTitleWrapper {
    display: flex;
    align-items: center;
  }

  & .Footer__singleLinksSection {
    @media (--desktop) {
      @mixin horizontalGap 64px;
      max-width: 150px;
      margin-top: 0;
    }

    &:not(:first-of-type) {
      margin-top: 24px;

      @media (--desktop) {
        margin-top: 0;
      }
    }
  }

  & .Footer__sectionCollapseWrapper {
    display: block;

    @media (--desktop) {
      display: none;
    }
  }

  & .Footer__singleSectionLinkWrapper {
    margin-top: 16px;
  }

  & .Footer__singleSectionDesktopLinkWrapper,
  & .Footer__singleSectionLinkWrapper {
    & .Footer__singleSectionLink {
      color: var(--grayscaleWhiteBase);
      transition: color 250ms;
      padding: 8px 24px 8px 0;

      &:hover {
        color: var(--primaryLighten40);
      }
    }
  }

  & .Footer__bottomSection {
    @mixin p30;
    grid-area: copyright;
    margin-top: 80px;

    @media (--desktop) {
      margin-top: 48px;
    }

    & .Footer__gyldendalWrapper {
      display: flex;
      align-items: center;

      & span {
        margin-right: 8px;
      }
    }

    & .Footer__copyrightText,
    & .Footer__privacyPolicyArticle {
      @mixin p40;
      color: var(--grayscaleWhiteBase);
      transition: color 250ms;
    }

    & .Footer__privacyPolicyArticle {
      &:hover {
        color: var(--primaryLighten40);
      }
    }

    & .Footer__bottomSplitLine {
      @mixin splitLine 16px, 8px, var(--grayscaleWhiteBase);
    }
  }

  & .Footer__gyldendalLogo {
    fill: var(--grayscaleWhiteBase);
    width: 104px;

    & div {
      display: flex;
      align-items: center;
    }

    & svg {
      max-height: 24px;
    }
  }

  & .Footer__copyrightSection {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
}
