.Dashboard {
  overflow: hidden;
  padding-top: var(--mobileHeaderHeight);

  @media (--tablet) {
    padding-top: var(--headerHeight);
  }

  & .Dashboard__inner {
    @mixin wrapper;
  }
}
