.ChoiceListTask {
  & .ChoiceListTask__content {
    @mixin p30;
    color: var(--grayscaleBlackLighten10);

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .ChoiceListTask__viewerWrapper {
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  & .ChoiceListTask__list {
    padding: 32px;

    & > li {
      @mixin verticalGap 16px;

      @media (--tablet) {
        @mixin verticalGap 24px;
      }
    }
  }
}
