.LevelSidePanel {
  & .LevelSidePanel__inner {
    display: grid;
    grid-template-columns: 1fr 112px;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
      "text text"
      "image progressRing"
      "button button";
    width: 100%;
    row-gap: 32px;
    column-gap: 8px;
    flex: 1;
    padding: 32px 32px 48px;

    @media (--tablet) {
      grid-template-columns: 277px 1fr;
      grid-template-rows: min-content min-content 1fr;
      grid-template-areas:
        "progressRing image"
        "text image"
        "button image";
      column-gap: 24px;
      row-gap: 40px;
      padding: 128px 32px 16px;
    }

    @media (--desktop) {
      @mixin lost-offset 1/8;
      @mixin lost-column 6/8;
      padding-right: 0;
      padding-left: 0;
    }
  }

  & .LevelSidePanel__text {
    grid-area: text;

    @media (--tablet) {
      text-align: center;
    }
  }

  & .LevelSidePanel__title {
    @mixin h50;
    @mixin lineUnder 8px, currentColor, 96px;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 24px, currentColor, 128px, 2px, calc(50% - 64px);
    }
  }

  & .LevelSidePanel__description {
    @mixin p20;
    margin: 4px 0 0;

    @media (--tablet) {
      margin-top: 24px;
    }
  }

  & .LevelSidePanel__level {
    @mixin aspectRatio 4/3;
    grid-area: image;
    margin-bottom: auto;
  }

  & .LevelSidePanel__progressRingWrapper {
    display: flex;
    justify-content: center;
  }

  & .LevelSidePanel__progressRing {
    grid-area: progressRing;
    width: 100%;

    @media (--tablet) {
      max-width: 180px;
    }
  }

  & .LevelSidePanel__button.LevelSidePanel__button {
    grid-area: button;
    align-self: start;

    @media (--desktop) {
      display: none;
    }
  }
}
