.HintRelatedStep {
  & .HintRelatedStep__linkDescription {
    margin: 0;

    @media (--tablet) {
      display: inline-block;
      margin-right: 4px;
    }
  }

  & .HintRelatedStep__link {
    color: var(--primaryDarken40);
    transition: 0.2s color ease-in;

    &:hover {
      color: var(--primaryLighten20);
    }
  }

  & .HintRelatedStep__buttonWrapper {
    margin-top: 16px;

    @media (--tablet) {
      margin-top: 24px;
    }
  }

  & .HintRelatedStep__icon.HintRelatedStep__icon {
    fill: none;
    stroke: var(--primaryBase);

    &.HintRelatedStep__icon--bookmarked {
      fill: var(--primaryBase);
      stroke: var(--primaryBase);
    }
  }
}
