.AccessOverlay {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.46);
  cursor: pointer;

  @media (--tablet) {
    &:hover {
      & .AccessOverlay__button.AccessOverlay__button {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  & .AccessOverlay__lock {
    &.AccessOverlay__lock--white {
      stroke: var(--grayscaleWhiteBase);
      fill: var(--grayscaleWhiteBase);
    }

    &.AccessOverlay__lock--black {
      stroke: var(--grayscaleBlackLighten10);
      fill: var(--grayscaleBlackLighten10);
    }
  }

  & .AccessOverlay__button.AccessOverlay__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;

    @media (--tablet) {
      visibility: hidden;
      opacity: 0;
    }
  }
}
