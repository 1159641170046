html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  color: var(--grayscaleBlackBase);
  font-family: var(--baseFontFamily);
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headingsFontFamily);
  font-weight: 700;
}

blockquote {
  font-family: var(--headingsFontFamily);
  font-style: italic;
}

textarea {
  font-family: var(--baseFontFamily);
}

input {
  font-family: var(--baseFontFamily);
}

button {
  font-family: var(--baseFontFamily);
}

a {
  color: var(--grayscaleBlackBase);
}
