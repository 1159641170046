.StatusLayer {
  @mixin absoluteParentSize;
  pointer-events: none;
  z-index: 1;

  & .StatusLayer__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 4px 8px;
    border-bottom-right-radius: 8px;

    @media (--tablet) {
      padding: 8px 24px;
    }

    &.StatusLayer__wrapper--warning {
      background-color: var(--actionWarningBase);
    }

    &.StatusLayer__wrapper--success {
      background-color: var(--actionSuccessBase);
    }

    &.StatusLayer__wrapper--error {
      background-color: var(--actionErrorBase);
      color: var(--grayscaleWhiteBase);
    }
  }

  & .StatusLayer__text {
    @mixin p40;
    font-weight: var(--mediumFontWeight);

    @media (--tablet) {
      @mixin p30;
    }
  }

  &.StatusLayer--small {
    & .StatusLayer__wrapper {
      padding: 4px 8px;
    }

    & .StatusLayer__text {
      @mixin p40;
    }
  }
}
