.Article {
  & .Article__loader {
    margin-top: calc(32px + var(--mobileHeaderHeight));

    @media (--tablet) {
      margin-top: calc(64px + var(--headerHeight));
    }
  }

  & .Article__wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--footerMinHeight));
  }

  & .Article__inner {
    flex: 1;
    position: relative;
    overflow: hidden;
  }

  & .Article__content {
    @mixin wrapper;
  }

  & .Article__summaryWrapper {
    padding-top: 48px;
  }

  & .Article__contentInner {
    width: 100%;
    padding: 48px 0 96px;

    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 10/12;
      max-width: 950px;
      padding-bottom: 128px;
    }

    @media (--desktop) {
      @mixin lost-column 9/12;
    }
  }

  & .Article__portableText {
    @mixin p10;
  }
}
