.ProductCard {
  @mixin linkOverlay;
  background-color: var(--grayscaleWhiteBase);
  box-shadow: var(--boxShadow10);
  outline: 1px solid transparent;
  transition: box-shadow 0.2s ease-out, outline-color 0.2s ease-out;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;

  @media (--desktop) {
    display: grid;
    grid-template-columns: 3fr 3fr;
    grid-template-areas: "info image";
    column-gap: 40px;
    box-shadow: var(--boxShadow20);
  }

  & .ProductCard__imageWrapper {
    @mixin aspectRatio 2/3;
    width: 100%;
    background-color: var(--support02Base);
    overflow: hidden;

    @media (--desktop) {
      @mixin aspectRatio 3/4;
      width: auto;
      grid-area: image;
    }
  }

  & .ProductCard__image {
    transition: transform 0.4s ease-out;
    object-fit: contain;
  }

  & .ProductCard__info {
    padding: 24px 24px 32px;

    @media (--desktop) {
      grid-area: info;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 0 24px 40px;
    }
  }

  & .ProductCard__targetGroup {
    @mixin p50;
    @mixin highlightedText;
    margin: 0;
  }

  & .ProductCard__title {
    @mixin h50;
    @mixin breakWithHyphen;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    margin: 4px 0 0;

    @media (--desktop) {
      @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
      margin-bottom: 32px;
    }
  }

  & .ProductCard__link {
    position: relative;
    z-index: 2;
    margin-top: 48px;

    @media (--desktop) {
      display: inline-block;
      margin-top: auto;
    }
  }

  & .ProductCard__linkOverlay {
    z-index: 1;
  }

  &:hover {
    box-shadow: none;
    outline-color: var(--grayscaleBlackLighten30) !important;

    & .ProductCard__image {
      transform: scale(1.11);
    }
  }
}
