/* stylelint-disable property-disallowed-list */

@define-mixin resetButtonStyles {
  @mixin insetFocus;
  font-family: var(--baseFontFamily);
  background: unset;
  font-weight: 400;
  cursor: pointer;
  padding: unset;
  border: unset;
  border-radius: 0;

  &:hover {
    background: unset;
    border: unset;
  }
}

@define-mixin solidColor $backgroundColor, $contentColor, $hoverBackgroundColor {
  background: $backgroundColor;
  color: $contentColor;
  border-color: $backgroundColor;

  &:hover {
    background: $hoverBackgroundColor;
    border-color: $hoverBackgroundColor;
  }

  &:disabled,
  &:disabled:hover {
    background: $backgroundColor;
    border-color: $backgroundColor;
  }
}

@define-mixin outlineColor $contentColor, $hoverBackgroundColor {
  background: transparent;
  color: $contentColor;
  border-color: $contentColor;

  &:hover {
    background: $hoverBackgroundColor;
  }

  &:disabled,
  &:disabled:hover {
    background: transparent;
  }
}

@define-mixin strokeIconColor $color {
  fill: none;
  stroke: $color;
}

@define-mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing, 32px);
  text-align: center;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out, box-shadow 0.3s ease-out;
  font-family: var(--headingsFontFamily);
  font-weight: 700;
  border: 1px solid;
  border-radius: 32px;

  &:disabled,
  &:disabled:hover {
    opacity: 0.4;
    cursor: default;
  }

  & .SvgIcon {
    fill: currentColor;
    flex-shrink: 0;

    &:first-child:not(:only-child) {
      margin-right: auto;
    }

    &:last-child:not(:only-child) {
      margin-left: auto;
    }
  }

  & > * {
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      &:not(.SvgIcon):not(:only-child) {
        margin: 0 var(--spacing, 32px);

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &:first-child:not(:only-child) {
      text-align: left;
    }

    &:last-child:not(:only-child) {
      text-align: right;
    }
  }

  &.focus-visible:focus {
    @mixin insetFocus;
  }
}

@define-mixin outlineButton {
  @mixin button;
}

@define-mixin circleButton {
  @mixin button;
  border-radius: 50%;
}

@define-mixin xSmallButton {
  --spacing: 16px;

  & .SvgIcon {
    width: 16px;
    height: 16px;
  }

  &.Button--solid,
  &.Button--outline {
    @mixin p40;
    padding: 1px 15px;
  }

  &.Button--circle {
    width: 24px;
    height: 24px;
    padding: 4px;
  }
}

@define-mixin smallButton {
  --spacing: 24px;

  & .SvgIcon {
    width: 16px;
    height: 16px;
  }

  &.Button--solid,
  &.Button--outline {
    @mixin p40;
    padding: 5px 23px;
  }

  &.Button--circle {
    width: 32px;
    height: 32px;
    padding: 8px;
  }
}

@define-mixin mediumButton {
  --spacing: 24px;

  & .SvgIcon {
    width: 24px;
    height: 24px;
  }

  &.Button--solid,
  &.Button--outline {
    @mixin p30;
    padding: 7px 23px;
  }

  &.Button--circle {
    width: 40px;
    height: 40px;
    padding: 8px;
  }
}

@define-mixin largeButton {
  --spacing: 32px;

  & .SvgIcon {
    width: 24px;
    height: 24px;
  }

  &.Button--solid,
  &.Button--outline {
    @mixin p30;
    padding: 10px 30px;
    border-width: 2px;
  }

  &.Button--circle {
    width: 48px;
    height: 48px;
    padding: 12px;
  }
}

@define-mixin xLargeButton {
  --spacing: 40px;

  & .SvgIcon {
    width: 24px;
    height: 24px;
  }

  &.Button--solid,
  &.Button--outline {
    @mixin h50;
    padding: 14px 38px;
    border-width: 2px;
  }

  &.Button--circle {
    width: 64px;
    height: 64px;
    padding: 20px;
  }
}
