.ThemesSelector {
  @media (--desktop) {
    height: calc(100vh - var(--footerMinHeight));
    position: relative;
    display: flex;
  }

  & .ThemeSelector__content {
    @mixin scrollbar var(--support01Lighten60), var(--grayscaleBlackLighten10);
    color: var(--grayscaleBlackLighten10);
    padding-right: 8px;
    padding-bottom: 64px;

    @media (--desktop) {
      @mixin lost-column 6/12;
      display: flex;
      flex-direction: column;
      overflow-y: auto; /* IT'S NOT THE BEST IDEA TO HAVE IT HERE, BUT IT's A BACKUP IN CASE OF THE VERY SHORT SCREEN */
      padding-bottom: 80px;
    }
  }

  & .ThemesSelector__title {
    @mixin h30;
    @mixin lineUnder 16px, currentColor, 128px, 2px;
    margin: 32px 0 0;

    @media (--tablet) {
      @mixin h10;
      @mixin lineUnder 24px, currentColor, 240px, 2px;
      margin-top: 64px;
    }
  }

  & .ThemesSelector__descriptionWrapper {
    margin: 16px 0 0;

    @media (--desktop) {
      margin-top: 32px;
    }
  }

  & .ThemesSelector__description {
    @mixin p20;
    white-space: pre-line;

    @media (--desktop) {
      @mixin p10;
    }
  }

  & .ThemesSelector__form {
    margin-top: 32px;
  }

  & .ThemesSelector__list {
    @mixin defaultGutter;
    @mixin clearList;
    @mixin scrollbar var(--support01Lighten60), var(--grayscaleBlackLighten10);
    display: grid;
    row-gap: 16px;

    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }

    @media (--desktop) {
      flex: 1;
      overflow-y: auto;
      min-height: 100px;
      grid-auto-rows: min-content;
    }
  }

  & .ThemesSelector__allCheckboxWrapper + .ThemesSelector__list {
    margin-top: 32px;

    @media (--tablet) {
      margin-top: 16px;
    }
  }

  & .ThemesSelector__listItem {
    overflow: hidden;
  }

  & .ThemesSelector__actions {
    margin-top: 64px;

    @media (--tablet) {
      display: flex;
      justify-content: flex-end;
      margin-top: 48px;
    }

    @media (--desktop) {
      margin: 0;
    }
  }

  & .ThemesSelector__confirmButton {
    width: 100%;

    @media (--tablet) {
      width: auto;
    }

    @media (--desktop) {
      position: absolute;
      right: 0;
      bottom: 80px;
      z-index: 1;
    }
  }

  & .ThemeSelector__sider {
    display: none;

    @media (--desktop) {
      @mixin lost-offset 1/12;
      @mixin lost-column 5/12;
      position: relative;
      display: flex;
      background-color: var(--grayscaleBlackLighten10);
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      width: 50vw;
      background-color: var(--grayscaleBlackLighten10);
    }
  }
}
