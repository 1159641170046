.OrderIndicator {
  @mixin h50;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--support02Base);
  width: 40px;
  height: 40px;
  font-weight: var(--boldFontWeight);
  font-family: var(--headingsFontFamily);
  border-radius: 50%;

  @media (--tablet) {
    @mixin h40;
    width: 48px;
    height: 48px;
  }
}
