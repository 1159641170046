.HamburgerMenu {
  @mixin fixedFullSize;
  z-index: 200;
  overflow: hidden;

  & .HamburgerMenu__closeBackgroundButton {
    @mixin fixedFullSize;
    background-color: var(--backgroundShadow);

    &:hover {
      background-color: var(--backgroundShadow);
    }
  }

  & .HamburgerMenu__inner {
    @mixin fixedFullSize;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;

    @media (--tablet) {
      @mixin wrapper;
    }
  }

  & .HamburgerMenu__panel {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    display: flex;
    flex-direction: column-reverse;
    background-color: var(--primaryBase);
    padding-bottom: 48px;
    border-bottom-left-radius: 64px;

    @media (--tablet) {
      @mixin lost-column 6/12;
    }

    @media (--desktop) {
      @mixin lost-column 5/12;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -50vw;
      width: 50vw;
      height: 100%;
      background-color: inherit;
    }
  }

  & .HamburgerMenu__closeBar {
    padding: 16px var(--mobileWrapperPadding) 24px 0;
    margin-bottom: auto;

    @media (--tablet) {
      padding: 16px 0;
    }
  }

  & .HamburgerMenu__closeButton {
    margin-left: auto;
  }

  & .HamburgerMenu__linksSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow-y: auto;
    padding: 0 var(--mobileWrapperPadding);

    @media (--tablet) {
      padding: 0 48px;
    }
  }

  & .HamburgerMenu__searchLinkWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--grayscaleWhiteBase);
    margin: 0 16px;
  }

  & .HamburgerMenu__searchIcon {
    @mixin strokeIconColor var(--grayscaleWhiteBase);
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  & .HamburgerMenu__searchInput {
    @mixin p10;
    color: var(--grayscaleWhiteBase);
    flex: 1;
  }

  & .HamburgerMenu__gap {
    margin-top: 32px;
  }

  & .HamburgerMenu__links,
  &.HamburgerMenu__secondaryLinks {
    & li {
      margin-top: 24px;
    }
  }

  & .HamburgerMenu__link.HamburgerMenu__link {
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    border-width: 0;
  }

  & .HamburgerMenu__link.HamburgerMenu__link,
  & .HamburgerMenu__secondaryLink.HamburgerMenu__secondaryLink {
    @mixin h50;
    color: var(--grayscaleWhiteBase);
    transition: background-color 0.2s ease-out, color 0.1s linear;
    padding: 8px 16px;
    border-radius: 24px;

    & .HamburgerMenu__linkText {
      display: inline-block;
      transition: transform 0.1s linear;
    }

    &:hover {
      color: var(--primaryLighten60);

      & .HamburgerMenu__linkText {
        transform: translateY(-2px);
      }
    }

    &.HamburgerMenu__link--active {
      @mixin lineUnder 8px, currentColor, 80%, 2px, 10%;
    }
  }

  & .HamburgerMenu__secondaryLinks {
    padding-top: 24px;
    border-top: 1px solid var(--primaryLighten40);
    margin-top: 24px;

    & .HamburgerMenu__secondaryLink.HamburgerMenu__secondaryLink {
      @mixin p10;
    }
  }

  & .HamburgerMenu__studiesType {
    @mixin h40;
    color: var(--primaryLighten40);
    font-family: var(--headingsFontFamily);
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    align-self: flex-end;
  }

  & .HamburgerMenu__buyLink {
    display: block;

    @media (--desktopLarge) {
      display: none;
    }
  }
}
