.TaskActions {
  width: 100%;

  /* TO REMOVE SPACING FROM BUTTONS */
  margin-bottom: -16px;

  & .TaskActions__hint {
    margin-bottom: 32px;

    @media (--tablet) {
      margin-bottom: 48px;
    }
  }

  & .TaskActions__actionsWrapper {
    display: flex;
    flex-direction: column;

    @media (--mobileLarge) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    & > * {
      margin-bottom: 16px;

      @media (--mobileLarge) {
        @mixin horizontalGap 16px;
      }
    }
  }
}
