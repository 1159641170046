.SectionTextBlock {
  & .SectionTextBlock__inner {
    & .SectionTextBlock__innerContainer {
      background-color: var(--support01Base);
      border-bottom-left-radius: 48px;
    }

    & .SectionTextBlock__info {
      @mixin wrapper;
      text-align: center;

      @media (--tablet) {
        border-bottom-left-radius: 96px;
      }

      & .SectionTextBlock__title {
        @mixin h2;
        @mixin lineUnder 16px, var(--grayscaleBlackLighten10), 45%, 1px, 0;
        display: inline-block;
        text-align: left;
        position: relative;
        margin: 32px auto 32px;

        @media (--tablet) {
          @mixin h1;
          @mixin lineUnder 24px, var(--grayscaleBlackLighten10), 45%, 1px, 30%;
          text-align: unset;
          margin: 64px auto 48px;
        }
      }

      & .SectionTextBlock__description {
        @mixin b1;
        white-space: pre-line;
        padding-bottom: 32px;
        margin: 0;

        @media (--tablet) {
          @mixin lost-offset 3/12;
          @mixin lost-column 6/12;
          padding-bottom: 48px;
        }
      }
    }

    & .SectionTextBlock__additionalInfo {
      @mixin wrapper;

      & .SectionTextBlock__videoPlayerWrapper {
        @mixin shiftBy 0, -96px;

        @media (--tablet) {
          @mixin shiftBy 0, -108px;
        }
      }

      & .SectionTextBlock__videoPlayer {
        @mixin aspectRatio 9/16;
        background-color: var(--grayscaleBlackLighten20);
        overflow: hidden;
        box-shadow: var(--boxShadow20);
        border: 8px solid var(--grayscaleWhiteBase);
        border-radius: 24px;

        @media (--mobileOnly) {
          margin-top: 32px;
        }

        @media (--tablet) {
          @mixin lost-offset 3/12;
          @mixin lost-column 6/12;
        }
      }
    }

    &.SectionTextBlock__inner--withAdditionalInfo {
      background-color: var(--support02Base);

      & .SectionTextBlock__info {
        padding-bottom: 64px;

        @media (--tablet) {
          padding-bottom: 96px;
        }
      }
    }
  }
}
