.Checkbox {
  position: relative;

  & .Checkbox__input {
    @mixin choice;
  }

  & .Checkbox__label {
    display: block;
    max-width: 100%;

    & .Checkbox__labelContent {
      display: block;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
