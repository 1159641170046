.ThemeProgress {
  position: relative;
  box-shadow: var(--boxShadow10);
  background-color: var(--support01Lighten20);
  border-bottom-left-radius: 48px;
  border-top-left-radius: 0;

  @media (--tablet) {
    @mixin lost-column 11/12;
    @mixin lost-offset 1/12;
    min-height: 120px;
    border-bottom-left-radius: 64px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -50vw;
    width: 50vw;
    height: 100%;
    background-color: inherit;
  }

  & .ThemeProgress__info {
    flex: 1;
    height: 100%;
    display: block;
    padding: 24px 0 40px 32px;

    @media (--tablet) {
      display: flex;
      align-items: flex-end;
      padding: 24px 0;
    }
  }

  & .ThemeProgress__messages {
    display: block;

    @media (--tablet) {
      @mixin lost-offset 1/11;
      @mixin lost-column 6/11;
    }

    @media (--desktop) {
      @mixin lost-column 5/11;
    }
  }

  & .ThemeProgress__mainMessage {
    @mixin h50;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
    }
  }

  & .ThemeProgress__description {
    @mixin p40;
    color: var(--grayscaleBlackLighten20);
    display: block;
    margin: 8px 0 0;

    @media (--tablet) {
      @mixin p30;
    }
  }

  & .ThemeProgress__detailedProgressBar {
    margin-top: 16px;

    @media (--tablet) {
      @mixin lost-column 4/11;
      margin: 0 0 8px;
    }
  }
}
