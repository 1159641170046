.BookmarkMessageEditor {
  & .BookmarkMessageEditor__textarea {
    width: 100%;
  }

  & .BookmarkMessageEditor_action {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }

  & .BookmarkMessageEditor__saveChanges {
    margin-left: 8px;

    @media (--tablet) {
      margin-left: 16px;
    }
  }

  & .BookmarkMessageEditor__message {
    @mixin p20;
    margin: 0;

    @media (--tablet) {
      @mixin p10;
    }
  }
}
