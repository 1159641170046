.InProgressThemesSection {
  & .InProgressThemesSection__list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }
}
