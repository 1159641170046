.MatchTaskList {
  margin-top: 40px;

  & .MatchTaskList__questionWrapper {
    margin-top: 24px;
  }

  & .MatchTaskList__question {
    @mixin p10;
    font-weight: var(--mediumFontWeight);
    font-family: var(--baseFontFamily);
    margin: 0;
  }

  & .MatchTaskList__questionText {
    @mixin p10;
  }

  & .MatchTaskList__answersWrapper {
    margin-top: 16px;
  }

  & .MatchTaskList__singleOptionItem {
    @mixin verticalGap 8px;
  }
}
