.EmptySearch {
  & .EmptySearch__imageWrapper {
    @mixin aspectRatio 1/1;
    width: 200px;
    max-width: 100%;
    margin: 0 auto;

    @media (--tablet) {
      width: 290px;
    }
  }

  & .EmptySearch__image {
    object-fit: contain;
  }

  & .EmptySearch__text {
    @mixin h50;
    text-align: center;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    margin: 24px auto 0;

    @media (--tablet) {
      max-width: 500px;
    }
  }

  & .EmptySearch__linkWrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}
