.CompleteSwitch {
  margin-top: 32px;

  & .CompleteSwitch__markAction {
    display: flex;
    align-items: center;

    & .CompleteSwitch__markDescription {
      @mixin p10;
      font-weight: var(--mediumFontWeight);
      margin-left: 16px;
    }
  }
}
