.ThemeTrainingSection {
  & .ThemeTrainingSection__items {
    @mixin clearList;
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }

  & .ThemeTrainingSection__item {
    @mixin verticalGap 24px;

    @media (--desktop) {
      @mixin verticalGap 32px;
    }
  }
}
