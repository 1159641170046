.Tooltip__container.Tooltip__container {
  --tooltipBackground: var(--grayscaleBlackLighten10);
  --tooltipBorder: var(--grayscaleBlackLighten10);

  @mixin p50;
  color: var(--grayscaleWhiteBase);
  opacity: 0.8;
  text-align: center;
  z-index: 100;
  padding: 8px;
  border-radius: 8px;
}
