.Table {
  overflow-x: auto;
  margin-bottom: 1em;

  & .Table__table {
    width: 100%;
    background-color: var(--grayscaleWhiteBase);
    border-spacing: 0;
    border: 1px solid var(--grayscaleBlackLighten50);
    border-radius: 8px;
  }

  & .Table__row {
    &:first-of-type {
      background-color: var(--support01Lighten60);
    }

    &:last-of-type td {
      border-bottom: none;
    }
  }

  & .Table__cell {
    padding: 8px 16px;
    border-right: 1px solid var(--grayscaleBlackLighten20);
    border-bottom: 1px solid var(--grayscaleBlackLighten50);

    @media (--tablet) {
      padding: 16px 24px;
    }

    &:last-of-type {
      border-right: none;
    }
  }
}
