.Select {
  width: 100%;

  & .Select__control {
    height: 48px;
    cursor: pointer;
    background-color: var(--grayscaleWhiteBase);
    transition: border-color 0.2s;
    outline: none;
    box-shadow: none;
    border: 2px solid var(--grayscaleBlackLighten30);

    &:hover {
      border-color: var(--grayscaleBlackLighten40);
    }

    &.Select__control--is-focused {
      box-shadow: 0 0 0 4px var(--notificationFocusBase);
    }

    &.Select__control--menu-is-open {
      & .Select__indicatorIcon {
        transform: rotateZ(180deg);
        fill: var(--primaryDarken20);
      }
    }
  }

  & .Select__value-container {
    @mixin p20;
    color: var(--grayscaleBlackLighten10);
    flex: 1;
    height: 100%;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 24px;
    margin: 0;
  }

  & .Select__multiValue {
    @mixin p20;
    color: var(--grayscaleBlackLighten10);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .Select__placeholder {
    @mixin p20;
    color: var(--grayscaleBlackLighten40);
  }

  & .Select__indicator-separator {
    display: none;
  }

  & .Select__indicator {
    padding: 0;
    margin: 0 24px 0 0;
  }

  & .Select__indicatorIcon {
    height: 16px;
    width: 16px;
    transform: rotateZ(0deg);
    transform-origin: center;
    transition-duration: 0.2s;
    fill: var(--primaryBase);
  }

  &.Select--disabled {
    & .Select__control {
      background-color: var(--grayscaleBlackLighten50);
      color: var(--grayscaleBlackLighten20);
      border-color: var(--grayscaleBlackLighten30);
    }

    & .Select__placeholder {
      color: var(--grayscaleBlackLighten20);
    }

    & .Select__indicatorIcon {
      fill: var(--grayscaleBlackLighten20);
    }
  }

  &.Select--rounded {
    & .Select__control {
      border-radius: 24px;
    }

    & .Select__menu.Select__menu {
      border-radius: 24px;
    }
  }

  &.Select--square {
    & .Select__control {
      border-radius: 8px;
    }

    & .Select__menu.Select__menu {
      border-radius: 8px;
    }
  }
}

.Select__menu-portal.Select__menu-portal {
  z-index: 9999;

  & .Select__menu {
    background: var(--grayscaleWhiteBase);
    overflow: hidden;
    box-shadow: var(--boxShadow20);
    border: 1px solid var(--support01Lighten20);
    margin: 16px 0 0;

    & .Select__menu-list {
      @mixin scrollbar var(--grayscaleWhiteBase), var(--grayscaleBlackLighten10);
      padding: 8px 24px 16px;
    }

    & .Select__option {
      @mixin p10;
      color: var(--grayscaleBlackLighten10);
      background-color: var(--grayscaleWhiteBase);
      transition: color 0.2s;
      cursor: pointer;
      padding: 8px 0 12px;
      border-bottom: 1px solid var(--grayscaleBlackLighten50);

      &:hover {
        color: var(--grayscaleBlackLighten30);
      }

      &:last-child {
        border-bottom: none;
      }

      &.Select__option--is-selected {
        color: var(--primaryDarken20);
      }
    }
  }
}
