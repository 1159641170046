@define-mixin label {
  @mixin p10;
  font-weight: var(--mediumFontWeight);
  display: block;
  margin: 0 0 8px;
}

@define-mixin fieldBase $backgroundColor: var(--grayscaleWhiteBase), $borderColor: var(--grayscaleBlackLighten30),
  $textColor: var(--grayscaleBlackLighten10) {
  height: 48px;
  background-color: $backgroundColor;
  color: $textColor;
  padding: 8px 24px;
  border: 2px solid $borderColor;
  border-radius: 24px;
}

@define-mixin resetInputStyles {
  /* stylelint-disable property-disallowed-list */
  font-size: unset;
  color: inherit;
  background: none;
  outline: none;
  padding: 0;
  border: none;

  &::placeholder {
    color: var(--grayscaleBlackLighten40);
  }
}
