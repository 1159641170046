.CheckboxButton {
  position: relative;

  & .CheckboxButton__label {
    cursor: pointer;
  }

  & input[type="checkbox"] {
    position: absolute;
    left: -9999px;

    &.focus-visible:focus + .CheckboxButton__label {
      box-shadow: inset 0 0 0 4px var(--notificationFocusBase);
    }
  }
}
