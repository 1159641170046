.InputTask {
  width: 40rem;

  @media (--mobileOnly) {
    width: 100%;
  }

  & .InputTask__content {
    @mixin p30;
    color: var(--grayscaleBlackLighten10);

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .InputTask__text {
    @mixin p30;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    position: relative;
    margin: 24px 0 0;

    @media (--tablet) {
      margin: 40px 0 0;
    }
  }

  & .InputTask__input {
    transition: border-color 0.2s ease-out;
    margin-top: 16px;

    &.InputTask__input--correct {
      border-color: var(--actionSuccessBase);
    }

    &.InputTask__input--wrong {
      border-color: var(--actionErrorBase);
    }
  }

  & .InputTask__info {
    font-weight: var(--mediumFontWeight);
    font-size: 1.3em;

    @media (--mobileOnly) {
      font-size: 1em;
      padding: 48px;
      margin: -20px 0 0;
    }
  }
}
