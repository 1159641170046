@define-mixin clearList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@define-mixin listTitle {
  @mixin listTitleFontSize;
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
  margin: 0 0 25px;

  @media (--tablet) {
    margin-bottom: 45px;
  }

  &::after {
    @mixin lost-column 4/7;
    content: "";
    position: absolute;
    min-width: 40%;
    left: 0;
    bottom: 0;
    height: 1px;
    background-color: var(--grayscaleBlackLighten20);

    @media (--tablet) {
      @mixin lost-column 2/4;
    }
  }
}

@define-mixin listTitleFontSize {
  @mixin h2;

  @media (--tablet) {
    @mixin h1;
  }
}

@define-mixin smallListTitle {
  @mixin h3;

  @media (--tablet) {
    @mixin h2;
  }
}

@define-mixin listHeading {
  @mixin b3;
  border-bottom: 1px solid var(--grayscaleBlackLighten30);

  @media (--tablet) {
    @mixin b2;
  }

  & :first-child {
    margin-left: 0;
  }

  & strong {
    @mixin h3;
    font-family: var(--headingsFontFamily);
    color: var(--grayscaleBlackLighten10);
    margin: 0 12px;

    @media (--tablet) {
      @mixin h2;
    }
  }
}
