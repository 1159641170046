.AuthorsSlider.AuthorsSlider {
  position: relative;

  & .AuthorsSlider__arrow--previous {
    position: absolute;
    right: 64px;
    top: -64px;
  }

  & .AuthorsSlider__arrow--next {
    position: absolute;
    right: 0;
    top: -64px;
  }

  & .AuthorsSlider__arrow {
    display: none;

    @media (--tablet) {
      @mixin outlineColor var(--grayscaleBlackLighten10), var(--grayscaleBlackLighten50);
      display: block;
      max-width: 48px;
    }
  }

  & .slick-list {
    & .slick-track {
      margin-top: 32px;
    }
  }

  & .slick-dots {
    bottom: -100px;
    padding-top: 32px;

    & li {
      & button {
        background-color: var(--grayscaleBlackBase);
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }

    & .slick-active {
      & button {
        position: relative;
        background-color: var(--primaryBase);

        &::before {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid var(--primaryBase);
          border-radius: 50%;
        }
      }
    }
  }
}
