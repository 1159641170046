.Test {
  padding-top: 32px;

  @media (--tablet) {
    @mixin lost-offset 1/12;
    @mixin lost-column 8/12;
    padding-top: 64px;
  }

  & .Test__subtitle {
    @mixin p40;
    @mixin highlightedText;
    display: block;
  }

  & .Test__title {
    @mixin h40;
    @mixin lineUnder 16px, var(--grayscaleBlackLighten10), 128px, 2px;
    @mixin breakWithHyphen;
    margin: 0;

    @media (--tablet) {
      @mixin h10;
      @mixin lineUnder 24px, var(--grayscaleBlackLighten10), 240px, 2px;
      margin-top: 8px;
    }
  }

  & .Test__content {
    @mixin p20;
    margin-top: 24px;

    @media (--tablet) {
      @mixin p10;
      margin-top: 40px;
    }
  }

  & .Test__list {
    margin-top: 40px;

    @media (--tablet) {
      margin-top: 64px;
    }
  }

  & .Test__item {
    position: relative;
  }

  & .Test__itemAnchor {
    position: absolute;
    top: calc(-1 * var(--mobileHeaderHeight));
    margin-top: -16px;

    @media (--tablet) {
      top: calc(-1 * var(--headerHeight));
    }
  }

  & .Test__actionButtons {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 48px;
    border-top: 1px solid var(--grayscaleBlackLighten30);
    margin-top: 16px;

    @media (--tablet) {
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-top: 16px;
      padding-bottom: 64px;
      margin-top: 64px;

      & .Button.Button:first-child {
        margin-bottom: 0;
      }
    }

    & .Button:first-child {
      margin-bottom: 16px;
    }
  }
}
