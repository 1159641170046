.BuyRedirect {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  margin-top: 80px;

  & p {
    font-size: 1.2em;
  }
}
