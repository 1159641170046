.ExamThemeCard {
  @mixin linkOverlay;
  color: var(--grayscaleBlackLighten10);
  background-color: var(--grayscaleWhiteBase);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-shadow: var(--boxShadow20);
  border-radius: 8px;

  & .ExamThemeCard__linkOverlay {
    z-index: 2;
  }

  & .ExamThemeCard__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 32px;
  }

  & .ExamThemeCard__title {
    @mixin h50;
    @mixin lineUnder 8px, currentColor, 48px;
    margin: 0;
  }

  & .ExamThemeCard__bottomContentWrapper {
    margin-top: auto;
  }

  & .ExamThemeCard__info {
    @mixin p40;
    margin-top: 16px;
  }

  & .ExamThemeCard__status {
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
    align-items: end;
    margin-top: 24px;

    @media (--tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (--desktopHD) {
      column-gap: 16px;
      row-gap: 16px;
    }

    &.ExamThemeCard__status--underDevelopment {
      display: inline-block;
    }
  }

  & .ExamThemeCard__actionItem {
    z-index: 3;
    white-space: nowrap;

    &:only-child {
      grid-column: 1 / span 2;
    }
  }

  & .ExamThemeCard__progressInfoSteps {
    @mixin p40;
    font-weight: var(--boldFontWeight);
    display: block;
  }

  & .ExamThemeCard__progressInfoStepsInfo {
    @mixin p50;
    color: var(--grayscaleBlackLighten20);
    display: block;
  }

  & .ExamThemeCard__progressBarWrapper {
    margin-top: 8px;
  }

  &.ExamThemeCard--underDevelopment {
    & .ExamThemeCard__title {
      opacity: 0.5;
    }
  }
}
