.ProgressBar {
  width: 100%;
  overflow: hidden;

  /* TYPE */

  & .ProgressBar__filler--error {
    &.ProgressBar__filler {
      background: var(--actionErrorBase);
    }
  }

  & .ProgressBar__filler--warning {
    &.ProgressBar__filler {
      background: var(--actionWarningBase);

      &.ProgressBar__filler--completed {
        background: var(--actionSuccessBase);
      }
    }
  }

  & .ProgressBar__filler--success {
    &.ProgressBar__filler {
      background: var(--actionSuccessBase);
    }
  }

  & .ProgressBar__filler--info {
    &.ProgressBar__filler {
      background: var(--notificationFocusBase);
    }
  }

  /* FILLER */

  & .ProgressBar__filler {
    height: 100%;
    border-radius: inherit;
  }

  /* ROUND */

  &:not(.ProgressBar--rounded) {
    border-radius: 0;
  }

  /* SIZE */

  &.ProgressBar--small {
    @mixin smallProgressBar;
  }

  &.ProgressBar--medium {
    @mixin mediumProgressBar;
  }

  &.ProgressBar--large {
    @mixin largeProgressBar;
  }

  /* VARIANT */

  &.ProgressBar--light {
    background-color: var(--grayscaleBlackLighten50);
  }

  &.ProgressBar--dark {
    background-color: var(--grayscaleBlackLighten10);
  }
}
