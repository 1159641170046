.TestPageView {
  background-color: var(--testPageBackgroundColor, var(--support01Lighten60));
  min-height: calc(100vh - var(--mobileFooterMinHeight));
  padding: calc(var(--mobileHeaderHeight) + 40px) 0 64px;

  @media (--tablet) {
    min-height: calc(100vh - var(--footerMinHeight));
    padding: calc(var(--headerHeight) + 72px) 0 128px;
  }

  & .TestPageView__inner {
    @media (--tablet) {
      @mixin wrapper;
    }
  }

  & .TestPageView__content {
    background-color: var(--grayscaleWhiteBase);
    overflow: hidden;
    border-radius: 0 32px 0 32px;

    @media (--mobileOnly) {
      @mixin wrapper;
    }

    @media (--tablet) {
      border-radius: 16px;
    }
  }

  & .TestPageView__loader {
    padding: 64px 0;
  }
}
