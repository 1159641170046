.ChoiceListOption {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-areas:
    "number select"
    "_ label";

  & .ChoiceListOption__answerStatus {
    grid-area: select;
    width: 100%;
  }

  & .ChoiceListOption__select {
    width: 30rem;

    @media (--mobileOnly) {
      width: auto;
    }
  }

  & .ChoiceListOption__choiceNumberWrapper {
    grid-area: number;
    display: flex;
    align-items: center;
    min-width: 24px;

    @media (--tablet) {
      min-width: 32px;
    }
  }

  & .ChoiceListOption__choiceNumber {
    @mixin p10;
    font-weight: var(--mediumFontWeight);
    margin: 0;
  }

  & .ChoiceListOption__label {
    grid-area: label;
    display: none;

    &.ChoiceListOption__label--visible {
      @mixin p40;
      display: block;
      margin: 4px 0 0;

      @media (--tablet) {
        @mixin p30;
        margin: 8px 0 0 24px;
      }
    }
  }
}
