.StepsList {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--grayscaleWhiteBase);
  box-shadow: 0 0 40px var(--support01Lighten20);
  border: 1px solid var(--grayscaleBlackLighten50);
  border-radius: 16px;

  & .StepsList__content {
    display: grid;
    grid-template-areas:
      "progress"
      "items"
      "navigation";
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;
    width: 100%;
    height: 100%;
  }

  & .StepsList__progress {
    grid-area: progress;
    padding: 32px 16px 24px;

    @media (--desktopLarge) {
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  & .StepsList__itemsWrapper {
    grid-area: items;
    overflow: hidden;
    border-top: 1px solid var(--grayscaleBlackLighten50);
  }

  & .StepsList__items {
    @mixin scrollbar var(--grayscaleWhiteBase), var(--grayscaleBlackLighten10);
    height: 100%;
    padding: 24px 16px;

    @media (--desktopLarge) {
      padding: 24px;
    }
  }

  & .StepsList__navigation {
    grid-area: navigation;
    padding: 24px 8px 32px;
    border-top: 1px solid var(--grayscaleBlackLighten50);

    @media (--desktopLarge) {
      padding-right: 24px;
      padding-left: 24px;
    }
  }
}
