.Radio {
  position: relative;

  & .Radio__input {
    @mixin choice;
  }

  & .Radio__label.Radio__label.Radio__label {
    display: block;
    max-width: 100%;

    &::before,
    &::after {
      border-radius: 50%;
    }

    & .Radio__labelContent {
      display: block;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
