.RettsdataReference__modal {
  --bottomPadding: 48px;
  padding: 24px;

  & .RettsdataReference__content {
    padding: 32px 32px calc(var(--bottomPadding) + 80px);

    @media (--tablet) {
      padding: 64px 64px 80px;
    }
  }

  & .RettsdataReference__modalCloseContainer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    background-color: var(--grayscaleWhiteBase);
    padding: 24px 32px var(--bottomPadding);

    @media (--tablet) {
      display: none;
    }

    & .RettsdataReference__modalCloseButton {
      flex: 1;
    }
  }

  & .RettsdataReference__link {
    padding-top: 32px;

    @media (--tablet) {
      padding-top: 48px;
    }
  }
}
