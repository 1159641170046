.CookiesConsent {
  & .CookiesConsent__container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    background-color: var(--grayscaleWhiteBase);
    box-shadow: var(--boxShadow30);
    border-top-right-radius: 64px;
  }

  & .CookiesConsent__inner {
    @mixin wrapper;
    position: relative;
    padding-top: 24px;
    padding-bottom: 40px;

    @media (--tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  & .CookiesConsent__message {
    @mixin p20;
    color: var(--grayscaleBlackLighten10);
    white-space: pre-line;
    margin: 0;
  }

  & .CookiesConsent__buttons {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 48px;

    @media (--tablet) {
      flex-direction: row;
      align-items: center;
      margin: 0 0 0 24px;
    }
  }

  & .CookiesConsent__button {
    white-space: nowrap;

    @media (--mobileOnly) {
      width: 100%;
    }

    &:first-of-type {
      margin-top: 24px;

      @media (--tablet) {
        margin: 0 32px 0 0;
      }
    }
  }

  & .CookiesConsent__modal {
    color: var(--grayscaleBlackLighten10);
    margin: 24px 32px 32px;

    @media (--tablet) {
      margin: 64px;
    }
  }

  & .CookiesConsent__title {
    @mixin h50;
    @mixin lineUnder 8px, currentColor, 128px, 2px;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, currentColor, 128px, 2px;
    }
  }

  & .CookiesConsent__description {
    @mixin p20;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .CookiesConsent__policyLink {
    @mixin p20;
    font-weight: var(--mediumFontWeight);
    transition: color 0.1s linear;
    margin-top: 24px;

    @media (--tablet) {
      @mixin p10;
    }

    &:hover {
      color: var(--grayscaleBlackLighten20);
    }
  }

  & .CookiesConsent__typesList {
    margin-top: 48px;

    @media (--tablet) {
      margin-top: 64px;
    }

    & > li {
      padding: 40px 0;
      border-bottom: 1px solid var(--grayscaleBlackLighten40);

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  & .CookiesConsent__cookiesTypeHeading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  & .CookiesConsent__toggleWrapper {
    flex-shrink: 0;
  }

  & .CookiesConsent__cookiesTypeTitle {
    @mixin p10;
    color: var(--primaryBase);
    font-weight: var(--mediumFontWeight);
    margin: 0 8px 0 0;
  }

  & .CookiesConsent__cookiesTypeDescription {
    @mixin p20;
    color: var(--grayscaleBlackLighten10);
    margin: 16px 0 0;

    @media (--tablet) {
      @mixin p10;
      max-width: 350px;
    }
  }

  & .CookiesConsent__submitButton {
    width: 100%;
    margin-top: 48px;

    @media (--tablet) {
      width: auto;
      margin-top: 64px;
      margin-left: auto;
    }
  }
}
