.ChoiceTestOptionStatus {
  --backgroundColor: var(--grayscaleWhiteBase);
  position: relative;
  display: inline-block;
  max-width: 100%;
  padding: 16px 16px 24px 0;

  &::before {
    @mixin absoluteParentSize;
    content: "";
    transform: translateX(calc(var(--choiceSize) / 2));
    transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out;
    border-radius: 8px;
  }

  & .ChoiceTestOptionStatus__choice {
    &:disabled:not(:checked),
    &:disabled:checked {
      @mixin choiceDefaultColors;
    }
  }

  & .ChoiceTestOptionStatus__infoContainer {
    padding-left: var(--choicePaddingLeft);
  }

  & .ChoiceTestOptionStatus__children,
  & .ChoiceTestOptionStatus__infoContainer {
    position: relative;
    z-index: 1;
  }

  & .ChoiceTestOptionStatus__info {
    @mixin p30;
    color: var(--grayscaleBlackLighten10);
    margin: 0;
  }

  & .ChoiceTestOptionStatus__explanation {
    color: var(--grayscaleBlackLighten20);
    margin-top: 8px;

    & span {
      @mixin p40;
    }
  }

  &.ChoiceTestOptionStatus--shouldBeChecked {
    &::before {
      background-color: var(--notificationSuccessLighten60);
    }

    & .ChoiceTestOptionStatus__choice.ChoiceTestOptionStatus__choice {
      @mixin choiceStatusColors var(--backgroundColor), transparent;
    }
  }

  &.ChoiceTestOptionStatus--correct {
    &::before {
      box-shadow: inset 0 0 0 2px var(--actionSuccessBase);
    }

    & .ChoiceTestOptionStatus__choice.ChoiceTestOptionStatus__choice {
      @mixin choiceStatusColors var(--backgroundColor), var(--actionSuccessBase);

      &.ChoiceTestOptionStatus__choice--checkbox {
        @mixin choiceStatusColors var(--backgroundColor), svg-load("icons/check.svg", stroke=#19d5a0); /* --actionSuccessBase */
        @mixin checkboxStatusCheckmark;
        @mixin correctCheckboxStatusCheckmark;
      }
    }
  }

  &.ChoiceTestOptionStatus--incorrect {
    &::before {
      box-shadow: inset 0 0 0 2px var(--actionErrorBase);
    }

    & .ChoiceTestOptionStatus__choice.ChoiceTestOptionStatus__choice {
      @mixin choiceStatusColors var(--backgroundColor), var(--actionErrorBase);

      &.ChoiceTestOptionStatus__choice--checkbox {
        @mixin choiceStatusColors var(--backgroundColor), svg-load("icons/close.svg", fill=#ff2323); /* --actionErrorBase */
        @mixin checkboxStatusCheckmark;
      }
    }
  }
}
