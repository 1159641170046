.ConfirmExitModal {
  & .ConfirmExitModal__container {
    padding: 32px 32px 48px;

    @media (--tablet) {
      padding: 64px 64px 80px;
    }
  }

  & .ConfirmExitModal__title {
    @mixin h50;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
    color: var(--grayscaleBlackLighten10);
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, currentColor, 128px, 2px;
    }
  }

  & .ConfirmExitModal__content {
    color: var(--grayscaleBlackLighten10);
    margin-top: 24px;
  }

  & .ConfirmExitModal__description {
    @mixin p20;
    margin: 0;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .ConfirmExitModal__action {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
  }

  & .ConfirmExitModal__closeModalButton,
  & .ConfirmExitModal__exitLink {
    width: 100%;

    @media (--tablet) {
      width: auto;
    }
  }

  & .ConfirmExitModal__closeModalButton {
    margin-right: 0;
    margin-bottom: 16px;

    @media (--tablet) {
      margin-right: 24px;
      margin-bottom: 0;
    }
  }
}
