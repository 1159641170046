.SectionHeading {
  display: flex;
  align-items: center;

  & .SectionHeading__title {
    @mixin h50;
    @mixin lineAfter 16px, var(--grayscaleBlackLighten30);
    color: var(--grayscaleBlackLighten10);
    flex: 1;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineAfter 24px, var(--grayscaleBlackLighten30);
    }
  }

  & .SectionHeading__extra {
    margin-left: 16px;

    @media (--tablet) {
      margin-left: 24px;
    }
  }

  &.SectionHeading--medium {
    & .SectionHeading__title {
      @media (--tablet) {
        @mixin h50;
        @mixin lineAfter 16px, var(--grayscaleBlackLighten30);
      }
    }

    & .SectionHeading__extra {
      @media (--tablet) {
        margin-left: 16px;
      }
    }
  }
}
