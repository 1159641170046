.MaskTaskStage {
  & .MaskTaskStage__wrapper {
    display: block;
    width: 100%;
  }

  & .MaskTaskStage__inner {
    display: flex;
    justify-content: center;
    transition: background-color 1s;
    background-color: transparent;
    border-radius: 16px;

    @media (--tablet) {
      border-radius: 32px;
    }

    &.MaskTaskStage__inner--correct {
      background-color: var(--notificationSuccessLighten60);
    }

    &.MaskTaskStage__inner--incorrect {
      background-color: var(--notificationErrorLighten60);
    }

    &.MaskTaskStage__inner--partiallyCorrect {
      background-color: var(--notificationFocusLighten60);
    }
  }
}
