.LogoSection {
  display: flex;
  align-items: center;
  padding-right: 16px;
  margin-right: auto;

  @media (--tablet) {
    padding-right: 32px;
  }

  & .LogoSection__logoLink {
    display: flex;
    border-radius: 8px;
  }

  & .LogoSection__logo {
    width: 64px;

    @media (--tablet) {
      width: 80px;
    }
  }

  & .LogoSection__splitLine {
    @mixin splitLine 16px, 8px, var(--grayscaleBlackLighten50);

    @media (--tablet) {
      @mixin splitLine 24px, 16px, var(--grayscaleBlackLighten50);
    }
  }

  & .LogoSection__gyldendalLogoWrapper--white {
    & .LogoSection__gyldendalLogoDesktop {
      fill: var(--grayscaleWhiteBase);

      & .LogoSvg__tree {
        fill: var(--grayscaleWhiteBase);
      }
    }
  }

  & .LogoSection__gyldendalLogoMobile {
    @mixin aspectRatio 1/1;
    fill: var(--primaryBase);
    width: 16px;

    @media (--tablet) {
      width: 24px;
    }

    @media (--desktop) {
      display: none;
    }
  }

  & .LogoSection__gyldendalLogoDesktop {
    display: none;

    @media (--desktop) {
      display: block;
      width: 96px;
    }

    & div {
      display: flex;
      align-items: center;
    }

    & .LogoSvg__tree {
      fill: var(--primaryBase);
    }
  }

  & .LogoSection__studiesType {
    display: none;

    @media (--tablet) {
      @mixin p40;
      display: block;
      font-family: var(--headingsFontFamily);
      padding: 8px 8px 8px 0;

      &.LogoSection__studiesType--white {
        color: var(--grayscaleWhiteBase);
      }
    }
  }
}
