.ProductNest {
  & .ProductNest__inner {
    @mixin wrapper;
    padding-top: calc(var(--mobileHeaderHeight) + 32px);
    padding-bottom: 80px;

    @media (--tablet) {
      padding-top: calc(var(--headerHeight) + 40px);
      padding-bottom: 64px;
    }

    @media (--desktop) {
      padding-top: calc(var(--headerHeight) + 80px);
      padding-bottom: 128px;
    }
  }

  & .ProductNest__heading {
    @media (--desktop) {
      display: flex;
      align-items: flex-start;
    }
  }

  & .ProductNest__info {
    color: var(--grayscaleBlackLighten10);

    @media (--tablet) {
      @mixin lost-column 6/8;
    }

    @media (--desktop) {
      @mixin lost-column 6/12;
    }
  }

  & .ProductNest__title {
    @mixin h30;
    @mixin lineUnder 16px, currentColor, 128px, 2px;
    margin: 0;

    @media (--desktop) {
      @mixin h10;
      @mixin lineUnder 24px, currentColor, 240px, 2px;
    }
  }

  & .ProductNest__description {
    @mixin p20;
    font-weight: var(--mediumFontWeight);
    white-space: pre-line;
    margin: 32px 0 0;
  }

  & .ProductNest__infoList {
    margin-top: 32px;

    @media (--tablet) {
      @mixin lost-column 4/8;
    }

    @media (--desktop) {
      @mixin lost-offset 1/12;
      @mixin lost-column 5/12;
      align-self: flex-end;
      margin-top: 0;
    }
  }

  & .ProductNest__infoListElement {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  & .ProductNest__productList {
    margin-top: 48px;

    @media (--tablet) {
      @mixin defaultGutter;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 6fr));
      row-gap: var(--tabletGutter);
    }

    @media (--desktop) {
      margin-top: 80px;
    }

    @media (--desktopHD) {
      row-gap: var(--desktopLargeGutter);
    }
  }

  & .ProductNest__productListElement {
    @media (--mobileOnly) {
      &:not(:first-child) {
        margin-top: 32px;
      }
    }
  }
}
