.ThemeHero {
  padding: calc(8px + var(--mobileHeaderHeight)) 0 64px;

  @media (--tablet) {
    padding: calc(8px + var(--headerHeight)) 0 80px;
  }

  & .ThemeHero__inner {
    @mixin wrapper;
  }

  & .ThemeHero__content {
    margin-top: 32px;

    @media (--tablet) {
      display: flex;
      margin-top: 48px;
    }

    @media (--desktop) {
      margin-top: 64px;
    }
  }

  & .ThemeHero__buyAccessButton {
    margin-top: 40px;
  }

  & .ThemeHero__info {
    color: var(--grayscaleBlackLighten10);

    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 6/12;
      align-self: flex-start;
      margin-bottom: 64px;
    }
  }

  & .ThemeHero__subtitle {
    @mixin p40;
    @mixin highlightedText;
    display: block;
  }

  & .ThemeHero__title {
    @mixin h40;
    @mixin lineUnder 16px, currentColor, 60%, 2px;
    margin: 4px 0 0;

    @media (--desktop) {
      @mixin h10;
      @mixin lineUnder 16px, currentColor, 60%, 2px;
      margin: 8px 0 0;
    }
  }

  & .ThemeHero__description {
    @mixin p20;

    --lineHeight: 26px;
    min-height: calc(5 * var(--lineHeight));
    font-weight: var(--mediumFontWeight);
    white-space: pre-line;
    margin: 24px 0 0;

    @media (--desktop) {
      @mixin p10;
      margin-top: 40px;

      --lineHeight: 28px;
    }
  }

  & .ThemeHero__imageWrapper {
    margin-top: 16px;

    @media (--mobileOnly) {
      width: 100%;
      max-width: 300px;
      margin-left: auto;
    }

    @media (--tablet) {
      @mixin lost-column 5/12;
      align-self: flex-end;
      margin-top: 0;
    }
  }

  & .ThemeHero__image {
    object-fit: contain;
    object-position: right bottom;
  }
}
