.UserLinks {
  & .UserLinks__listItem {
    transition: border-bottom-color 0.2s linear;
    border-bottom: 1px solid var(--grayscaleBlackLighten40);

    &:last-child {
      border-bottom: none;
    }
  }

  & .UserLinks__link.UserLinks__link {
    display: flex;
    align-items: center;
    padding: 16px 0;

    &:hover {
      & .UserLinks__icon {
        @mixin strokeIconColor var(--primaryDarken20);
      }

      & .UserLinks__linkText {
        color: var(--primaryDarken20);
      }
    }

    &.UserLinks__link--active {
      & .UserLinks__icon {
        @mixin strokeIconColor var(--primaryDarken40);
      }

      & .UserLinks__linkText {
        color: var(--primaryDarken40);
      }
    }
  }

  & .UserLinks__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    transition: stroke 0.2s linear;
    margin-right: 16px;

    &.UserLinks__icon--primary {
      @mixin strokeIconColor var(--primaryBase);
    }

    &.UserLinks__icon--secondary {
      @mixin strokeIconColor var(--grayscaleBlackLighten10);
    }
  }

  & .UserLinks__linkText {
    @mixin p30;
    font-weight: var(--mediumFontWeight);
    color: var(--grayscaleBlackLighten10);
    transition: color 0.2s linear;
    margin-right: 16px;
  }

  & .UserLinks__externalIcon {
    @mixin strokeIconColor var(--grayscaleBlackLighten40);
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: auto;
  }
}
