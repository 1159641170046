.ProductCoursesSection {
  background-color: var(--grayscaleWhiteBase);
  padding: 48px 0 64px;

  @media (--tablet) {
    padding: 64px 0 96px;
  }

  @media (--desktop) {
    padding: 96px 0 128px;
  }

  & .ProductCoursesSection__inner {
    @mixin wrapper;

    @media (--tablet) {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
    }
  }

  & .ProductCoursesSection__imageContainer {
    @media (--mobileOnly) {
      width: 80%;
      max-width: 400px;
      margin: 64px auto 0;
    }

    @media (--tablet) {
      @mixin lost-column 6/12;
    }

    @media (--desktop) {
      @mixin lost-offset 1/12;
      @mixin lost-column 5/12;
    }
  }

  & .ProductCoursesSection__image {
    object-position: top;
  }

  & .ProductCoursesSection__content {
    @media (--tablet) {
      @mixin lost-column 6/12, 1;
    }

    @media (--desktop) {
      @mixin lost-offset 1/12;
      @mixin lost-column 5/12, 1;
    }
  }

  & .ProductCoursesSection__title {
    @mixin h40;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px, 1px;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, currentColor, 128px, 2px;
    }
  }

  & .ProductCoursesSection__description {
    @mixin p20;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin p10;
      margin-top: 32px;
    }
  }

  & .ProductCoursesSection__coursesWrapper {
    margin-top: 32px;

    @media (--tablet) {
      margin-top: 40px;
    }
  }
}
