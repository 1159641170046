.CoursesPicker {
  @mixin wrapper;
  margin: 40px 0;

  @media (--tablet) {
    margin: 80px 0;
  }

  & .CoursesPicker__title {
    @mixin h50;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten10), 128px;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
    }
  }

  & .CoursesPicker__description {
    @mixin p20;
    margin: 16px 0 0;

    @media (--tablet) {
      @mixin p10;
      margin: 24px 0 0;
    }
  }

  & .CoursesPicker__list {
    @mixin defaultGutter;
    @mixin gridList 16px, 32px, 1, 2, 1, 2;
    margin-top: 32px;

    @media (--tablet) {
      margin-top: 64px;
    }
  }

  & .CoursesPicker__singleItem {
    @mixin card 32px, 32px;
    height: 100%;

    & .CoursesPicker__studiesType {
      @mixin p50;
      @mixin highlightedText;
      margin: 0;
    }

    & .CoursesPicker__courseTitle {
      @mixin h50;
      @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
      font-weight: var(--boldFontWeight);
      margin: 0;
    }
  }

  & .CoursesPicker__loginButton {
    margin-top: 32px;

    @media (--tablet) {
      margin-top: 64px;
    }
  }
}
