.SectionImageBlock {
  background-color: var(--grayscaleWhiteBase);
  overflow: hidden;
  padding: 20px 0 45px;

  @media (--tablet) {
    padding: 45px 0;
  }

  & .SectionImageBlock__inner {
    @mixin wrapper;

    @media (--tablet) {
      display: flex;
      align-items: center;
    }
  }

  & .SectionImageBlock__imageContainer {
    @mixin lost-column 6/8;
    min-width: 270px;
    position: relative;

    @media (--mobileOnly) {
      margin: 80px auto 0 !important;
    }

    @media (--tablet) {
      @mixin lost-column 6/12;
    }

    &::after {
      @mixin lost-offset 2/6;
      content: "";
      position: absolute;
      top: -30px;
      width: 100vw;
      height: calc(100% + 90px);
      z-index: 1;
      background: no-repeat svg-load("icons/crate.svg", stroke=#D1D3D4);

      @media (--tablet) {
        top: -64px;
        width: 100%;
        height: 100%;
      }
    }

    & img {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
    }

    & .SectionImageBlock__primaryImageContainer {
      width: 100%;

      @media (--mobileOnly) {
        margin: 0 auto !important;
      }

      @media (--tablet) {
        @mixin lost-column 5/6, 1;
      }

      & img {
        border-radius: 20px;
      }
    }

    & .SectionImageBlock__secondaryImageContainer {
      @mixin lost-column 4/6, 1;
      min-width: 200px;
      margin-top: -90px;

      @media (--tablet) {
        @mixin lost-column 3/6, 1;
        margin-top: -120px;
        margin-left: 0 !important;
      }

      & img {
        border-radius: 10px;
      }
    }
  }

  & .SectionImageBlock__content {
    @media (--tablet) {
      @mixin lost-column 6/12, 1;
    }

    & .SectionImageBlock__contentInner {
      @media (--tablet) {
        @mixin lost-column 5/6;
      }

      & .SectionImageBlock__titleContainer {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        @media (--tablet) {
          margin-bottom: 35px;
        }
      }

      & .SectionImageBlock__titleImageContainer {
        @mixin aspectRatio 55/65;
        @mixin lost-column 1/3;
        max-width: 90px;
        display: inline-block;
        margin-right: 35px;

        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      & .SectionImageBlock__title {
        @mixin h40;
        @mixin breakWithHyphen;
        display: inline-block;
        max-width: 100%;
        position: relative;
        padding-bottom: 15px;
        margin: 0;

        @media (--tablet) {
          @mixin h30;
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 80%;
          height: 1px;
          background-color: var(--grayscaleBlackLighten10);
        }
      }

      & .SectionImageBlock__description {
        @mixin b2;
        white-space: pre-line;
        margin: 0 0 30px;

        @media (--tablet) {
          @mixin b0;
          margin-bottom: 35px;
        }
      }
    }
  }

  &:not(.SectionImageBlock--crateVisible) {
    & .SectionImageBlock__imageContainer {
      &::after {
        content: none;
      }
    }
  }

  &.SectionImageBlock--imageLeft {
    & .SectionImageBlock__inner {
      @media (--tablet) {
        flex-direction: row-reverse;
      }

      & .SectionImageBlock__content {
        @media (--tablet) {
          @mixin lost-column 6/12, 1;
        }
      }

      & .SectionImageBlock__contentInner {
        @media (--tablet) {
          @mixin lost-column 5/6, 1;
        }
      }

      & .SectionImageBlock__primaryImageContainer {
        margin-left: 0 !important;
      }

      & .SectionImageBlock__secondaryImageContainer {
        margin-left: auto !important;
      }
    }

    & .SectionImageBlock__imageContainer {
      @media (--tablet) {
        @mixin lost-column 6/12, 1;
      }

      &::after {
        @media (--tablet) {
          @mixin lost-offset -1/6;
        }
      }
    }
  }
}
