.TrainingResult {
  @mixin fullWidthBackground var(--support01Lighten20);
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - var(--mobileHeaderHeight));
  padding: 32px 0 64px;

  @media (--tablet) {
    min-height: calc(100vh - var(--footerMinHeight));
    padding-bottom: 128px;
  }

  & .TrainingResult__topContent {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: min-content min-content min-content 1fr;
    grid-template-areas:
      "progress close"
      "info info"
      "image image"
      "action action";
    align-items: start;

    @media (--tablet) {
      grid-template-columns: 6fr 6fr;
      grid-template-rows: min-content min-content min-content 1fr;
      grid-template-areas:
        ". close"
        "progress image"
        "info image"
        "action action";
    }

    @media (--desktop) {
      grid-template-columns: 5fr 1fr 6fr;
      grid-template-rows: min-content min-content min-content 1fr;
      grid-template-areas:
        ". . close"
        "progress . image"
        "info . image"
        "action action .";
    }
  }

  & .TrainingResult__close {
    grid-area: close;
    display: flex;
  }

  & .TrainingResult__closeLink.TrainingResult__closeLink {
    margin-left: auto;

    @media (--mobileOnly) {
      @mixin resetButtonStyles;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;

      &:hover {
        background-color: var(--support01Lighten60);
      }
    }
  }

  & .TrainingResult__closeLinkIcon {
    display: block;
    margin: unset;

    @media (--tablet) {
      display: none;
    }
  }

  & .TrainingResult__progress {
    grid-area: progress;
    margin-top: 16px;
  }

  & .TrainingResult__ringWrapper {
    width: 112px;

    @media (--tablet) {
      width: 180px;
    }
  }

  & .TrainingResult__info {
    grid-area: info;
  }

  & .TrainingResult__title {
    @mixin h30;
    @mixin lineUnder 16px, var(--grayscaleBlackLighten10), 128px, 2px;
    color: var(--grayscaleBlackLighten10);
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin h10;
      @mixin lineUnder 24px, var(--grayscaleBlackLighten10), 240px, 2px;
      margin-top: 32px;
    }
  }

  & .TrainingResult__description {
    @mixin p20;
    color: var(--grayscaleBlackLighten10);
    font-weight: var(--mediumFontWeight);
    white-space: pre-line;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin h40;
      margin-top: 48px;
    }
  }

  & .TrainingResult__imageWrapper {
    grid-area: image;
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 48px;
    }
  }

  & .TrainingResult__action {
    grid-area: action;
    margin-top: 56px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }

  & .TrainingResult__actionButton {
    @media (--mobileOnly) {
      width: 100%;
    }
  }

  & .TrainingResult__actionButtonIcon.TrainingResult__actionButtonIcon {
    @mixin strokeIconColor currentColor;
  }

  & .TrainingResult__bottomContent {
    padding: 32px 0 64px;
    margin-top: 96px;

    @media (--mobileOnly) {
      @mixin fullWidthBackground var(--support01Lighten60);
    }

    @media (--tablet) {
      background-color: var(--support01Lighten60);
      padding: 48px 64px 80px;
      border-radius: 32px;
      margin-top: 48px;
    }
  }

  & .TrainingResult__summaryTitle {
    @mixin h50;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
    color: var(--grayscaleBlackLighten10);
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, var(--grayscaleBlackLighten10), 128px, 2px;
    }
  }

  & .TrainingResult__summaryList {
    margin-top: 32px;

    @media (--tablet) {
      margin-top: 48px;
    }
  }

  & .TrainingResult__summaryItemWrapper {
    padding: 16px 0;
    border-bottom: 1px solid var(--grayscaleBlackLighten40);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      @media (--mobileOnly) {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}
