.FooterListItem.FooterListItem.FooterListItem {
  @mixin p30;
  @mixin focus;
  color: var(--grayscaleWhiteBase);
  display: inline-block;
  transition: color 0.1s linear;
  font-weight: var(--mediumFontWeight);
  padding: 16px 0;
  border-radius: 24px;

  @media (--desktop) {
    padding: 8px 16px;
  }

  &:hover {
    color: var(--primaryLighten40);
  }

  &:active {
    color: var(--primaryDarken40);
  }
}
