.TestResultActionButtons {
  justify-content: space-between;

  @media (--tablet) {
    display: flex;
  }

  & .TestResultActionButtons__container {
    @media (--tablet) {
      display: flex;
      margin-right: 24px;
    }
  }

  & .TestResultActionButtons__button {
    @media (--mobileOnly) {
      width: 100%;

      &:last-of-type {
        margin-top: 16px;
      }
    }
  }

  & .TestResultActionButtons__link {
    @media (--tablet) {
      margin-left: 24px;
    }

    @media (--mobileOnly) {
      width: 100%;

      &:last-of-type {
        margin-top: 16px;
      }
    }
  }

  & .TestResultActionButtons__icon.TestResultActionButtons__icon {
    @mixin strokeIconColor currentColor;
    transform: rotate(0deg);
    transition: transform 0.2s;

    &.TestResultActionButtons__icon--reversed {
      transform: rotate(180deg);
    }
  }
}
