.ErrorFooter {
  position: relative;
  background-color: var(--primaryBase);
  width: 100%;
  min-height: var(--mobileFooterMinHeight);

  @media (--tablet) {
    min-height: var(--footerMinHeight);
  }

  & .ErrorFooter__inner {
    @mixin wrapper;

    @media (--belowDesktop) {
      padding-top: 32px;
    }

    @media (--desktop) {
      display: grid;
      grid-template-columns: min-content 1fr min-content;
      grid-template-areas: "logo links";
      align-items: start;
    }
  }

  & .ErrorFooter__logoLink {
    @mixin offsetFocus;
    border-radius: 8px;

    @media (--desktop) {
      grid-area: logo;
      margin: 32px 128px 32px 0;
    }
  }

  & .ErrorFooter__logo {
    fill: var(--grayscaleWhiteBase);
    width: 96px;

    @media (--desktop) {
      width: 144px;
    }

    & svg {
      width: 100%;
    }
  }

  & .ErrorFooter__linksList {
    padding: 32px 0 48px;

    @media (--desktop) {
      grid-area: links;
      justify-self: end;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 32px 0;
    }

    & .ErrorFooter__singleLink {
      @media (--belowDesktop) {
        margin-top: 8px;
      }

      @media (--dekstop) {
        @mixin horizontalGap 8px;
        margin-top: 0;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
