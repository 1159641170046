.mobileOnly {
  display: none;

  @media (--mobileOnly) {
    display: block;
  }
}

.tablet {
  display: none;

  @media (--tablet) {
    display: block;
  }
}

.aboveTablet {
  display: none;

  @media (--desktop) {
    display: block;
  }
}

.belowDesktop {
  display: block;

  @media (--desktop) {
    display: none;
  }
}
