.Quote {
  display: flex;
  align-items: flex-start;
  margin: 16px 0 40px;

  @media (--tablet) {
    max-width: 620px;
    margin: 32px auto 48px;
  }

  & .Quote__icon {
    @mixin aspectRatio 1/1;
    width: 32px;
    flex-shrink: 0;
    fill: var(--primaryBase);
    margin-right: 16px;
  }

  & .Quote__text {
    @mixin p30;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    margin: 0;

    @media (--tablet) {
      @mixin h50;
    }
  }
}
