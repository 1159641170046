.StepsListItems {
  overflow-y: auto;

  & li {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}
