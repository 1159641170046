.RettsdataReferenceContent {
  & .RettsdataReferenceContent__linkContainer {
    padding-top: 32px;

    @media (--tablet) {
      padding-top: 48px;
    }
  }

  & .RettsdataReferenceContent__link {
    &:hover {
      color: var(--primaryDarken40);
    }
  }

  & .RettsdataReferenceContent__error {
    text-align: center;
  }
}
