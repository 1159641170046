.Video {
  & .Video__player {
    @mixin aspectRatio 9/16;
    position: relative;
    background-color: var(--grayscaleBlackLighten20);
    overflow: hidden;
    box-shadow: var(--boxShadow20);
    border: 8px solid var(--grayscaleWhiteBase);
    border-radius: 24px;
    margin-bottom: 24px;

    @media (--tablet) {
      margin-bottom: 32px;
    }

    & .Video__endPanel {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--grayscaleBlackBase);
      z-index: 1;
    }
  }

  & .Video__author {
    margin-bottom: 24px;

    @media (--tablet) {
      margin-bottom: 32px;
    }
  }

  & .Video__description {
    @mixin p10;
    @mixin lost-column 7/8;
    color: var(--grayscaleBlackLighten10);
    white-space: pre-line;
    margin: 0;
  }

  & .Video__anchorsContainer {
    margin-top: 48px;
  }
}
