.InfoElement {
  display: flex;
  align-items: center;

  & .InfoElement__icon {
    @mixin strokeIconColor var(--primaryBase);
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 24px;
  }

  & .InfoElement__value {
    @mixin p30;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    color: var(--grayscaleBlackLighten10);
    margin: 0;

    @media (--desktop) {
      @mixin h50;
    }
  }
}
