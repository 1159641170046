.TextCollapse {
  & .TextCollapse__collapse {
    visibility: visible !important;
    padding: 0 !important;
  }

  & .TextCollapse__content {
    margin: 0;
  }

  & .TextCollapse__button {
    @mixin p30;
    color: var(--grayscaleBlackLighten10);
    font-weight: var(--mediumFontWeight);
    display: flex;
    align-items: center;
    margin-top: 16px;

    @media (--tablet) {
      margin-top: 24px;
    }
  }

  & .TextCollapse__buttonIcon {
    @mixin strokeIconColor var(--primaryBase);
    transform: rotate(0deg);
    transition: transform 0.2s;
    margin-left: 8px;

    &.TextCollapse__buttonIcon--reversed {
      transform: rotate(180deg);
    }
  }
}
