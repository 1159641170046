.message-box-icon {
  background-color: var(--primaryLighten60);
  border-radius: 12px;

  & p {
    font-size: 1.2em;
    width: auto;
    white-space: nowrap;
    padding: 0 12px 0 12px;
    margin: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -8%;
    transform: translateY(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent var(--primaryLighten60);
  }
}
