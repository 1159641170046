.TaskSolution {
  & .TaskSolution__panel {
    box-shadow: var(--boxShadow20);
    border: 1px solid var(--grayscaleBlackLighten50);
    border-radius: 8px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  & .TaskSolution__heading {
    position: relative;
    background-color: var(--support03Lighten60);
    padding: 16px 24px;
    border-radius: 8px 8px 0 0;
    margin: 0;

    @media (--tablet) {
      padding: 32px 40px;
    }
  }

  & .TaskSolution__title {
    @mixin h50;
    margin: 0;
  }

  & .TaskSolution__buttonWrapper {
    position: absolute;
    top: -24px;
    right: -24px;
  }

  & .TaskSolution__content {
    @mixin p30;
    background-color: var(--grayscaleWhiteBase);
    padding: 32px 24px 40px;
    border-radius: 0 0 8px 8px;

    @media (--tablet) {
      @mixin p20;
      padding: 40px 40px 48px;
    }
  }
}
