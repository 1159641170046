.ThemeContentByType {
  @mixin p40;
  color: var(--grayscaleBlackLighten10);

  & .ThemeContentByType__list {
    @mixin clearList;
    display: flex;
    flex-wrap: wrap;
  }

  & .ThemeContentByType__listItem {
    position: relative;
    white-space: nowrap;
    padding-right: 8px;
    margin-right: 8px;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: calc(50% - 8px);
      width: 1px;
      height: 16px;
      background-color: var(--grayscaleBlackLighten40);
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;

      &::after {
        content: none;
      }
    }
  }
}
