@keyframes ShowStatusImage {
  0% {
    transform: translateY(100%);
  }

  25%,
  75%,
  100% {
    transform: translateY(0);
  }
}

@keyframes HideStatusImage {
  0% {
    transform: translateY(0);
  }

  25%,
  75%,
  100% {
    transform: translateY(100%);
    display: none;
  }
}

.TaskStatusImage {
  position: relative;

  & .TaskStatusImage__inner {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
    pointer-events: none;
    overflow: hidden;
  }

  & .TaskStatusImage__imageWrapper {
    display: none;
    position: relative;

    &.TaskStatusImage__imageWrapper--active {
      display: block;
      width: 100%;
      animation: ShowStatusImage 5s ease, HideStatusImage 2s ease 5s forwards;
    }
  }

  & .TaskStatusImage__image {
    display: block;
    width: 100%;
    max-height: 210px;

    @media (--mobileOnly) {
      max-height: 170px;
    }
  }
}
