.ArticleSummary {
  background-color: var(--support01Lighten20);
  color: var(--grayscaleBlackLighten10);
  padding: 24px 24px 32px;
  border-radius: 16px;

  @media (--tablet) {
    @mixin lost-offset 1/12;
    @mixin lost-column 10/12;
    padding: 24px 80px 48px 48px;
  }

  @media (--desktop) {
    @mixin lost-column 8/12;
  }

  @media (--desktopHD) {
    @mixin lost-column 7/12;
  }

  & .ArticleSummary__title {
    @mixin h40;
    @mixin lineUnder 8px, currentColor, 128px, 2px;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, currentColor, 128px, 2px;
    }
  }

  & .ArticleSummary__list {
    @mixin p20;
    margin: 16px 0 0;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .ArticleSummary__listItem {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}
