.ProgressByType {
  background-color: var(--grayscaleWhiteBase);
  box-shadow: var(--boxShadow10);
  padding: 32px 32px 40px;
  border-radius: 16px;

  @media (--mobileOnly) {
    padding: 24px;
  }

  & .ProgressByType__list {
    @mixin clearList;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    column-gap: 16px;
    row-gap: 8px;
  }

  & .ProgressByType__item {
    @mixin p20;
    display: contents;
  }

  & .ProgressByType__progressBar {
    display: flex;
    align-items: center;
  }

  & .ProgressByType__taskProgress {
    white-space: nowrap;
  }
}
