.Input {
  position: relative;
  display: inline-flex;
  align-items: center;

  & .Input__field {
    @mixin resetInputStyles;
    flex: 1;
    z-index: 1;
  }

  &.Input--default {
    @mixin fieldBase;

    & .Input__field {
      @mixin p30;

      &.focus-visible:focus + .Input__focusIndicator {
        box-shadow: 0 0 0 4px var(--notificationFocusBase);
      }
    }

    & .Input__focusIndicator {
      @mixin absoluteParentSize;
      border-radius: inherit;
    }
  }

  &.Input--disabled {
    @mixin fieldBase var(--grayscaleWhiteBase), var(--grayscaleBlackLighten40), var(--grayscaleBlackLighten50);
  }
}
