.SectionMultiQuoteBlock {
  position: relative;
  transform: translateY(-40px); /* THIS IS CAUSED BY PADDING ON SectionMultiQuoteBlock__wrapper */

  & .slick-track {
    display: flex;

    & .slick-slide {
      height: unset;

      & > * {
        height: 100%;
      }
    }
  }

  & .slick-dots {
    bottom: 24px;

    & li {
      margin: 0;

      & button {
        &:focus {
          outline: 1px solid var(--grayscaleBlackBase);
        }

        &::before {
          width: 11px;
          height: 11px;
          opacity: 1;
          background-color: none;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          border: 1px solid var(--grayscaleBlackBase);
          border-radius: 50%;
        }
      }

      &.slick-active button::before {
        background-color: var(--grayscaleBlackLighten10);
        border: 1px solid var(--grayscaleBlackLighten10);
      }
    }
  }

  & .SectionMultiQuoteBlock__wrapper {
    display: inline-flex !important;
    height: 100%;
    outline: none !important;
    padding-top: 40px;
  }
}

.SectionMultiQuoteBlock__arrow.Button.Button--clear {
  display: none;

  @media (--tablet) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40px;
    width: 100px;
    height: calc(100% - 40px);
    z-index: 1;
  }

  & .SvgIcon {
    width: 24px;
    height: 24px;
    transition-duration: 250ms;
    transform: translateX(0);
  }

  &:not(.SectionMultiQuoteBlock__arrow--next) {
    left: 16px;

    @media (--desktop) {
      left: 60px;
    }
  }

  &.SectionMultiQuoteBlock__arrow--next {
    right: 16px;

    @media (--desktop) {
      right: 60px;
    }
  }
}
