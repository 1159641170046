.StepPreview {
  position: relative;
  background-color: var(--support01Lighten60);
  z-index: 102;

  & .StepPreview__content {
    position: relative;
  }

  & .StepPreview__modeWrapper {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 103;
    padding-top: 16px;
  }

  & .StepPreview__modeInner {
    @mixin wrapper;
    display: flex;
  }

  & .StepPreview__mode {
    @media (--tablet) {
      display: inline-block;
      margin-left: auto;
    }
  }
}
