@define-mixin stepIconsAnimation {
  & .FiguresSvg__square {
    transform: translate(-16%, 0);
  }

  & .FiguresSvg__circle {
    transform: translate(8%, 0);
  }

  & .FiguresSvg__figure {
    transform: translate(0, 9%);
  }

  & .ArrowsSvg__arrow {
    transform: translate(-7%, 0);
  }
}

@define-mixin zoomImageAnimation {
  & .ThumbnailImage__picture {
    transform: scale(1.11);
  }
}
