.BookmarkLayer {
  @mixin absoluteParentSize;

  & .BookmarkLayer__bookmarkButton {
    position: absolute;
    width: 48px;
    height: 48px;
    right: 0;
    top: 0;
    z-index: 1;
    padding: 12px;
    border-radius: 8px;
  }

  & .BookmarkLayer__bookmarkIcon.BookmarkLayer__bookmarkIcon {
    @mixin strokeIconColor var(--grayscaleBlackBase);
    width: 24px;
    height: 24px;

    &.BookmarkLayer__bookmarkIcon--bookmarked.BookmarkLayer__bookmarkIcon--bookmarked {
      fill: var(--primaryBase);
      stroke: var(--primaryBase);
    }
  }

  &.BookmarkLayer--video {
    & .BookmarkLayer__bookmarkIcon {
      stroke: var(--grayscaleWhiteBase);
    }
  }

  &.BookmarkLayer--small {
    & .BookmarkLayer__bookmarkButton {
      width: 24px;
      height: 24px;
      padding: 4px;
    }

    & .BookmarkLayer__bookmarkIcon.BookmarkLayer__bookmarkIcon {
      width: 16px;
      height: 16px;
    }
  }
}
