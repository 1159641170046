.LessonList {
  & > li {
    margin-top: 48px;

    @media (--tablet) {
      margin-top: 96px;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
