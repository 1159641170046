.TestResult {
  & .TestResult__heading {
    @mixin fullWidthBackground var(--support01Lighten60);
    z-index: 1;
    padding-top: 32px;
    padding-bottom: 48px;

    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 10/12;
      @mixin defaultGutter;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content 1fr;
      grid-template-areas:
        "info image"
        "action action";
      align-items: start;
      row-gap: 48px;
      padding-top: 64px;
      padding-bottom: 80px;
    }
  }

  & .TestResult__info {
    grid-area: info;
  }

  & .TestResult__progressRing {
    width: 112px;

    @media (--tablet) {
      width: 180px;
    }
  }

  & .TestResult__title {
    @mixin h30;
    @mixin lineUnder 16px, currentColor, 128px, 2px;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin h20;
      @mixin lineUnder 24px, currentColor, 240px, 2px;
      margin-top: 48px;
    }
  }

  & .TestResult__description {
    @mixin p20;
    font-weight: var(--mediumFontWeight);
    white-space: pre-line;
    margin-top: 24px;
    margin-bottom: 0;

    @media (--tablet) {
      @mixin h40;
      margin-top: 48px;
    }
  }

  & .TestResult__imageWrapper {
    @mixin aspectRatio 2/3;
    grid-area: image;
    align-self: center;
  }

  & .TestResult__image {
    object-fit: contain;
  }

  & .TestResult__actionButtons {
    grid-area: action;
    margin-top: 32px;

    @media (--tablet) {
      margin: 0;
    }
  }

  & .TestResult__bottomActionButtons {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 32px;
    border-top: 1px solid var(--grayscaleBlackLighten30);
    margin-top: 16px;

    @media (--tablet) {
      flex-direction: row;
      padding-top: 16px;
      margin-top: 64px;
    }
  }

  & .TestResult__content {
    padding-top: 24px;
    padding-bottom: 64px;

    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 8/12;
      padding-top: 80px;
      padding-bottom: 96px;
    }
  }

  & .TestResult__taskHint {
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 48px;
    }
  }
}
