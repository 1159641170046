.QuotesSlider.QuotesSlider {
  display: flex;

  & .QuotesSlider__arrow {
    display: none;

    @media (--desktop) {
      @mixin outlineColor var(--grayscaleBlackLighten10), var(--grayscaleBlackLighten50);
      display: block;
      min-width: 48px;
    }
  }
}
