@define-mixin wrapper {
  width: 100%;
  max-width: var(--wrapperWidth);
  padding: 0 var(--mobileWrapperPadding);
  margin-right: auto;
  margin-left: auto;

  @media (--desktop) {
    padding: 0 var(--desktopSmallWrapperPadding);
  }

  @media (--desktopHD) {
    padding: 0 var(--desktopLargeWrapperPadding);
  }
}
