.RectangleThumbnail {
  @mixin linkOverlay;
  overflow: hidden;
  box-shadow: var(--boxShadow10);
  border: 1px solid var(--grayscaleBlackLighten40);
  border-radius: 8px;

  @media (--tablet) {
    box-shadow: var(--boxShadow20);
    border-width: 0;
  }

  & .RectangleThumbnail__imageWrapper {
    @mixin aspectRatio 2/3;
    position: relative;

    @media (--tablet) {
      @mixin aspectRatio 9/16;
    }
  }

  & .RectangleThumbnail__linkWrapper {
    @mixin flexCenter;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s linear, opacity 0.2s linear;
    pointer-events: none;
    z-index: 1;
  }

  & .RectangleThumbnail__link {
    pointer-events: auto;
  }

  & .RectangleThumbnail__infoWrapper {
    display: flex;
    flex-direction: column;
  }

  & .RectangleThumbnail__info {
    display: none;

    @media (--tablet) {
      @mixin p50;
      position: relative;
      display: flex;
      align-items: flex-end;
      flex: 1;
      color: var(--grayscaleBlackBase);
      padding: 8px 16px;
    }
  }

  & .RectangleThumbnail__subtitle {
    @mixin lineAbove 8px, var(--grayscaleBlackLighten30), 48px;
    position: relative;
    margin-right: auto;
  }

  & .RectangleThumbnail__timeStamp {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;

    & > * {
      @mixin horizontalGap 8px;
    }
  }

  & .RectangleThumbnail__timeStampIcon {
    width: 16px;
    height: 16px;
    fill: var(--grayscaleBlackBase);
  }

  & .RectangleThumbnail__progressBar {
    display: none;

    @media (--tablet) {
      display: block;
      margin-top: auto;
    }
  }

  &.RectangleThumbnail:not(.RectangleThumbnail--locked) {
    &:hover {
      @mixin stepIconsAnimation;
      @mixin zoomImageAnimation;

      & .RectangleThumbnail__linkWrapper {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.RectangleThumbnail--video {
    & .RectangleThumbnail__info {
      color: var(--grayscaleWhiteBase);
    }

    & .RectangleThumbnail__timeStampIcon {
      fill: var(--grayscaleWhiteBase);
    }
  }
}
