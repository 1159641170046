.ThemeArticleItem {
  @mixin linkOverlay;
  display: block;
  overflow: hidden;
  height: 100%;
  background-color: var(--grayscaleWhiteBase);
  box-shadow: var(--boxShadow20);
  border-radius: 16px;

  @media (--desktop) {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: 1fr minmax(0, 1fr);
  }

  & .ThemeArticleItem__imageContainer {
    @mixin aspectRatio 2/3;
    position: relative;
    width: 100%;

    @media (--desktop) {
      @mixin aspectRatio 3/4;
    }
  }

  & .ThemeArticleItem__image {
    object-fit: cover;
  }

  & .ThemeArticleItem__info {
    color: var(--grayscaleBlackLighten10);
    width: 100%;
    padding: 16px 24px 24px;

    @media (--desktop) {
      padding: 24px 40px 24px 0;
    }
  }

  & .ThemeArticleItem__category {
    @mixin p50;
    @mixin highlightedText;
    display: block;
  }

  & .ThemeArticleItem__title {
    @mixin h50;
    @mixin lineUnder 8px;
    @mixin breakWithHyphen;
    width: 100%;
    margin: 4px 0 0;
  }

  & .ThemeArticleItem__description {
    @mixin p30;
    @mixin lineClamp 4;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin p10;
      @mixin lineClamp 3;
      margin-top: 16px;
    }
  }
}
