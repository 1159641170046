.BottomSheet {
  @mixin absoluteParentSize;

  & .BottomSheet__sheet {
    @mixin wrapper;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 80%;
    z-index: 3;
    background-color: var(--grayscaleWhiteBase);
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    border-radius: 16px 16px 0 0;

    &.BottomSheet__sheet--blue {
      background-color: var(--notificationFocusLighten60);
    }
  }

  & .BottomSheet__sheetTop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0;
  }

  & .BottomSheet__bar {
    background-color: var(--grayscaleBlackLighten30);
    width: 64px;
    height: 4px;
    border-radius: 32px;
  }

  & .BottomSheet__buttonWrapper {
    position: absolute;
    top: -24px;
    right: 24px;
  }

  & .BottomSheet__backgroundButton {
    @mixin fixedFullSize;
    background-color: var(--backgroundShadow);
    z-index: 2;

    &:hover {
      background-color: var(--backgroundShadow);
    }
  }
}
