.StepIcon {
  width: 60%;
  height: 60%;
  top: 20%;
  left: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100%;
  }

  & * {
    transition: transform 250ms linear;
  }
}
