.HighlightedThumbnail {
  @mixin linkOverlay;
  display: block;
  overflow: hidden;
  height: 100%;
  box-shadow: var(--boxShadow20);
  border-radius: 8px;

  @media (--tablet) {
    display: grid;
    grid-template-columns: 1fr minmax(0, 1fr);
    border-radius: 16px;
  }

  & .HighlightedThumbnail__imageWrapper {
    @mixin aspectRatio 9/16;

    @media (--tablet) {
      margin-right: 40px;
    }
  }

  & .HighlightedThumbnail__infoBox {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 24px;

    @media (--tablet) {
      padding: 40px 40px 24px 0;
    }
  }

  & .HighlightedThumbnail__category {
    @mixin p50;
    @mixin highlightedText;
    display: block;

    @media (--tablet) {
      @mixin p40;
    }
  }

  & .HighlightedThumbnail__title {
    @mixin h50;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
    @mixin breakWithHyphen;
    width: 100%;
    margin: 4px 0 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineUnder 16px, var(--grayscaleBlackLighten30), 128px;
    }

    &:first-child {
      margin: 0;
    }
  }

  & .HighlightedThumbnail__description {
    @mixin p20;
    @mixin lineClamp 4;
    white-space: pre-line;
    margin: 16px 0 0;

    @media (--tablet) {
      @mixin p10;
      @mixin lineClamp 3;
      margin-top: 32px;
    }
  }

  & .HighlightedThumbnail__bottomContentWrapper {
    margin-top: auto;
  }

  & .HighlightedThumbnail__bottomContent {
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }

  &.HighlightedThumbnail:not(.HighlightedThumbnail--locked) {
    &:hover {
      @mixin stepIconsAnimation;
      @mixin zoomImageAnimation;
    }
  }

  &.HighlightedThumbnail--default {
    color: var(--grayscaleBlackLighten10);
    background-color: var(--grayscaleWhiteBase);
  }

  &.HighlightedThumbnail--dark {
    color: var(--grayscaleWhiteBase);
    background-color: var(--grayscaleBlackLighten10);

    & .HighlightedThumbnail__category {
      color: var(--primaryLighten30);
    }
  }
}
