.Panel {
  @mixin fixedFullSize;
  z-index: 200;

  & .Panel__outer {
    @mixin wrapper;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  & .Panel__wrapper {
    position: relative;
    z-index: 1;
    width: 100%;

    @media (--tablet) {
      @mixin lost-column 7/12;
      min-width: 500px;
    }
  }

  & .Panel__inner {
    overflow: hidden;
    background-color: var(--grayscaleWhiteBase);
    border-radius: 8px;
  }

  & .Panel__content {
    z-index: 100;
    overflow-y: auto;
    width: 100%;
    max-height: calc(100vh - 64px - var(--mobileSafariBottomInset));

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .Panel__closeButton {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 101;
  }

  & .Panel__background {
    @mixin fixedFullSize;
    background-color: var(--backgroundShadow);
  }
}
