.TileCollection {
  @mixin defaultGutter;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 100%));
  grid-auto-rows: 1fr;
  row-gap: 24px;
  width: 100%;
  margin-bottom: 1em;

  @media (--tablet) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  & .TileCollection__tile {
    height: 100%;
    background-color: var(--grayscaleWhiteBase);
    box-shadow: var(--boxShadow10);
    padding: 16px;
    border: 1px solid var(--grayscaleBlackLighten50);
    border-radius: 8px;

    & > * {
      width: 100%;
    }

    &.TileCollection__tile--centred,
    &.TileCollection__tile--centred > * {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &.TileCollection--small {
    @media (--tablet) {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  }

  &.TileCollection--large {
    @media (--tablet) {
      grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
    }
  }
}
