@define-mixin smallAvatar {
  @mixin p50;
  width: 32px;
}

@define-mixin mediumAvatar {
  @mixin p30;
  width: 40px;
}

@define-mixin largeAvatar {
  @mixin p10;
  width: 48px;
}

@define-mixin xLargeAvatar {
  @mixin h40;
  width: 80px;
}

@define-mixin xxLargeAvatar {
  @mixin h30;
  width: 112px;
}

@define-mixin xxxLargeAvatar {
  @mixin h20;
  width: 200px;
}
