.PaymentSidePanel {
  & .PaymentSidePanel__inner {
    width: 100%;
    flex: 1;
    padding: 32px 32px 48px;

    @media (--tablet) {
      padding: 64px 64px 0 64px;
    }
  }

  & .PaymentSidePanel__content {
    height: 100%;

    & .PaymentSidePanel__title {
      @mixin h30;
      @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
      margin: 0;

      @media (--tablet) {
        @mixin h40;
      }
    }

    & .PaymentSidePanel__description {
      @mixin h50;
      font-family: var(--baseFontFamily);
      font-weight: var(--mediumFontWeight);
      margin-top: 24px;

      @media (--tablet) {
        @mixin h40;
      }
    }

    & .PaymentSidePanel__descriptionThemes {
      @mixin p10;
      color: var(--grayscaleBlackLighten30);
    }

    & .PaymentSidePanel__pricingMessage {
      @mixin h50;
      font-family: var(--baseFontFamily);
      font-weight: var(--mediumFontWeight);
      margin: 24px 0 0;

      @media (--tablet) {
        @mixin h40;
      }
    }

    & .PaymentSidePanel__priceOptions {
      display: grid;
      row-gap: 16px;
      margin-top: 24px;

      @media (--desktop) {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        column-gap: 24px;
      }
    }

    & .PaymentSidePanel__buyCourseButtonWrapper {
      display: block;
      align-items: center;
      margin-top: 32px;

      @media (--tablet) {
        display: flex;
        margin-top: 64px;
      }

      & .PaymentSidePanel__paymentInProgress {
        display: flex;
        min-width: fit-content;
        align-items: center;
        color: var(--primaryBase);
        margin-top: 24px;
        margin-left: 24px;

        @media (--tablet) {
          margin-top: 0;
        }

        & .PaymentSidePanel__paymentSpinner {
          margin-right: 8px;
        }
      }
    }

    & .PaymentSidePanel__buyCourseButton {
      min-width: 235px;
    }
  }
}
