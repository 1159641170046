@define-mixin smallProgressBar {
  height: 2px;
  border-radius: 2px;
}

@define-mixin mediumProgressBar {
  height: 4px;
  border-radius: 2px;
}

@define-mixin largeProgressBar {
  height: 8px;
  border-radius: 4px;
}
