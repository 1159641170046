.AuthorInfo {
  display: flex;
  align-items: flex-start;
  color: var(--grayscaleBlackLighten10);

  & .AuthorInfo__avatar {
    margin-right: 16px;
  }

  & .AuthorInfo__name {
    @mixin p30;
    font-family: var(--headingsFontFamily);
    margin: 0;
  }

  & .AuthorInfo__role {
    @mixin p40;
  }
}
