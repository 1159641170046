.Error {
  min-height: calc(100vh - var(--footerMinHeight));

  & .Error__content {
    @mixin wrapper;
    padding-top: calc(32px + var(--mobileHeaderHeight));
    padding-bottom: 96px;

    @media (--tablet) {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      padding-top: calc(64px + var(--headerHeight));
      padding-bottom: 128px;
    }
  }

  & .Error__info {
    margin-top: 64px;

    @media (--tablet) {
      @mixin lost-column 5/12;
      margin-top: 0;
    }
  }

  & .Error__title {
    @mixin h20;
    margin: 0;

    @media (--tablet) {
      @mixin h10;
    }
  }

  & .Error__description {
    @mixin h40;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    margin: 16px 0 0;

    @media (--tablet) {
      @mixin h30;
      margin-top: 8px;
    }
  }

  & .Error__button {
    width: 100%;
    margin-top: 32px;

    @media (--tablet) {
      width: auto;
      margin-top: 64px;
    }
  }

  & .Error__imageWrapper {
    @mixin aspectRatio 2/3;

    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 6/12;
    }

    & img {
      object-fit: contain;
    }
  }
}
