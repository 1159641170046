.Avatar {
  @mixin aspectRatio 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--boldFontWeight);
  color: var(--grayscaleBlackLighten20);
  background-color: var(--support03Lighten60);
  position: relative;
  overflow: hidden;
  box-shadow: var(--boxShadow30);
  border-radius: 50%;

  & .Avatar__imageWrapper {
    @mixin absoluteParentSize;

    &::after {
      @mixin absoluteParentSize;
      content: "";
      background-color: var(--grayscaleBlackBase);
      opacity: 0.1;
    }
  }

  & .Avatar__image {
    @mixin absoluteParentSize;
    object-fit: cover;
  }

  &.Avatar--small {
    @mixin smallAvatar;
  }

  &.Avatar--medium {
    @mixin mediumAvatar;
  }

  &.Avatar--large {
    @mixin largeAvatar;
  }

  &.Avatar--xLarge {
    @mixin xLargeAvatar;
  }

  &.Avatar--xxLarge {
    @mixin xxLargeAvatar;
  }

  &.Avatar--xxxLarge {
    @mixin xxxLargeAvatar;
  }
}
