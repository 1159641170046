.BasicThemesSection {
  & .BasicThemesSection__list {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
    margin-top: 24px;

    @media (--mobileLarge) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: 40px;
      margin-top: 32px;
    }
  }
}
