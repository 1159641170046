.TaskHint {
  color: var(--grayscaleBlackLighten10);

  & .TaskHint__content {
    @mixin p20;
  }

  & .TaskHint__bubble {
    background-color: var(--notificationFocusLighten60);
    position: relative;
    padding: 24px 24px 32px;
    border-radius: 8px;

    @media (--tablet) {
      padding: 24px 32px 32px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 48px;
      bottom: -24px;
      border-width: 24px 12px 0 12px;
      border-style: solid;
      border-color: var(--notificationFocusLighten60) transparent transparent transparent;
    }
  }

  & .TaskHint__image {
    @mixin aspectRatio 1/1;
    max-width: 180px;
    margin-top: 32px;
  }

  & .TaskHint__relatedStep {
    margin-top: 8px;

    @media (--tablet) {
      margin-top: 16px;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
