.TrainingTask {
  display: flex;
  align-items: center;
  flex-direction: column;

  & h2 {
    text-align: center;
  }

  & p {
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 20rem;
    margin: -24px 0 0;
  }

  &:not(:first-child) {
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }

  & .TrainingMaskTask__feedback {
    @mixin p30;
    margin: 4px 0 24px;

    @media (--tablet) {
      @mixin h50;
      margin: 8px 0 32px;
    }

    &.TrainingMaskTask__feedback--correct {
      color: var(--notificationSuccessBase);
    }

    &.TrainingMaskTask__feedback--wrong {
      color: var(--notificationErrorBase);
    }
  }
}
