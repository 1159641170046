.UserLinksDialog {
  display: none;

  @media (--tablet) {
    display: block;
  }

  & .UserLinksDialog__dialog {
    width: 256px;
  }
}
