.BookmarkButton {
  & .BookmarkButton__button.Button {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      & span {
        color: var(--grayscaleBlackLighten20);
      }
    }

    & .BookmarkButton__text {
      color: var(--grayscaleBlackLighten10);
      font-weight: 700;
      margin-right: 8px;

      @media (--mobileOnly) {
        display: none;
      }
    }

    & .SvgIcon {
      fill: none;
      stroke: var(--grayscaleBlackLighten10);
      width: 14px;
      height: 18px;
    }

    &.BookmarkButton__bookmarked {
      & .SvgIcon {
        stroke: var(--primaryBase);
        fill: var(--primaryBase);
      }
    }
  }
}
