.SectionArticleItem {
  @mixin linkOverlay;
  height: 100%;
  background-color: var(--grayscaleWhiteBase);
  overflow: hidden;
  box-shadow: var(--boxShadow20);
  outline: 1px solid transparent;
  transition: box-shadow 0.2s ease-out, outline 0.2s ease-out;
  border-radius: 8px;

  @media (--tablet) {
    border-radius: 16px;
  }

  &:hover {
    @mixin zoomImageAnimation;
    box-shadow: none;
    outline-color: var(--grayscaleBlackLighten10);
  }

  & .StepThumbnail__linkOverlay:focus {
    border: 2px solid blue;
    border-radius: 20px;
  }

  & .SectionArticleItem__details {
    padding: 8px 24px 24px;

    @media (--tablet) {
      padding: 8px 32px 40px;
    }
  }

  & .SectionArticleItem__category {
    @mixin highlightedText;
    @mixin p50;
    display: block;
  }

  & .SectionArticleItem__title {
    @mixin lineUnder 12px, var(--grayscaleBlackLighten30), 96px;
    margin: 4px 0 0;

    &:first-child {
      margin-top: 0;
    }

    &.SectionArticleItem__title--small {
      @mixin p40;
    }

    &.SectionArticleItem__title--big {
      @mixin h40;
    }
  }

  & .SectionArticleItem__description {
    @mixin lineClamp 4;
    @mixin p30;
    color: var(--grayscaleBlackLighten10);
    margin: 24px 0 0;

    @media (--desktop) {
      @mixin lineClamp 3;
      @mixin p10;
    }
  }
}
