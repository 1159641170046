.ChoiceListOptionStatus {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & .ChoiceListOptionStatus__children--limitedWidth {
    width: 100%;

    @media (--tablet) {
      @mixin lost-column 5/8, 0;
    }
  }

  & .ChoiceListOptionStatus__children--fullWidth {
    width: 100%;
  }

  &:not(.ChoiceListOptionStatus--default) {
    & .Select__indicatorIcon.Select__indicatorIcon {
      fill: var(--grayscaleBlackBase);
    }

    & .Select--disabled {
      opacity: 0.6;
    }
  }

  &.ChoiceListOptionStatus--correct {
    & .Select__control.Select__control.Select__control.Select__control {
      background-color: var(--notificationSuccessLighten60);
      border-color: var(--actionSuccessBase);
    }
  }

  &.ChoiceListOptionStatus--incorrect {
    & .Select__control.Select__control.Select__control.Select__control {
      background-color: var(--notificationErrorLighten60);
      border-color: var(--actionErrorBase);
    }
  }

  &.ChoiceListOptionStatus {
    & .Select__control.Select__control.Select__control.Select__control {
      transition: border-color 0.5s, background-color 0.5s;
    }
  }
}
