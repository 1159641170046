.ChoiceListTestViewer {
  width: 100%;
  display: flex;
  justify-content: center;

  & > img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 550px;
  }

  & .ChoiceListTestViewer__model {
    @mixin aspectRatio 9/16;
  }
}
