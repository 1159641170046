.RemoveBookmarkDialog {
  max-width: 310px;

  & .RemoveBookmarkDialog__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
  }

  & .RemoveBookmarkDialog__bookmarkTitle {
    @mixin h50;
    margin: 0;
  }

  & .RemoveBookmarkDialog__exitButton {
    flex-shrink: 0;
    margin-left: 8px;
  }

  & .RemoveBookmarkDialog__bookmarkAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & .RemoveBookmarkDialog__cancelBookmarkButton {
    color: var(--grayscaleBlackLighten20);
    font-weight: var(--mediumFontWeight);
    transition: color 0.2s linear;
    margin-right: 16px;

    &:hover {
      color: var(--grayscaleBlackLighten10);
    }
  }
}
