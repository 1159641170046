.DashboardHero {
  padding: 24px 0 72px;

  @media (--desktop) {
    padding: 64px 0 96px;
  }

  & .DashboardHero__content {
    @mixin defaultGutter;
    display: grid;
    grid-template-areas:
      "header header"
      "image ringProgress"
      "taskTypeProgress taskTypeProgress";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
    row-gap: 24px;

    &.DashboardHero__content--authenticated {
      @media (--desktop) {
        grid-template-areas:
          "header image ringProgress"
          "header image taskTypeProgress";
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &.DashboardHero__content--anonymous {
      @media (--desktop) {
        grid-template-areas:
          "header image"
          "header image";
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  & .DashboardHero__headerContainer {
    grid-area: header;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .DashboardHero__title {
    @mixin h30;
    @mixin lineUnder 16px, var(--grayscaleBlackBase), 95%;
    font-family: var(--headingsFontFamily);
    font-weight: 700;
    margin: 0 0 24px;

    @media (--desktop) {
      @mixin h10;
      @mixin lineUnder 24px, var(--grayscaleBlackBase), 95%;
      display: inline-block;
      margin-bottom: 40px;
    }
  }

  & .DashboardHero__courseDescription {
    @mixin p10;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 28px;
  }

  & .DashboardHero__buyButton {
    align-self: flex-start;
  }

  & .DashboardHero__description {
    @mixin h50;
    font-family: var(--headingsFontFamily);
    margin: 0 0 16px;

    @media (--belowDesktop) {
      display: none;
    }
  }

  & .DashboardHero__searchContainer {
    display: none;

    @media (--desktop) {
      display: flex;
    }
  }

  & .DashboardHero__action {
    display: flex;
    align-items: center;

    @media (--desktop) {
      flex: 1;
    }
  }

  & .DashboardHero__input {
    flex: 1;
    margin-right: 8px;
  }

  & .DashboardHero__submit {
    & .SvgIcon.SvgIcon {
      @mixin strokeIconColor var(--grayscaleWhiteBase);
    }
  }

  & .DashboardHero__imageContainer {
    grid-area: image;
  }

  & .DashboardHero__progressRing {
    grid-area: ringProgress;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .ProgressRing {
      @mixin aspectRatio 1/1;
      width: 112px;
      margin: 0 auto 24px;

      @media (--desktop) {
        width: 180px;
      }
    }

    & .DashboardHero__progressRingDescription {
      @mixin p30;
      text-align: center;

      @media (--desktop) {
        @mixin p20;
      }
    }
  }

  & .DashboardHero__progressByType {
    grid-area: taskTypeProgress;
  }
}
