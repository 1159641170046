.TaskGroupStep {
  & .TaskGroupStep__content {
    @mixin p10;
    color: var(--grayscaleBlackLighten10);
  }

  & .TaskGroupStep__tasksList {
    margin-top: 48px;

    @media (--tablet) {
      margin-top: 80px;
    }

    & > li {
      margin-top: 32px;
      margin-bottom: 48px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & .TaskGroupStep__task {
    margin-top: 32px;
  }

  & .TaskGroupStep__taskTitle {
    @mixin h40;
    font-weight: 700;
    margin: 0;

    &.TaskGroupStep__taskTitle--hidden {
      @mixin visuallyHidden;
    }
  }

  & .TaskGroupStep__taskDescription {
    @mixin p10;
    color: var(--grayscaleBlackLighten20);
    white-space: pre-line;
    margin-top: 8px;

    @media (--tablet) {
      margin-top: 16px;
    }
  }

  & .TaskGroupStep__taskContent {
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }
}
