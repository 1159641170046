.SliderThumbnail {
  @mixin actionOverlay;

  --activeBackgroundOffset: 8px;

  &::before {
    @mixin absoluteParentSize;
    content: "";
    background-color: transparent;
    transition: background-color 0.2s ease-out;
    box-sizing: content-box;
    transform: translate(calc(-1 * var(--activeBackgroundOffset)), calc(-1 * var(--activeBackgroundOffset)));
    padding: var(--activeBackgroundOffset);
    border-radius: 8px;
  }

  & .SliderThumbnail__actionOverlay {
    z-index: 5;

    &:disabled {
      cursor: default;
    }

    &:hover {
      & + .HorizontalThumbnail:not(.HorizontalThumbnail--locked) {
        @mixin stepIconsAnimation;
        @mixin zoomImageAnimation;

        & .HorizontalThumbnail__linkWrapper {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &.SliderThumbnail--active {
    &::before {
      background-color: var(--grayscaleBlackLighten50);
    }
  }
}
