.SidePanel {
  @mixin fixedFullSize;
  z-index: 200;

  & .SidePanel__closeBackgroundButton {
    @mixin fixedFullSize;
    background-color: var(--backgroundShadow);

    &:hover {
      background-color: var(--backgroundShadow);
    }
  }

  & .SidePanel__panelWrapper {
    @mixin fixedFullSize;
    top: auto;
    height: 624px;
    max-height: 80%;

    @media (--desktop) {
      pointer-events: none;
      top: 0;
      height: 100%;
      max-height: 100%;
    }
  }

  & .SidePanel__panelWrapper--wrapper {
    @mixin wrapper;
  }

  & .SidePanel__panelWrapper--fullWidth {
    max-width: 1500px;
    height: 100%;
    max-height: none;
    left: auto;
  }

  & .SidePanel__panel {
    @mixin absoluteParentSize;
    pointer-events: auto;
    color: var(--grayscaleBlackLighten10);
    background-color: var(--grayscaleWhiteBase);
    border-radius: 16px 16px 0 0;

    @media (--belowDesktop) {
      left: 0 !important;
    }

    @media (--desktop) {
      @mixin lost-offset 4/12;
      @mixin lost-column 8/12;
      height: 100%;
      top: 0 !important;
      border-radius: 0 0 0 48px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -50vw;
        width: 50vw;
        height: 100%;
        background-color: inherit;
      }

      &.SidePanel__panel--notRounded {
        border-radius: 0;
      }
    }

    & .SidePanel__inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    & .SidePanel__closeMobileButton {
      display: flex;
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 3;

      @media (--desktop) {
        display: none;
      }
    }

    & .SidePanel__closeButton {
      display: none;

      @media (--desktop) {
        position: absolute;
        display: block;
        top: 48px;
        left: -24px;
      }

      & .SvgIcon {
        fill: var(--grayscaleWhiteBase);
        width: 24px;
        height: 24px;
      }
    }
  }
}
