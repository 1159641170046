.TrainingTaskFeedback {
  @mixin h30;
  font-weight: var(--boldFontWeight);
  font-family: var(--headingsFontFamily);
  margin: 0;

  @media (--tablet) {
    @mixin h20;
  }

  &.TrainingTaskFeedback--correct {
    color: var(--actionSuccessBase);
  }

  &.TrainingTaskFeedback--wrong {
    color: var(--actionErrorBase);
  }
}
