.VerticalThumbnail {
  @mixin linkOverlay;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: var(--boxShadow20);
  border-radius: 8px;

  @media (--tablet) {
    border-radius: 16px;
  }

  & .VerticalThumbnail__imageWrapper {
    @mixin aspectRatio 9/16;
  }

  & .VerticalThumbnail__infoBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--grayscaleBlackLighten10);
    padding: 16px 24px 40px;

    @media (--tablet) {
      padding: 24px 32px 40px;
    }
  }

  & .VerticalThumbnail__category {
    @mixin p50;
    @mixin highlightedText;
    display: block;
  }

  & .VerticalThumbnail__title {
    @mixin h50;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
    @mixin breakWithHyphen;
    width: 100%;
    margin: 4px 0 0;

    &:first-child {
      margin: 0;
    }
  }

  & .VerticalThumbnail__description {
    @mixin p30;
    @mixin lineClamp 3;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .VerticalThumbnail__bottomContentWrapper {
    margin-top: auto;
  }

  & .VerticalThumbnail__bottomContent {
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }
  }

  &.VerticalThumbnail:not(.VerticalThumbnail--locked) {
    &:hover {
      @mixin stepIconsAnimation;
      @mixin zoomImageAnimation;
    }
  }
}
