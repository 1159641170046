.MaskTaskContent {
  @mixin p30;
  color: var(--grayscaleBlackLighten10);
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  @media (--tablet) {
    @mixin p10;
    margin-bottom: 32px;
  }
}
