.TestThumbnailInfo {
  display: flex;
  flex-direction: column;

  @media (--mobileX) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  & .TestThumbnailInfo__tasksProgress {
    @media (--tablet) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  & .TestThumbnailInfo__icon {
    display: none;

    @media (--tablet) {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  & .TestThumbnailInfo__tasksNumber {
    @mixin p30;
    font-weight: var(--boldFontWeight);
    font-family: var(--headingsFontFamily);
    margin: 0;

    @media (--tablet) {
      @mixin h40;
      margin-right: 8px;
    }
  }

  & .TestThumbnailInfo__tasks {
    @mixin p30;
    margin: 0;

    @media (--tablet) {
      @mixin p20;
    }
  }

  & .TestThumbnailInfo__link {
    margin-top: 24px;
    margin-left: 0;

    @media (--tablet) {
      z-index: 2;
      white-space: nowrap;
      margin-top: 0;
      margin-left: 24px;
    }
  }
}
