.AuthorDetailsSidePanel {
  & .AuthorDetailsSidePanel__closeMobileButton.AuthorDetailsSidePanel__closeMobileButton {
    display: flex;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 3;

    @media (--desktop) {
      display: none;
    }
  }
}
