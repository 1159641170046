.MaskTaskError {
  padding-top: 96px;

  @media (--tablet) {
    padding-top: 128px;
  }

  & .MaskTaskError__button {
    margin: 0 auto;

    @media (--tablet) {
      @mixin xLargeButton;
    }
  }

  & .MaskTaskError__icon.MaskTaskError__icon {
    @mixin strokeIconColor var(--grayscaleWhiteBase);
    fill: none;
  }

  & .MaskTaskError__text {
    @mixin p30;
    text-align: center;
    margin: 16px 0 0;

    @media (--tablet) {
      @mixin p20;
      margin-top: 24px;
    }
  }

  & .MaskTaskError__image {
    max-width: 138px;
    margin-top: 16px;

    @media (--tablet) {
      max-width: 184px;
    }
  }
}
