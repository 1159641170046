.TrainingGroundPreview {
  background-color: var(--grayscaleBlackLighten10);
  height: 100vh;
  z-index: 102;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & .TrainingGroundPreview__inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: 80px;

    @media (--tablet) {
      @mixin wrapper;
      padding-top: 80px;
      padding-bottom: 48px;
    }
  }
}
