.SearchItem {
  @mixin linkOverlay;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;

  @media (--tablet) {
    padding-bottom: 32px;
    border-bottom: 1px solid var(--grayscaleBlackLighten30);
    margin-bottom: 40px;
  }

  & .SearchItem__thumbnailContainer {
    @mixin lost-column 3/8;
    z-index: 1;

    @media (--desktop) {
      @mixin lost-column 3/10;
    }

    &.SearchItem__thumbnailContainer--skeleton {
      @mixin aspectRatio 2/3;

      @media (--tablet) {
        @mixin aspectRatio 9/16;
      }
    }
  }

  & .SearchItem__skeletonThumbnail {
    height: 100%;
  }

  & .SearchItem__info {
    @mixin lost-column 5/8;

    @media (--desktop) {
      @mixin lost-column 7/10;
    }
  }

  & .SearchItem__category {
    @mixin p50;
    @mixin highlightedText;
    display: block;

    @media (--tablet) {
      @mixin p40;
    }
  }

  & .SearchItem__title {
    @mixin p40;
    color: var(--grayscaleBlackLighten10);
    margin: 4px 0 0;

    @media (--tablet) {
      @mixin h30;
      margin: 0;
    }
  }

  & .SearchItem__description {
    display: none;

    @media (--tablet) {
      @mixin p10;
      @mixin lineClamp 2;
      color: var(--grayscaleBlackLighten10);
      margin: 16px 0 0;
    }
  }
}
