.ArticleBuySection {
  background-color: var(--support01Lighten20);

  & .ArticleBuySection__wrapper {
    @mixin wrapper;
    padding-top: 48px;
    padding-bottom: 64px;

    @media (--tablet) {
      display: flex;
      align-items: center;
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }

  & .ArticleBuySection__content {
    @media (--tablet) {
      @mixin lost-column 6/12;
    }

    @media (--desktop) {
      @mixin lost-offset 1/12;
      @mixin lost-column 4/12;
    }
  }

  & .ArticleBuySection__title {
    @mixin h30;
    @mixin lineUnder 16px, currentColor, 128px, 2px;
    color: var(--grayscaleBlackLighten10);
    margin: 0;

    @media (--tablet) {
      @mixin h20;
    }
  }

  & .ArticleBuySection__description {
    @mixin p10;
    color: var(--grayscaleBlackLighten10);
    margin: 32px 0 0;
  }

  & .ArticleBuySection__action {
    margin-top: 32px;
  }

  & .ArticleBuySection__imageWrapper {
    margin-top: 32px;

    @media (--tablet) {
      @mixin lost-column 6/12;
      margin-top: 0;
    }
  }
}
