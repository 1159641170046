.TestHeader {
  @mixin headerContainer;
  z-index: 101;
  box-shadow: var(--boxShadow10);

  & .TestHeader__inner {
    @mixin wrapper;
    height: 100%;
  }

  & .TestHeader__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 0;

    @media (--tablet) {
      padding: 16px 0;
    }
  }

  & .TestHeader__testInfo {
    display: none;

    @media (--tablet) {
      @mixin lost-column 4/12;
      display: block;
      padding-right: 32px;
    }

    @media (--desktop) {
      @mixin lost-column 6/12;
      padding-right: 32px;
    }
  }

  & .TestHeader__subtitle {
    @mixin p50;
    @mixin highlightedText;
    display: block;
  }

  & .TestHeader__title {
    @mixin p30;
    @mixin lineClamp 2;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    margin-top: 2px;
  }

  & .TestHeader__interactive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;

    @media (--mobileOnly) {
      width: 100%;
    }

    @media (--tablet) {
      justify-content: flex-end;
      flex: 1;
    }
  }

  & .TestHeader__progress {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 8px;

    @media (--tablet) {
      margin-right: 32px;
    }
  }

  & .TestHeader__tasksCount {
    @mixin p30;
    font-weight: var(--mediumFontWeight);
    margin-right: 8px;

    @media (--tablet) {
      @mixin p10;
    }
  }

  & .TestHeader__tasksCountDescription {
    display: none;

    @media (--tablet) {
      @mixin p30;
      display: block;
      white-space: nowrap;
      margin-right: 16px;
    }
  }

  & .TestHeader__quitItem.TestHeader__quitItem {
    @media (--mobileOnly) {
      @mixin resetButtonStyles;
      background-color: transparent;
      transition: background-color 0.2s ease-out;
      padding: 8px;
      border-radius: 50%;

      &:hover {
        background-color: var(--support01Lighten20);
      }

      & .SvgIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
