.SingleFilter {
  & .SingleFilter__filterValueList {
    @mixin clearList;
    display: flex;

    & .SingleFilter__selectAll {
      margin-right: 8px;
    }

    & .SingleFilter__item {
      @mixin horizontalGap 8px;
    }
  }

  & .SingleFilter__filterValue {
    white-space: nowrap;
  }
}
