/* HEADER */

@define-mixin h00 {
  font-size: 144px;
  line-height: 144px;
}

@define-mixin h10 {
  font-size: 48px;
  line-height: 64px;
}

@define-mixin h20 {
  font-size: 40px;
  line-height: 56px;
}

@define-mixin h30 {
  font-size: 32px;
  line-height: 48px;
}

@define-mixin h40 {
  font-size: 24px;
  line-height: 36px;
}

@define-mixin h50 {
  font-size: 20px;
  line-height: 32px;
}

/* PARAGRAPH */

@define-mixin p10 {
  --fontSize: 20px;
  --lineHeight: 28px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin p20 {
  --fontSize: 18px;
  --lineHeight: 26px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin p30 {
  --fontSize: 16px;
  --lineHeight: 24px;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
}

@define-mixin p40 {
  font-size: 14px;
  line-height: 20px;
}

@define-mixin p50 {
  font-size: 12px;
  line-height: 16px;
}

/* QUOTE */

@define-mixin q10 {
  font-size: 32px;
  line-height: 48px;
}

@define-mixin q20 {
  font-size: 24px;
  line-height: 40px;
}

@define-mixin q30 {
  font-size: 20px;
  line-height: 32px;
}
