.ThemeItem {
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  padding: 16px;
  border: 2px solid var(--grayscaleBlackLighten10);
  border-radius: 16px;

  &:hover {
    background-color: var(--support01Lighten20);
    cursor: pointer;
  }

  & .ThemeItem__image {
    width: 48px;
    margin-right: 8px;

    @media (--desktop) {
      display: none;
    }
  }

  & .ThemeItem__title {
    @mixin p30;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    overflow: hidden;
    margin: 0 8px 0 0;
  }

  & .ThemeItem__checkboxWrapper {
    height: var(--choiceSize);
    pointer-events: none;
    margin-left: auto;
  }

  & .ThemeItem__checkbox.ThemeItem__checkbox.ThemeItem__checkbox {
    @mixin resetChoiceSpacing;
    @mixin choiceStatusColors var(--backgroundColor), svg-load("icons/check.svg", stroke=#19d5a0); /* --actionSuccessBase */
    @mixin checkboxStatusCheckmark;
    @mixin correctCheckboxStatusCheckmark;
  }

  & .ThemeItem__iconWrapper {
    margin-left: auto;

    & .SvgIcon {
      width: 24px;
      height: 24px;
    }
  }

  &.ThemeItem--checked {
    background-color: var(--notificationSuccessLighten60);

    &:hover {
      background-color: var(--notificationSuccessLighten40);
    }
  }
}
