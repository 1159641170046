.Login {
  & .Login__inner {
    @mixin wrapper;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - var(--footerMinHeight));
    padding-top: calc(40px + var(--mobileHeaderHeight));

    @media (--tablet) {
      flex-direction: row;
      padding-top: calc(120px + var(--headerHeight));
    }

    & .Login__info {
      @media (--tablet) {
        @mixin lost-column 5/12;
        @mixin lost-offset 1/12;
        padding-bottom: 64px;
      }

      & .Login__title {
        @mixin h40;
        @mixin lineUnder;
        position: relative;
        padding-bottom: 8px;
        margin: 0 0 16px;

        @media (--tablet) {
          @mixin h30;
        }
      }

      & .Login__description {
        @mixin p10;
        white-space: pre-line;
        margin: 0 0 16px;

        @media (--tablet) {
          @mixin h40;
        }
      }
    }

    & .Login__imageContainer {
      @mixin aspectRatio 2/3;
      width: 100%;
      margin-top: 32px;

      @media (--tablet) {
        @mixin lost-column 6/12;
        align-self: flex-end;
        margin-top: 0;
      }

      & .Login__image {
        object-fit: contain;
        object-position: bottom;
      }
    }
  }
}
