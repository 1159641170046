.TileThumbnail {
  @mixin linkOverlay;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 3fr 5fr;
  column-gap: var(--mobileGutter);
  position: relative;
  width: 100%;
  height: 100%;

  @media (--tablet) {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: var(--boxShadow20);
    border-radius: 8px;
  }

  & .TileThumbnail__linkOverlay {
    z-index: 1;
  }

  & .TileThumbnail__imageWrapper {
    @mixin aspectRatio 9/16;
    position: relative;

    @media (--mobileOnly) {
      @mixin aspectRatio 2/3;
      overflow: hidden;
      box-shadow: var(--boxShadow10);
      border: 1px solid var(--grayscaleBlackLighten40);
      border-radius: 8px;
    }

    @media (--tablet) {
      width: 100%;
    }
  }

  & .TileThumbnail__linkWrapper {
    @mixin flexCenter;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s linear, opacity 0.2s linear;
    pointer-events: none;
    z-index: 1;
  }

  & .TileThumbnail__link {
    pointer-events: auto;
  }

  & .TileThumbnail__progressBar {
    position: absolute;
    top: unset;
    bottom: 0;
    left: 0;
    width: 100%;
    height: unset;

    @media (--mobileOnly) {
      display: none;
    }
  }

  & .TileThumbnail__infoBox {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    @media (--tablet) {
      flex: 1;
      width: 100%;
      background-color: var(--grayscaleWhiteBase);
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
    }
  }

  & .TileThumbnail__title {
    @mixin p40;
    font-weight: 700;
    font-family: var(--headingsFontFamily);
    color: var(--grayscaleBlackLighten10);
    margin: 0;

    @media (--tablet) {
      @mixin p30;
      margin-bottom: 32px;
    }
  }

  & .TileThumbnail__secondaryContent {
    @mixin p50;
    color: var(--grayscaleBlackLighten20);
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    @media (--tablet) {
      margin-bottom: 0;
    }

    &:empty {
      display: none;
    }
  }

  & .TileThumbnail__subtitle {
    @media (--mobileOnly) {
      @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 48px;
    }

    @media (--tablet) {
      @mixin lineAbove 8px, var(--grayscaleBlackLighten50), 48px;
      flex: 1;
    }
  }

  & .TileThumbnail__timeStamp {
    display: flex;
    align-items: center;

    @media (--mobileOnly) {
      @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 48px;
      display: flex;
    }

    & > * {
      @mixin horizontalGap 8px;
    }

    & .TileThumbnail__timeStampIcon {
      width: 16px;
      height: 16px;
      fill: var(--grayscaleBlackLighten30);
    }
  }

  &.TileThumbnail:not(.TileThumbnail--locked) {
    &:hover {
      @mixin stepIconsAnimation;
      @mixin zoomImageAnimation;

      & .TileThumbnail__linkWrapper {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.TileThumbnail--video {
    & .TileThumbnail__subtitle {
      @media (--mobileOnly) {
        display: none;
      }
    }
  }
}
