.AddBookmarkDialog {
  max-width: 310px;

  & .AddBookmarkDialog__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .AddBookmarkDialog__bookmarkTitle {
    @mixin h50;
    margin: 0;
  }

  & .AddBookmarkDialog__exitButton {
    flex-shrink: 0;
    margin-left: 8px;
  }

  & .AddBookmarkDialog__textArea {
    width: 100%;
    height: 96px;
    margin-top: 8px;
  }

  & .AddBookmarkDialog__bookmarkAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }

  & .AddBookmarkDialog__cancelBookmarkButton {
    color: var(--grayscaleBlackLighten30);
    font-weight: var(--mediumFontWeight);
    margin-right: 16px;

    &:hover {
      color: var(--grayscaleBlackLighten10);
    }
  }

  & .AddBookmarkDialog__addBookmarkButton {
    color: var(--primaryBase);
    font-weight: var(--mediumFontWeight);

    &:hover {
      color: var(--primaryDarken20);
    }
  }

  & .AddBookmarkDialog__addBookmarkButton,
  & .AddBookmarkDialog__cancelBookmarkButton {
    transition: color 0.2s linear;
  }
}
