.Button.TabHeadingItem {
  @mixin h5;
  position: relative;
  color: var(--grayscaleBlackLighten20);
  white-space: nowrap;
  padding: 8px;
  margin-right: 30px;

  &:last-child {
    margin: 0;
  }

  &.TabHeadingItem--active::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--grayscaleBlackLighten10);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
}
