.MatchTask {
  & .MatchTask__answerWrapper {
    width: 0;
    overflow: hidden;
    white-space: nowrap;
  }

  & .MatchTask__listWrapper {
    margin-top: 40px;
  }

  & .MatchTask__tableWrapper {
    margin-top: 48px;
  }
}
