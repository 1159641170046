.Accordion {
  padding-bottom: 16px;

  & .Accordion__toggleButton.Accordion__toggleButton {
    @mixin h40;
    @mixin resetButtonStyles;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    font-weight: var(--mediumFontWeight);
    width: 100%;
    padding: 0;
  }

  &.Accordion--light {
    border-bottom: 1px solid var(--grayscaleWhiteBase);
  }

  &.Accordion--dark {
    border-bottom: 1px solid var(--grayscaleBlackBase);

    & .Accordion__toggleButton.Accordion__toggleButton {
      color: var(--grayscaleBlackBase);
    }

    & .Accordion__closeIcon,
    & .Accordion__openIcon {
      fill: var(--primaryBase);
    }
  }
}
