@import-normalize;

html {
  min-height: 100vh;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--support01Lighten60);
  padding: 0;
  margin: 0;

  & .ML__keyboard {
    --keyboard-background: var(--support01Lighten20);
    --keycap-background: var(--support01Lighten60);
    --keycap-secondary-text: var(--grayscaleBlackBase);
    --keycap-secondary-background: var(--support03Lighten60);
    --keycap-text: var(--grayscaleBlackBase);
    --keyboard-toolbar-text-active: var(--primaryDarken40);
    --keyboard-toolbar-text: var(--grayscaleBlackBase);
    --keycap-text-active: var(--grayscaleBlackBase);
    --keyboard-accent-color: none;
    --keycap-background-hover: var(--support01Lighten20);
    --keycap-secondary-background-hover: var(--grayscaleBlackLighten50);
    --keyboard-toolbar-background-hover: var(--grayscaleBlackLighten50);
    --keycap-shift-color: var(--primaryDarken40);
    --keycap-border: #9c736a;
    --keycap-border-bottom: #9c736a;
    --keycap-secondary-border: #9c736a;
    --keycap-secondary-border-bottom: #9c736a;
    --keyboard-border: var(--support01Base);
    --keycap-secondary-background-hover: #daeeff;
    --variant-panel-background: var(--support01Lighten20);

    & .MLK__rows > .row div.action.is-pressed {
      --keyboard-accent-color: var(--support03Base);
      color: var(--grayscaleBlackBase);
    }

    & .MLK__rows > .row div.action.is-active {
      --keyboard-accent-color: var(--support03Base);
      color: var(--grayscaleBlackBase);
    }

    & .MLK__rows > .row div.MLK__keycap.is-pressed {
      background: #ede3e0;
      color: var(--grayscaleBlackBase);
    }

    & .MLK__rows > .row div.MLK__keycap.is-active {
      background: #ede3e0;
      color: var(--grayscaleBlackBase);
    }

    & .MLK__rows > .row div.shift.is-active {
      background: var(--primaryLighten20);
    }

    & .MLK__rows > .row .warning {
      background: var(--primaryDarken20);
      color: var(--grayscaleBlackBase);

      &:hover {
        background: var(--primaryLighten20);
      }
    }

    &.is-visible {
      & .MLK__backdrop.MLK__backdrop {
        box-shadow: var(--backgroundShadow);
      }
    }
  }

  &.scrollLocked {
    position: fixed;
    width: 100%;
  }
}

main {
  min-height: calc(100vh - var(--mobileFooterMinHeight));

  @media (--tablet) {
    min-height: calc(100vh - var(--footerMinHeight));
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button {
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  padding: 0;
  border: none;
}

button,
input,
a {
  &:focus:not(.focus-visible) {
    outline: none;
  }
}

textarea {
  @mixin b2;
  color: var(--grayscaleBlackLighten30);
  resize: vertical;
  padding: 8px 22px;
  border-color: var(--grayscaleBlackLighten30);
  border-radius: 10px;

  &:focus {
    color: var(--grayscaleBlackBase);
    outline-color: var(--grayscaleBlackBase);
  }
}

fieldset {
  min-width: 0;
  padding: 0;
  border: 0;
  margin: 0;
}

th {
  font-weight: unset;
}

.stickyHeader {
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
}

.visuallyHidden {
  @mixin visuallyHidden;
}

.wrapper {
  @mixin wrapper;
}

.rc-switch::after {
  top: 2px;
}
