.BookmarkList {
  @mixin wrapper;
  padding-top: calc(24px + var(--mobileHeaderHeight));
  padding-bottom: 64px;

  @media (--tablet) {
    padding-top: calc(64px + var(--headerHeight));
    padding-bottom: 96px;
  }

  & .BookmarkList__inner {
    width: 100%;

    @media (--tablet) {
      @mixin lost-offset 1/12;
      @mixin lost-column 10/12;
    }
  }

  & .BookmarkList__title {
    @mixin h30;
    @mixin lineUnder 16px, currentColor, 128px, 2px;
    margin: 0;

    @media (--tablet) {
      @mixin h10;
      @mixin lineUnder 24px, currentColor, 240px, 2px;
    }
  }

  & .BookmarkList__listHeading {
    @mixin h50;
    @mixin lineAfter 16px, var(--grayscaleBlackLighten30);
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    margin: 32px 0 0;

    @media (--tablet) {
      @mixin h30;
      @mixin lineAfter 24px, var(--grayscaleBlackLighten30);
      margin-top: 80px;
    }
  }

  & .BookmarkList__list {
    margin-top: 24px;

    @media (--tablet) {
      margin-top: 32px;
    }

    & li {
      margin-bottom: 16px;

      @media (--tablet) {
        padding-bottom: 32px;
        border-bottom: 1px solid var(--grayscaleBlackLighten30);
        margin-bottom: 40px;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }

  & .BookmarkList__emptyList {
    margin-top: 40px;

    @media (--tablet) {
      margin-top: 48px;
    }
  }

  & .BookmarkList__emptyListImageWrapper {
    width: 100%;
    max-width: 200px;
    display: block;
    margin: 0 auto;

    @media (--tablet) {
      width: 290px;
    }
  }

  & .BookmarkList__emptyListText {
    @mixin h50;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    text-align: center;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin lost-offset 3/10;
      @mixin lost-column 4/10;
    }
  }
}
