.PersonsList {
  display: flex;
  justify-content: center;

  & .PersonsList__list {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (--desktop) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: 64px;
      width: 100%;
    }

    @media (--desktopLarge) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: 64px;
      width: 100%;
    }
  }

  & .PersonsList__singleItem {
    display: flex;
    justify-content: center;

    @media (--belowDesktop) {
      &:not(:first-child) {
        margin-top: 64px;
      }
    }
  }
}
