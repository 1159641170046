.LessonSection {
  & .LessonSection__stepList {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    margin-top: 24px;

    @media (--tablet) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: 40px;
      margin-top: 40px;
    }

    @media (--desktop) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &.LessonSection__stepList--highlighted {
      grid-template-columns: 1fr;
    }
  }
}
