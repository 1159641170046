.DashboardSections {
  & > * {
    @mixin verticalGap 32px;

    @media (--tablet) {
      @mixin verticalGap 64px;
    }

    &:last-child:not(.DashboardSections__section--stickToTheBottom) {
      margin-bottom: 96px;

      @media (--tablet) {
        margin-bottom: 128px;
      }
    }

    &.DashboardSections__section--noSpacingAbove {
      margin-top: 0;
    }
  }

  & .DashboardSections__examsLink {
    @media (--mobileOnly) {
      @mixin smallButton;
    }
  }

  & .DashboardSections__examsList {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
    margin-top: 32px;

    @media (--mobileLarge) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (--desktop) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
