.Tabs {
  & .Tabs__heading {
    position: relative;
    width: 100%;
    border-bottom: 1px solid var(--grayscaleBlackLighten40);
    margin: 25px 0 15px;

    @media (--tablet) {
      display: none;
    }

    & .Tabs__items {
      display: flex;
      align-items: flex-end;
      overflow-x: auto;
    }
  }

  & .Tabs__tabWrapper {
    @media (--mobileOnly) {
      display: none;
    }

    &.Tabs__tabWrapper--activeTab {
      display: block;
    }
  }

  &.Tabs--tabOnWideScreen {
    & .Tabs__heading {
      display: flex;
    }

    & .Tabs__tabWrapper {
      display: none;
    }
  }
}
