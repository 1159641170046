.ImageCollection {
  @mixin defaultGutter;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 100%));
  grid-auto-rows: 1fr;
  row-gap: 24px;
  width: 100%;
  margin-bottom: 1em;

  @media (--tablet) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  & .ImageCollection__imageWrapper {
    @mixin aspectRatio 9/16;
    position: relative;
    height: 100%;

    & figure {
      margin: 0;
    }

    & img {
      @mixin absoluteParentSize;
      object-fit: contain;
    }
  }

  &.ImageCollection--small {
    @media (--tablet) {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  }

  &.ImageCollection--large {
    @media (--tablet) {
      grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
    }
  }
}
