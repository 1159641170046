.Header {
  @mixin headerContainer;
  z-index: 100;
  transition: background-color 0.2s ease-out;

  & .Header__inner {
    @mixin wrapper;
    height: 100%;
  }

  & .Header__content {
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grayscaleBlackLighten50);
  }

  & .Header__linkList {
    display: none;

    @media (--tablet) {
      display: flex;

      & .Header__singleLink {
        @mixin horizontalGap 8px;
      }
    }
  }

  & .Header__link {
    @mixin focus;
    @mixin p30;
    color: var(--grayscaleBlackLighten10);
    font-weight: var(--mediumFontWeight);
    transition: color 0.1s linear, background-color 0.2s ease-out;
    white-space: nowrap;
    padding: 8px 16px;
    border-radius: 24px;

    @media (--desktop) {
      padding: 8px 24px;
    }

    & .Header__linkText {
      display: inline-block;
      transition: transform 0.1s linear;
    }

    &:hover {
      color: var(--primaryDarken40);

      & .Header__linkText {
        transform: translateY(-2px);
      }
    }

    &.Header__link--active {
      background-color: var(--support01Lighten20);
    }
  }

  & .Header__actionSection {
    display: flex;
    align-items: center;
    margin-left: 16px;

    @media (--desktop) {
      margin-left: 32px;
    }

    &:empty {
      margin-left: 0;
    }

    & > * {
      @mixin horizontalLeftSpacing 8px;
    }

    & .Header__actionButton {
      white-space: nowrap;
      margin-right: 16px;
    }

    & .Header__buyButton {
      display: none;

      @media (--desktopLarge) {
        display: flex;
      }
    }
  }

  & .Header__actionIcon.Header__actionIcon {
    width: 24px;
    height: 24px;
    transition: stroke 0.1s linear, transform 0.1s linear;
  }

  & .Header__iconWrapperStyling {
    @mixin focus;
    @mixin strokeIconColor var(--primaryBase);
    background-color: transparent;
    transition: background-color 0.2s ease-out;
    padding: 8px;
    border-radius: 50%;

    &:hover {
      @mixin strokeIconColor var(--primaryDarken40);

      & .Header__actionIcon {
        transform: translateY(-2px);
      }
    }

    &.Header__iconWrapperStyling--active {
      background-color: var(--support01Lighten20);
    }
  }

  & .Header__tabletActionItem.Header__tabletActionItem {
    display: none;

    @media (--tablet) {
      display: inline-block;
    }
  }

  & .Header__mobileActionItem {
    display: inline-block;

    @media (--tablet) {
      display: none;
    }
  }

  &.Header--sticky {
    box-shadow: var(--boxShadow10);

    & .Header__content {
      border-bottom: 0;
    }
  }
}
