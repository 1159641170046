.HorizontalCard {
  @mixin linkOverlay;
  display: flex;
  background-color: var(--support01Lighten20);
  overflow: hidden;
  box-shadow: var(--boxShadow10);
  transition: box-shadow 0.2s ease-out;
  border-radius: 8px;

  &:hover {
    @mixin zoomImageAnimation;
    @mixin stepIconsAnimation;
    box-shadow: none;
  }

  & .HorizontalCard__imageWrapper {
    @mixin aspectRatio 2/3;
    flex: 3;
    max-width: 120px;
    background-color: var(--support01Base);
  }

  & .HorizontalCard__infoBox {
    flex: 5;
    padding: 8px 8px 16px 16px;
  }

  & .HorizontalCard__category {
    @mixin highlightedText;
    @mixin p50;
  }

  & .HorizontalCard__title {
    @mixin p40;
    margin: 4px 0 0;

    &:first-child {
      margin: 0;
    }
  }
}
