.BuyCourseItem {
  @mixin card 8px 16px, 16px;
  height: 100%;
  display: flex;
  align-items: flex-start;

  & .BuyCourseItem__info {
    flex: 1;
  }

  & .BuyCourseItem__radioWrapper {
    pointer-events: none;
  }

  & .BuyCourseItem__price {
    @mixin h40;
    color: var(--grayscaleBlackLighten10);
    font-weight: var(--mediumFontWeight);
    padding-left: var(--choicePaddingLeft);
    margin: 0;

    &.BuyCourseItem__price--promotional {
      color: var(--primaryBase);
    }
  }

  & .BuyCourseItem__regularPrice {
    @mixin p50;
    white-space: nowrap;
    padding-left: var(--choicePaddingLeft);
  }

  & .BuyCourseItem__promotionValue {
    @mixin p40;
    color: var(--grayscaleWhiteBase);
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    text-align: center;
    white-space: nowrap;
    display: inline-block;
    background-color: var(--primaryBase);
    padding: 2px 16px;
    border-radius: 16px;
    margin-top: 2px;
    margin-left: 32px;
  }

  &:not(.BuyCourseItem--promotional) {
    @media (--tablet) {
      padding-right: 32px;
    }
  }

  &:not(.BuyCourseItem--disabled) {
    &:hover {
      border-color: var(--grayscaleBlackLighten30);
    }
  }
}
