.TextTask {
  & .TextTask__content {
    @mixin p30;
    color: var(--grayscaleBlackLighten10);

    @media (--tablet) {
      @mixin p10;
    }
  }
}
