.ArticleList {
  @mixin wrapper;
  padding-top: var(--mobileHeaderHeight);
  padding-bottom: 80px;

  @media (--tablet) {
    padding-top: var(--headerHeight);
    padding-bottom: 164px;
  }

  & .ArticleList__hero {
    @mixin fullWidthBackground var(--support01Lighten20);
    padding: 32px 0;

    @media (--tablet) {
      display: flex;
      align-items: center;
      padding: 24px 0;
    }
  }

  & .ArticleList__content {
    color: var(--grayscaleBlackLighten10);

    @media (--tablet) {
      @mixin lost-column 6/12;
    }
  }

  & .ArticleList__title {
    @mixin h30;
    @mixin lineUnder 8px, currentColor, 128px, 2px;
    margin: 0;

    @media (--tablet) {
      @mixin h20;
      @mixin lineUnder 16px, currentColor, 240px, 2px;
    }
  }

  & .ArticleList__description {
    @mixin p10;
    font-weight: var(--mediumFontWeight);
    margin: 32px 0 0;

    @media (--tablet) {
      margin-top: 24px;
    }
  }

  & .ArticleList__imageWrapper {
    max-width: 400px;

    @media (--mobileOnly) {
      margin: 32px auto 0;
    }

    @media (--tablet) {
      @mixin lost-column 6/12;
    }

    @media (--desktop) {
      @mixin lost-offset 1/12;
      @mixin lost-column 5/12;
    }

    @media (--desktopHD) {
      @mixin lost-offset 1/12;
      @mixin lost-column 4/12;
    }
  }

  & .ArticleList__filters {
    margin-top: 24px;

    @media (--mobileXLarge) {
      @mixin defaultGutter;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (--tablet) {
      margin-top: 32px;
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &:empty {
      margin-top: 0;
    }
  }

  & .ArticleList__listWrapper {
    margin-top: 56px;

    @media (--mobileXLarge) {
      margin-top: 48px;
    }
  }

  & .ArticleList__list {
    display: grid;
    row-gap: 32px;

    @media (--mobileXLarge) {
      @mixin defaultGutter;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (--tablet) {
      row-gap: 48px;
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  & .ArticleList__emptyListImageWrapper {
    width: 100%;
    max-width: 200px;
    display: block;
    margin: 0 auto;

    @media (--tablet) {
      width: 290px;
    }
  }

  & .ArticleList__emptyListText {
    @mixin h50;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    text-align: center;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin lost-offset 3/10;
      @mixin lost-column 4/10;
    }
  }
}
