.TestTaskList {
  width: 100%;

  & > li {
    padding: 24px 0 8px;

    @media (--tablet) {
      padding: 32px 0 16px;
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}
