.StepsListPanel {
  & .StepsListPanel__heading {
    display: flex;
    align-items: center;
    padding: 24px 32px 16px;
  }

  & .StepsListPanel__progress {
    flex: 1;
    margin-right: 8px;
  }

  & .StepsListPanel__closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
  }

  & .StepsListPanel__items {
    padding: 24px 32px 48px;
    border-top: 1px solid var(--grayscaleBlackLighten50);
  }
}
