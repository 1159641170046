.CardThemeDetailed {
  position: relative;
  background-color: var(--grayscaleWhiteBase);
  overflow: hidden;
  transition: border-color 0.2s ease-out;
  border: 1px solid var(--grayscaleBlackLighten30);
  border-radius: 16px;

  @media (--tablet) {
    display: flex;

    &:hover {
      border-color: transparent;

      &::after {
        box-shadow: inset 0 0 0 2px var(--support04Darken20);
      }
    }
  }

  &::after {
    @mixin absoluteParentSize;
    content: "";
    transition: box-shadow 0.2s ease-out;
    pointer-events: none;
    border-radius: inherit;
  }

  & .CardThemeDetailed__themeInfo {
    @mixin linkOverlay;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
    padding: 24px;

    @media (--tablet) {
      @mixin lost-column 6/12;
      padding: 40px;
    }
  }

  & .CardThemeDetailed__themeDetails {
    flex: 1;
    overflow: hidden;
  }

  & .CardThemeDetailed__cardNumber {
    @mixin p40;
    @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 48px;
    font-weight: var(--boldFontWeight);
    font-family: var(--headingsFontFamily);
    margin: 0 0 8px;

    @media (--tablet) {
      @mixin h40;
      @mixin lineUnder 8px, var(--grayscaleBlackLighten30), 96px;
      margin-bottom: 16px;
    }
  }

  & .CardThemeDetailed__titleWrapper {
    margin: 0 0 16px;

    @media (--tablet) {
      margin-bottom: 24px;
    }
  }

  & .CardThemeDetailed__title {
    @mixin lineClamp 3;
    @mixin h50;
    @mixin breakWithHyphen;
    margin: 0;

    @media (--tablet) {
      @mixin h40;
    }
  }

  & .CardThemeDetailed__description {
    @mixin p30;
    @mixin lineClamp 3;
    color: var(--grayscaleBlackLighten10);
    white-space: pre-line;
    margin: 0;

    @media (--tablet) {
      @mixin p10;
      @mixin lineClamp 4;
    }
  }

  & .CardThemeDetailed__imageWrapper {
    @mixin aspectRatio 1/1;
    width: 100%;
    flex-shrink: 0;
    max-width: 80px;
    margin-left: 16px;

    @media (--desktop) {
      max-width: 168px;
      margin-left: 40px;
    }
  }

  & .CardThemeDetailed__image {
    object-fit: contain;
  }

  & .CardThemeDetailed__lessonsInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 24px 32px;

    @media (--tablet) {
      @mixin lost-column 6/12;
      padding: 40px 40px 40px 0;
    }
  }

  & .CardThemeDetailed__thumbnailWrapper {
    margin-bottom: 24px;

    @media (--tablet) {
      margin-bottom: 40px;
    }
  }

  & .CardThemeDetailed__lessonInfoBottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    grid-gap: 24px;
    margin-top: auto;

    @media (--tablet) {
      grid-gap: 40px;
    }
  }

  & .CardThemeDetailed__progress {
    flex: 1;
  }

  & .CardThemeDetailed__nextStepLink {
    display: block;

    @media (--mobileOnly) {
      @mixin mediumButton;
    }
  }

  &.CardThemeDetailed--withoutNumber {
    & .CardThemeDetailed__titleWrapper {
      @mixin lineUnder 8px;

      @media (--tablet) {
        @mixin lineUnder 16px;
      }
    }
  }
}
