.PreviousExams {
  color: var(--grayscaleBlackLighten10);
  padding-top: var(--mobileHeaderHeight);

  @media (--tablet) {
    padding-top: var(--headerHeight);
  }

  & .PreviousExams__inner {
    @mixin wrapper;
  }

  & .PreviousExams__hero {
    padding: 32px 0 64px;

    @media (--tablet) {
      display: flex;
      align-items: flex-start;
      padding: 64px 0;
    }
  }

  & .PreviousExams__content {
    @media (--tablet) {
      @mixin lost-column 6/12 2;
    }
  }

  & .PreviousExams__title {
    @mixin h40;
    @mixin lineUnder 8px, currentColor, 128px, 2px;
    margin: 0;

    @media (--tablet) {
      @mixin h20;
      @mixin lineUnder 24px, currentColor, 240px, 2px;
    }

    @media (--desktop) {
      @mixin h10;
    }
  }

  & .PreviousExams__description {
    @mixin p20;
    font-weight: var(--mediumFontWeight);
    white-space: pre-line;
    margin: 24px 0 0;

    @media (--tablet) {
      @mixin p10;
      margin-top: 40px;
    }
  }

  & .PreviousExams__imageContainer {
    @media (--tablet) {
      @mixin lost-column 6/12 2;
    }
  }

  & .PreviousExams__filters {
    @media (--tablet) {
      @mixin defaultGutter;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  & .PreviousExams__filter {
    @media (--mobileOnly) {
      @mixin verticalGap 16px;
    }
  }

  & .PreviousExams__filtersListWrapper {
    margin-top: 16px;

    @media (--tablet) {
      display: grid;
      grid-template-columns: minmax(0, 1fr) min-content;
      align-items: center;
      column-gap: 16px;
      margin-top: 32px;
    }
  }

  & .PreviousExams__filtersList {
    @mixin clearList;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: -8px;
  }

  & .PreviousExams__filtersListItem {
    @mixin horizontalGap 8px;
    margin-bottom: 8px;
  }

  & .PreviousExams__clearFiltersButton {
    @mixin p40;
    display: flex;
    align-items: center;
    color: var(--grayscaleBlackLighten10);
    font-family: var(--headingsFontFamily);
    white-space: nowrap;
    transition: color 0.3s ease-out;
    padding: 8px 16px;

    @media (--mobileOnly) {
      margin: 8px 0 0 auto;
    }

    &:hover {
      color: var(--grayscaleBlackLighten20);
    }
  }

  & .PreviousExams__clearFiltersIcon {
    fill: currentColor;
    margin-right: 8px;
  }

  & .PreviousExams__exams {
    padding: 32px 0 80px;

    @media (--tablet) {
      padding: 48px 0 128px;
    }
  }

  & .PreviousExams__yearsList {
    @mixin clearList;
  }

  & .PreviousExams__yearsListItem {
    @mixin verticalGap 32px;
  }

  & .PreviousExams__examsList {
    @mixin defaultGutter;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
    margin-top: 32px;

    @media (--mobileLarge) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (--desktopHD) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  & .PreviousExams__emptyExamsList {
    margin-top: 32px;
  }

  & .PreviousExams__articlesSection {
    @mixin fullWidthBackground var(--support01Base);
    padding: 40px 0 64px;

    @media (--tablet) {
      padding: 48px 0 144px;
    }
  }

  & .PreviousExams__articlesList {
    margin-top: 32px;

    @media (--tablet) {
      margin-top: 48px;
    }
  }

  & .PreviousExams__articlesListItem {
    @mixin verticalGap 32px;
  }
}
