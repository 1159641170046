.ScreenSizeAwareButton {
  & .ScreenSizeAwareButton__button.ScreenSizeAwareButton__button {
    display: none;

    @media (--tablet) {
      display: block;
    }

    &.ScreenSizeAwareButton__button--mobile {
      display: block;
      width: 100%;

      @media (--tablet) {
        display: none;
      }
    }
  }
}
