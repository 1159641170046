.LessonPreview {
  @mixin wrapper;
  padding-top: 80px;
  padding-bottom: 48px;

  & .LessonPreview__themeTitle {
    @mixin h50;
    @mixin lineAfter 24px, var(--grayscaleBlackLighten20), none;
    font-family: var(--headingsFontFamily);
    font-weight: var(--boldFontWeight);
    width: 100%;
    margin: 0;

    @media (--tablet) {
      @mixin h30;
    }
  }

  & .LessonPreview__content {
    margin-top: 40px;

    @media (--tablet) {
      display: flex;
      margin-top: 48px;
    }
  }

  & .LessonPreview__step {
    width: 100%;

    @media (--tablet) {
      @mixin lost-column 8/12;
    }
  }

  & .LessonPreview__title {
    @mixin h50;
    @mixin breakWithHyphen;
    font-family: var(--headingsFontFamily);
    margin: 0 0 16px;
  }

  & .LessonPreview__description {
    @mixin p10;
    color: var(--grayscaleBlackLighten20);
    white-space: pre-line;
    margin: 0 0 24px;

    @media (--tablet) {
      margin-bottom: 32px;
    }
  }

  & .LessonPreview__stepListMock {
    display: none;

    @media (--tablet) {
      @mixin lost-column 4/12;
      display: block;
      position: sticky;
      top: calc(16px + var(--headerHeight));
      left: 0;
      height: 600px;
      max-height: calc(100vh - var(--headerHeight) - 32px);
      overflow: hidden;
      background-color: var(--grayscaleWhiteBase);
      box-shadow: 0 0 40px var(--support01Lighten20);
      border: 1px solid var(--grayscaleBlackLighten50);
      border-radius: 16px;
    }
  }

  & .LessonPreview__stepActions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 64px;

    @media (--mobileOnly) {
      margin-bottom: 64px;
    }
  }

  & .LessonPreview__markStep {
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grayscaleBlackLighten30);
    margin-bottom: 24px;

    & .CompleteSwitch {
      margin-top: 0;
    }
  }

  & .LessonPreview__stepButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  & .LessonPreview__stepWrapper {
    display: flex;
    align-items: center;

    &.LessonPreview__stepWrapper--reverse {
      flex-direction: row-reverse;
    }
  }

  & .LessonPreview__navigationStepText {
    display: none;

    @media (--tablet) {
      display: inline;
      margin: 0 16px;
    }
  }
}
