.TrainingItemContainer {
  width: 100%;
  flex: 1;
  background-color: var(--support01Lighten60);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media (--tablet) {
    border-radius: 24px;
  }

  & .Input {
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 32px;
  }

  & .TrainingItemContainer__topContent {
    @media (--desktop) {
      padding: 0 32px;
    }
  }

  & .TrainingItemContainer__themeImageWrapper {
    display: none;

    @media (--tablet) {
      @mixin aspectRatio 1/1;
      width: 100%;
      max-width: 144px;
      display: block;
    }
  }

  & .TrainingItemContainer__themeImage {
    object-fit: contain;
  }

  & .TrainingItemContainer__themeTitle {
    @mixin highlightedText;
    @mixin p50;
    margin-top: 24px;

    @media (--desktop) {
      font-size: 1.2em;
    }

    @media (--mobileOnly) {
      margin-top: 12px;
    }
  }

  & .TrainingItemContainer__taskTitle {
    padding: 10px;
    margin: -10px 0 0;

    @media (--desktop) {
      font-size: 1.8em;
    }
  }

  & .TrainingItemContainer__taskDescription {
    display: none;

    @media (--desktop) {
      @mixin p10;
      display: block;
      white-space: pre-line;
      margin-top: 24px;
    }
  }

  & .TrainingItemContainer__navigationContainer {
    display: none;

    @media (--tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  & .TrainingItemContainer__navigationElement {
    position: relative;
    display: flex;
    align-items: center;
  }

  & .TrainingItemContainer__navigationText {
    @mixin p30;
    color: var(--grayscaleBlackLighten10);
    margin: 0 16px;
  }

  & .TrainingItemContainer__task {
    flex: 1;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  & .TrainingItemContainer__taskContent {
    position: relative;
    flex: 1;
    width: 100%;
    overflow: hidden;
  }

  & .TrainingItemContainer__taskContainer {
    max-height: 100%;
    overflow-y: scroll;
    width: auto;
    margin: 0 auto;

    @media (--mobileOnly) {
      @mixin wrapper;
      display: block;
      justify-content: normal;
      padding-top: 16px;
      padding-bottom: 32px;
    }

    @media (--tablet) {
      @mixin scrollbar var(--support01Lighten60), var(--grayscaleBlackLighten10);
      padding: 48px 40px;
    }

    /* stylelint-disable selector-nested-pattern, rule-empty-line-before */
    .MaskTaskStage {
      width: 40rem;

      @media (--mobileOnly) {
        width: 20rem;
      }
    }
    /* stylelint-enable selector-nested-pattern, rule-empty-line-before */

    /* stylelint-disable selector-nested-pattern, rule-empty-line-before */
    .ChoiceTask {
      margin-bottom: 48px;
    }
    /* stylelint-enable selector-nested-pattern, rule-empty-line-before */
  }

  & .TrainingItemContainer__hintContainer {
    @media (--mobileOnly) {
      display: none;
    }
  }

  & .TrainingItemContainer__hint {
    margin-top: 32px;

    @media (--tablet) {
      margin-top: 48px;
    }
  }

  & .TrainingItemContainer__hintPanelWrapper {
    @media (--tablet) {
      display: none;
    }
  }

  & .TrainingItemContainer__taskFooter {
    display: flex;
    align-items: center;

    @media (--mobileOnly) {
      @mixin wrapper;
      position: relative;
      z-index: 2;
      background-color: var(--support01Lighten20);
      box-shadow: var(--boxShadow20);
      padding-top: 24px;
      padding-bottom: 24px;
    }

    @media (--tablet) {
      justify-content: space-around;
      box-shadow: var(--boxShadow10);
      padding: 24px 32px;
    }

    & > * {
      @mixin horizontalGap 24px;
    }
  }

  & .TrainingItemContainer__primaryAction {
    @media (--mobileOnly) {
      flex: 1;
    }

    @media (--mobileX) {
      white-space: nowrap;
    }

    @media (--tablet) {
      white-space: normal;
    }
  }
}
